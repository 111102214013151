import { Styles } from '@types'
import { Select, Input, Tag } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { Products } from 'gadjet-v2-types/dist/model'
import { ProductSaleSearch } from 'gadjet-v2-types/dist/type/search'
import { RangeValue } from 'rc-picker/lib/interface'
import { useEffect, useMemo } from 'react'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

type Props = {
  search: ProductSaleSearch
  product: { list: Products[]; loading: boolean }
  onChangeSearch: (search: Partial<ProductSaleSearch>) => void
  onSearch: () => void
  branchId: number
}

export default function BranchProductSaleSearchBox({
  search,
  product,
  onChangeSearch,
  onSearch,
  branchId,
}: Props): JSX.Element {
  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => onChangeSearch({ query: e.target.value })
  const onChangeProducts = (_productIds: number[]) => onChangeSearch({ productIds: _productIds })

  const onChangeDateRange = (_value: RangeValue<Dayjs>) => {
    const start = _value?.[0]?.format('YYYY-MM-DD') || search.datetime[0]
    const end = _value?.[1]?.format('YYYY-MM-DD') || search.datetime[1]
    onChangeSearch({ datetime: [start, end] })
  }

  const rangeValue = useMemo((): [Dayjs, Dayjs] => {
    const [start, end] = search.datetime
    return [dayjs(start), dayjs(end)]
  }, [search])

  useEffect(() => {
    onChangeProducts([])
  }, [branchId])

  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="상품" span={9}>
        <Select
          loading={product.loading}
          mode="multiple"
          onChange={onChangeProducts}
          size="large"
          value={search.productIds}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
        >
          {product.list.map((p) => (
            <Select.Option key={p.productId} value={p.productId}>
              {p.name}
            </Select.Option>
          ))}
        </Select>
      </SearchItem>
      <SearchItem label="날짜" span={6}>
        <LocalDatePicker.RangePicker
          size="large"
          style={styles.datePicker}
          value={rangeValue}
          onChange={onChangeDateRange}
        />
      </SearchItem>

      <SearchItem label="검색어" span={6}>
        <Input size="large" placeholder="검색어를 입력하세요." value={search.query} onChange={onChangeQuery} />
      </SearchItem>
    </SearchBox>
  )
}

const styles: Styles = {
  datePicker: { width: '100%' },
  select: { width: '100%' },
}
