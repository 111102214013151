import { Styles } from '@types'
import { Row, Col } from 'antd'

type Props = {
  contract: number
  mileage: number
  buy: number
}

export default function Credit({ contract, mileage, buy }: Props): JSX.Element {
  return (
    <Row gutter={[20, 20]}>
      <Col span={8}>
        <div style={styles.label}>계약</div>
        <div style={styles.value}>{contract}</div>
      </Col>
      <Col span={8}>
        <div style={styles.label}>마일리지</div>
        <div style={styles.value}>{mileage}</div>
      </Col>
      <Col span={8}>
        <div style={styles.label}>구매</div>
        <div style={styles.value}>{buy}</div>
      </Col>
    </Row>
  )
}

const styles: Styles = {
  label: { opacity: '0.6', fontSize: 14, textAlign: 'center' },
  value: { fontSize: 20, textAlign: 'center' },
}
