import { Divider, Form, Input, Modal, Radio, Select, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { Bills, Contracts } from 'gadjet-v2-types/dist/model'
import { ContractStatus } from 'gadjet-v2-types/dist/type'
import { contractStatus, receiptStatus } from 'gadjet-v2-types/dist/type/label'
import debounce from 'lodash.debounce'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import defaultValues from '@utils/defaultValues'
import formRule from '@utils/formRule'

import BillAPI from '@apis/branch/bill'
import ContractAPI from '@apis/branch/contract'

import { RootState } from '@reducers/index'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import LocalDatePicker from '@components/atoms/LocalDatePicker'
import TypeTag from '@components/molecules/TypeTag'

import ItemList from './ItemList'

type Props = {
  hqId: number
  branchId: number
  billId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function BillFormModal({ hqId, branchId, billId, visible, onClose, onDone }: Props): JSX.Element {
  const option = useSelector((state: RootState) => state.option)
  const [selectedContract, setSelectedContract] = useState<Contracts>()
  const [contracts, setContracts] = useState<Contracts[]>([])
  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(false)

  const [form] = useForm<Bills>()

  const searchContracts = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return

        setSearching(true)
        const { data } = await ContractAPI.searchContracts(
          { hqId, branchId },
          { query, status: ['before-started', 'started', 'expired', 'extended', 'suspended'] }
        )
        setContracts(data.contracts)
        setSearching(false)
      }, 400),
    []
  )

  const getBill = async () => {
    if (!billId) return
    setLoading(true)
    const { data: bill } = await BillAPI.getBill({ hqId, branchId, billId })
    if (bill.contract) setSelectedContract(bill.contract)
    form.setFieldsValue(bill)
    setLoading(false)
  }

  const onOk = async () => {
    setLoading(true)
    try {
      const values = await form.validateFields()
      if (!billId) await BillAPI.addBill({ hqId, branchId }, { bill: values })
      if (billId) await BillAPI.updateBiil({ hqId, branchId, billId }, { bill: values })
      setLoading(false)

      notification.success({ message: '저장되었습니다.' })
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const initialValues = defaultValues.bill({ branchId })

  const reset = () => {
    form.resetFields()
    setContracts([])
    setSelectedContract(undefined)
    if (billId) getBill()
  }

  useEffect(() => {
    if (visible) reset()
  }, [visible])

  const getContractlabel = (contract: Contracts) => {
    const { tenant, suspendDate, status } = contract
    return (
      <div>
        {tenant.name} ( ~ {dayjs(suspendDate).format('YY.MM.DD')}){' '}
        <TypeTag<ContractStatus>
          label={contractStatus[status]}
          value={status}
          switchCase={{
            values: [['before-started'], ['started'], ['extended'], ['expired', 'suspended']],
            types: ['default', 'success', 'ongoing', 'fail'],
          }}
        />
      </div>
    )
  }

  const contractOptions = useMemo(() => {
    const search = contracts.map((contract) => ({ label: getContractlabel(contract), value: contract.contractId }))
    if (selectedContract && !search.some(({ value }) => value === selectedContract.contractId)) {
      return [{ label: getContractlabel(selectedContract), value: selectedContract.contractId }, ...search]
    }
    return search
  }, [contracts, selectedContract])

  return (
    <Modal title="청구서" visible={visible} onCancel={onClose} onOk={onOk} okButtonProps={{ loading }}>
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="계약" name="contractId" required rules={[formRule.required]}>
          <Select
            showSearch
            onSearch={searchContracts}
            filterOption={false}
            loading={searching}
            options={contractOptions}
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item label="청구서 타입" name="type">
          <Radio.Group>
            <Radio value="sales">매출</Radio>
            <Radio value="deposit">보증금</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="청구서 공개일"
          name="openDate"
          extra="입주사가 청구서를 확인할 수 있는 날짜입니다."
          getValueProps={(value) => ({ value: dayjs(value) })}
          getValueFromEvent={(value) => dayjs(value).format('YYYY-MM-DD')}
          required
          rules={[formRule.required]}
        >
          <LocalDatePicker allowClear={false} />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            const start = dayjs(getFieldValue('startDate'))
            const end = dayjs(getFieldValue('endDate'))
            return (
              <Form.Item label="제공기간" required rules={[formRule.required]}>
                <LocalDatePicker.RangePicker
                  value={[start, end]}
                  allowClear={false}
                  allowEmpty={[false, false]}
                  onChange={(range) => {
                    if (!range) return
                    setFieldsValue({
                      startDate: dayjs(range[0] || undefined).format('YYYY-MM-DD'),
                      endDate: dayjs(range[1] || undefined).format('YYYY-MM-DD'),
                    })
                  }}
                />
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            const start = dayjs(getFieldValue('paymentStartDate'))
            const end = dayjs(getFieldValue('paymentEndDate'))
            return (
              <Form.Item label="결제기간" required rules={[formRule.required]}>
                <LocalDatePicker.RangePicker
                  value={[start, end]}
                  allowClear={false}
                  allowEmpty={[false, false]}
                  onChange={(range) => {
                    if (!range) return
                    setFieldsValue({
                      paymentStartDate: dayjs(range[0] || undefined).format('YYYY-MM-DD'),
                      paymentEndDate: dayjs(range[1] || undefined).format('YYYY-MM-DD'),
                    })
                  }}
                />
              </Form.Item>
            )
          }}
        </Form.Item>

        <Form.Item
          label="연체료 부과일"
          name="lateFeeDate"
          getValueProps={(d) => ({ value: d ? dayjs(d) : undefined })}
          getValueFromEvent={(_, s) => s}
        >
          <LocalDatePicker allowClear />
        </Form.Item>

        <Form.Item label="증빙상태" name="receiptStatus">
          <Select>
            {option.receiptStatus.map((status) => (
              <Select.Option key={status} value={status}>
                {receiptStatus[status]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Divider />
        <ItemList label="공간" name="spaces" form={form} />
        <ItemList label="부가서비스" name="additions" form={form} />
        <ItemList label="추가비용" name="surcharges" form={form} />

        <Form.Item label="메모" name="memo">
          <Input.TextArea style={{ height: '100px' }} />
        </Form.Item>

        {/** 데이터 전송용 hidden form */}
        <HiddenItems names={['startDate', 'endDate', 'paymentStartDate', 'paymentEndDate', 'manualFlag']} />
      </Form>
    </Modal>
  )
}
