import { Table, TableColumnsType } from 'antd'
import dayjs from 'dayjs'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { ContractStatus } from 'gadjet-v2-types/dist/type'
import { contractStatus } from 'gadjet-v2-types/dist/type/label'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getHqDashboardRecentContracts } from '@reducers/hq/dashboard.reducer'
import { RootState } from '@reducers/index'

import DashboardCard from '@components/molecules/Dashboard/Card'
import TypeTag from '@components/molecules/TypeTag'

type Props = { hqId: number }

export default function DashboardRecentContracts({ hqId }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const contracts = useSelector((state: RootState) => state.hqDashboard.startContracts)

  const getContracts = async () => {
    setLoading(true)
    dispatch(
      getHqDashboardRecentContracts(hqId, () => {
        setLoading(false)
      })
    )
  }

  useEffect(() => {
    getContracts()
  }, [])

  const columns = useMemo(
    (): TableColumnsType<Contracts> => [
      { key: 'branch', dataIndex: 'branch', title: '지점', render: (_, { branch }) => branch?.name },
      { key: 'tenant', dataIndex: 'tenant', title: '입주사', render: (_, { tenant }) => tenant?.name },
      {
        key: 'startDate',
        dataIndex: 'startDate',
        title: '기간',
        render: (_, { startDate, suspendDate }) =>
          `${dayjs(startDate).format('YYYY-MM-DD')} - ${dayjs(suspendDate).format('YYYY-MM-DD')}`,
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: '상태',
        render: (_, { status }) => (
          <TypeTag<ContractStatus>
            label={contractStatus[status]}
            value={status}
            switchCase={{
              values: [['before-started'], ['started'], ['extended'], ['expired', 'suspended']],
              types: ['default', 'success', 'ongoing', 'fail'],
            }}
          />
        ),
      },
    ],
    []
  )

  return (
    <DashboardCard title="최근 계약" loading={loading}>
      <Table
        dataSource={contracts}
        columns={columns}
        size="small"
        pagination={false}
        rowKey={({ contractId }) => `${contractId}`}
      />
    </DashboardCard>
  )
}
