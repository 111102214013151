import { MoreOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Table, TableColumnType, TablePaginationConfig, Image, Button, Dropdown, Menu } from 'antd'
import { Events } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

import { BranchEventAction } from '@pages/Authorized/Branch/EventPage'

import ContentWrapper from '@components/atoms/ContentWrapper'
import TableColumnItem from '@components/molecules/TableColumn/Item'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  events: Omit<Events, 'content'>[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchEventAction, eventId: number) => void
}
export default function BranchEventListTable({
  loading,
  total,
  pagination,
  events,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<Omit<Events, 'content'>>[] = [
    {
      key: 'bannerImageId',
      dataIndex: 'bannerImageId',
      title: '이미지',
      render: (_, { bannerImage }) => <Image style={styles.image} src={bannerImage?.uri || ''} alt="event" />,
    },
    { key: 'title', dataIndex: 'title', title: '제목' },
    {
      key: 'price',
      dataIndex: 'price',
      title: '비용',
      render: (price) => `${price} 크레딧`,
    },
    { key: 'openDate', dataIndex: 'openDate', title: '공개일' },
    {
      key: 'applyStartDate',
      dataIndex: 'applyStartDate',
      title: '모집기간',
      render: (_, e) => (
        <>
          <TableColumnItem label="시작" value={e.applyStartDate} />
          <TableColumnItem label="종료" value={e.applyEndDate} />
        </>
      ),
    },
    {
      key: 'startDate',
      dataIndex: 'startDate',
      title: '이벤트기간',
      render: (_, e) => (
        <>
          <TableColumnItem label="시작" value={e.startDate} />
          <TableColumnItem label="종료" value={e.endDate} />
        </>
      ),
    },
    {
      key: 'attendeeCount',
      dataIndex: 'attendeeCount',
      title: '참가자',
      render: (_, { eventId, attendeeCount, maxAttendeeCount }) => (
        <Button onClick={() => onAction('attendee', eventId)}>
          {attendeeCount}/{maxAttendeeCount}
        </Button>
      ),
    },
    {
      key: 'place',
      dataIndex: 'place',
      title: '장소',
      render: (place, { address, addressDetail, onlineFlag }) => {
        if (onlineFlag) return '온라인'
        return (
          <>
            <TableColumnItem label="장소" value={place} />
            <TableColumnItem label="주소" value={`${address} ${addressDetail}`} />
          </>
        )
      },
    },
    {
      key: 'eventId',
      dataIndex: 'eventId',
      title: '',
      render: (eventId) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="update" onClick={() => onAction('update', eventId)}>
                수정
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => onAction('delete', eventId)}>
                제거
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <ContentWrapper>
      <Table<Omit<Events, 'content'>>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ eventId }) => `${eventId}`}
        dataSource={events}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomRight'],
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
      />
    </ContentWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
  image: { width: '100px', height: '100px', objectFit: 'scale-down' },
}
