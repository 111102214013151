import { FileImageOutlined, FilePdfOutlined, FileUnknownOutlined, UploadOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Col, Row } from 'antd'
import { Files, Images } from 'gadjet-v2-types/dist/model'
import { FileCategory, ImageCategory } from 'gadjet-v2-types/dist/type'
import { useMemo, useRef, useState } from 'react'

import { fileUpload, imageUpload } from '@utils/upload'

type ElementProps = {
  download: {
    icon: JSX.Element
    href?: string
    disabled: boolean
    label?: string
    hide?: boolean
  }
  upload: {
    loading: boolean
    accept: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
  }
}
const Element = ({ download, upload }: ElementProps) => {
  const inputFile = useRef<HTMLInputElement>(null)

  return (
    <Row gutter={10}>
      {!download.hide && (
        <Col span={12}>
          <Button
            type="text"
            download
            icon={download.icon}
            href={download.href}
            target="_blank"
            disabled={download.disabled}
            block
            style={style.download}
          >
            {download.label}
          </Button>
        </Col>
      )}
      <Col>
        <Button icon={<UploadOutlined />} onClick={() => inputFile.current?.click()} loading={upload.loading}>
          업로드
        </Button>
        <input accept={upload.accept} value="" ref={inputFile} type="file" hidden onChange={upload.onChange} />
      </Col>
    </Row>
  )
}

type UploadButtonProps = {
  label: string
  accept: string
}

type FileUploadButtonProps = UploadButtonProps & {
  file?: Files | null
  onUploadDone: (file: Files) => void
  category: FileCategory
}

const FileUploadButton = ({ file, label, accept, category, onUploadDone }: FileUploadButtonProps): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [newFile, setNewFile] = useState<Files | null>(null)

  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files } = e.target
    if (!files || !files[0]) return
    setLoading(true)
    const result = await fileUpload(files[0], category)
    onUploadDone(result)
    setLoading(false)
    setNewFile(result)
  }
  const downloadFile = useMemo(() => newFile || file, [newFile, file])

  return (
    <Element
      download={{
        disabled: !downloadFile,
        icon: downloadFile ? <FilePdfOutlined /> : <FileUnknownOutlined />,
        href: downloadFile?.uri,
        label,
      }}
      upload={{ accept, loading, onChange }}
    />
  )
}

type ImageUploadButtonProps = UploadButtonProps & {
  file?: Images | null
  onUploadDone: (image: Images) => void
  category: ImageCategory
  hideDownload?: boolean
}

const ImageUploadButton = ({
  file,
  label,
  accept,
  category,
  onUploadDone,
  hideDownload = false,
}: ImageUploadButtonProps): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [newImage, setNewImage] = useState<Images | null>(null)

  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files } = e.target
    if (!files || !files[0]) return
    setLoading(true)
    const result = await imageUpload(files[0], category)
    onUploadDone(result)
    setLoading(false)
    setNewImage(result)
  }

  const downloadImage = useMemo(() => newImage || file, [newImage, file])

  return (
    <Element
      download={{
        disabled: !downloadImage,
        icon: downloadImage ? <FileImageOutlined /> : <FileUnknownOutlined />,
        href: downloadImage?.uri,
        label,
        hide: hideDownload,
      }}
      upload={{ accept, loading, onChange }}
    />
  )
}

const style: Styles = {
  download: { textAlign: 'left' },
}

const UploadButton = {
  File: FileUploadButton,
  Image: ImageUploadButton,
}

export default UploadButton
