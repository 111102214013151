import { Table, TableColumnType, Tag } from 'antd'
import dayjs from 'dayjs'
import { RentalReservations } from 'gadjet-v2-types/dist/model'

import TableColumnItem from '@components/molecules/TableColumn/Item'

type Props = {
  rentalReservations: RentalReservations[]
}

export default function RentalReservationTable({ rentalReservations }: Props): JSX.Element {
  const columns: TableColumnType<RentalReservations>[] = [
    {
      key: 'tenant',
      dataIndex: 'tenant',
      title: '입주사',
      render: (_, { tenant, managerFlag }) => {
        if (managerFlag) return <Tag>관리자 예약</Tag>
        return tenant?.name || '-'
      },
    },
    { key: 'rentalId', dataIndex: 'rentalId', title: '대관/대여', render: (_, { rental }) => rental?.name || '-' },
    { key: 'date', dataIndex: 'date', title: '날짜', render: (_, { date }) => dayjs(date).format('YYYY년 MM월 DD일') },
    {
      key: 'startTime',
      dataIndex: 'startTime',
      title: '시간',
      render: (_, { startTime, endTime }) => (
        <>
          <TableColumnItem label="시작" value={dayjs(startTime, 'HH:mm').format('HH시 mm분')} />
          <TableColumnItem label="종료" value={dayjs(endTime, 'HH:mm').format('HH시 mm분')} />
        </>
      ),
    },
  ]
  return (
    <Table
      rowKey={({ rentalReservationId }) => `${rentalReservationId}`}
      dataSource={rentalReservations}
      columns={columns}
    />
  )
}
