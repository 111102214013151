import { Styles } from '@types'
import { Select } from 'antd'
import { RentalReservationSearch } from 'gadjet-v2-types/dist/type/search'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import RentalAPI from '@apis/branch/rental'
import branchQueryKey from '@apis/queryKey/branch'

import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

type Props = {
  hqId: number
  branchId: number
  search: RentalReservationSearch
  onChangeSearch: (search: Partial<RentalReservationSearch>) => void
  onSearch: () => void
}

export default function BranchRentalReservationSearchBox({
  hqId,
  branchId,
  search,
  onChangeSearch,
  onSearch,
}: Props): JSX.Element {
  const { isLoading, data } = useQuery(
    branchQueryKey.getBranchRentals(branchId),
    () => RentalAPI.getRentals({ hqId, branchId }, { current: 1, pageSize: 500, query: '' }),
    { refetchOnWindowFocus: false }
  )
  const rentals = useMemo(() => {
    if (!data) return []
    return data.data.rentals.map(({ rentalId, name }) => ({ label: name, value: rentalId }))
  }, [data])

  const onChange = (_rentalIds: number[]) => {
    onChangeSearch({ rentalIds: _rentalIds })
  }

  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="대관/대여 시설" span={12}>
        <Select
          value={search.rentalIds}
          mode="multiple"
          onChange={onChange}
          style={styles.select}
          size="large"
          loading={isLoading}
          options={rentals}
        />
      </SearchItem>
    </SearchBox>
  )
}

const styles: Styles = {
  select: { width: '100%' },
}
