import GreenButton from '@components/atoms/Buttons/GreenButton'

import style from './style.module.scss'

export default function Features(): JSX.Element {
  const features: { title: string; content: string; image: string }[] = [
    {
      title: '청구서 자동 발송',
      content: '매 월 청구서를 자동으로 발송해 반복되는 업무를 줄일 수 있습니다.',
      image: 'bill.png',
    },
    { title: '세금계산서 발행', content: '클릭 몇 번으로 세금계산서를 발행할 수 있습니다.', image: 'tax-invoice.png' },
    { title: '전자서명', content: '전자 서명으로 간편하게 계약서를 작성할 수 있습니다.', image: 'signature.png' },
    {
      title: '이용료 결제',
      content: '카드 결제, 실시간 계좌 이체, 가상 계좌 입금이 가능합니다.',
      image: 'payment.png',
    },
    {
      title: '크레딧 시스템',
      content: '입주사는 크레딧을 이용해 상품을 구매하고 회의실을 예약 할 수 있습니다.',
      image: 'credit.png',
    },
    {
      title: '회의실 및 공용서비스',
      content: '손 쉽게 회의실을 등록하고 예약을 관리할 수 있습니다.',
      image: 'meetingroom.png',
    },
  ]

  return (
    <>
      <div className={style.bg}>
        <div className={style.title}>
          공간 관리를 위한 필수 업무, 가젯으로 자동화해 <span className={style.colored}>쉽고 빠르게</span>
        </div>
        <div className={style.container}>
          {features.map((el) => (
            <div className={style.featureWrapper}>
              <div>
                <img src={`/images/landing/home-features/${el.image}`} alt="feature" className={style.icons} />
              </div>
              <div className={style.featureDetail}>
                <div className={style.featureTitle}>{el.title}</div>
                <div className={style.featureContent}>{el.content}</div>
              </div>
            </div>
          ))}
        </div>
        <GreenButton text="자세히 보기" noColor medium path="/feature" />
      </div>
    </>
  )
}
