import { Role } from 'gadjet-v2-types/dist/type'

export const VISIBLE_ROLES: (Role | number)[] = [1, 2]
export const TOKEN_KEY = 'GADJET_TOKEN'
export const TOKEN_TYPE = 'Bearer' as const

export const COLORS = {
  success: '#52c41a',
  warn: '#faad14',
  error: '#ff4d4f',
  primary: '#3ebd73',
  active: '#F0FCF1',
  secondary: '#d9d9d9',

  calendarEvents: [
    { backgroundColor: '#AD1457', color: '#FFFFFF' },
    { backgroundColor: '#F4511E', color: '#FFFFFF' },
    { backgroundColor: '#E4C441', color: '#FFFFFF' },
    { backgroundColor: '#0B8043', color: '#FFFFFF' },
    { backgroundColor: '#3F51B5', color: '#FFFFFF' },
    { backgroundColor: '#8E24AA', color: '#FFFFFF' },
    { backgroundColor: '#D81B60', color: '#FFFFFF' },
    { backgroundColor: '#EF6C00', color: '#FFFFFF' },
    { backgroundColor: '#C0CA33', color: '#FFFFFF' },
    { backgroundColor: '#009688', color: '#FFFFFF' },
    { backgroundColor: '#7986CB', color: '#FFFFFF' },
    { backgroundColor: '#795548', color: '#FFFFFF' },
    { backgroundColor: '#D50000', color: '#FFFFFF' },
    { backgroundColor: '#F09300', color: '#FFFFFF' },
    { backgroundColor: '#7CB342', color: '#FFFFFF' },
    { backgroundColor: '#039BE5', color: '#FFFFFF' },
    { backgroundColor: '#B39DDB', color: '#FFFFFF' },
    { backgroundColor: '#616161', color: '#FFFFFF' },
    { backgroundColor: '#E67C73', color: '#FFFFFF' },
    { backgroundColor: '#F6BF26', color: '#FFFFFF' },
    { backgroundColor: '#33B679', color: '#FFFFFF' },
    { backgroundColor: '#4285F4', color: '#FFFFFF' },
    { backgroundColor: '#9E69AF', color: '#FFFFFF' },
    { backgroundColor: '#A79B8E', color: '#FFFFFF' },
  ],
}
