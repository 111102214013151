import { Styles } from '@types'
import { Layout } from 'antd'

import { AdminGlobalProvider } from '@components/contexts/AdminGlobal'
import AdminHeader from '@components/organisms/Layout/Admin/Header'
import AdminMenu from '@components/organisms/Layout/Admin/Menu'
import NotificationDrawer from '@components/organisms/Layout/Admin/NotificationDrawer'

type Props = {
  children: JSX.Element
}

export default function AdminTemplate({ children }: Props): JSX.Element {
  return (
    <AdminGlobalProvider>
      <Layout style={styles.layout}>
        <Layout>
          <Layout.Header style={styles.header}>
            <AdminHeader />
          </Layout.Header>
          <Layout.Content style={styles.content}>{children}</Layout.Content>
        </Layout>
        <Layout.Sider style={styles.sider}>
          <AdminMenu />
        </Layout.Sider>
        <NotificationDrawer />
      </Layout>
    </AdminGlobalProvider>
  )
}

const styles: Styles = {
  header: {
    backgroundColor: '#fafafa',
    padding: '0 10px',
    borderBottom: '1px solid #eee',
  },
  layout: { backgroundColor: '#fff' },
  sider: {
    backgroundColor: '#fff',
    height: '100vh',
    position: 'fixed',
    overflow: 'auto',
    left: 0,
    // boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
    borderRight: '1px solid #eee',
  },
  content: {
    marginLeft: '200px',
    overflow: 'auto',
    padding: '24px 16px',
    backgroundColor: '#fff',
  },
}
