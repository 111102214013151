import { Styles } from '@types'
import { Form, Select, Input, FormInstance } from 'antd'
import { Contracts, ContractAdditions, Additions } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'

import { krw } from '@utils/format'
import mask from '@utils/mask'

import FormListTable, { FormListTableColumn } from '@components/molecules/FormListTable'
import TableColumnItem from '@components/molecules/TableColumn/Item'

import TotalPriceFooter from '../TotalPriceFooter'

type Props = {
  form: FormInstance<Contracts>
  additions: Additions[]
  loading: boolean
}

export default function ContractFeeAdditionList({ form, additions, loading }: Props): JSX.Element {
  const findAddition = (additionId: number) => additions.find((s) => s.additionId === additionId)

  const onChangeAdditionId = (additionId: number, index: number) => {
    const addition = findAddition(additionId)
    if (!addition) return

    const _contractAdditions = form.getFieldValue('contractAdditions')
    form.setFieldsValue({
      contractAdditions: [
        ..._contractAdditions.slice(0, index),
        { additionId, name: addition.name, price: addition.price },
        ..._contractAdditions.slice(index + 1),
      ],
    })
  }

  const additionOptions = useMemo(() => additions.map((s) => ({ label: s.name, value: s.additionId })), [additions])
  const additionColumns = useMemo(
    (): FormListTableColumn[] => [
      {
        label: '부가서비스',
        width: '25%',
        render: (field, index) => (
          <>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const _value = getFieldValue('contractAdditions')[index]
                return (
                  <Select<number>
                    style={styles.select}
                    value={_value.additionId}
                    onChange={(value) => onChangeAdditionId(value, index)}
                    options={additionOptions}
                    loading={loading}
                    showSearch
                    optionFilterProp="label"
                  />
                )
              }}
            </Form.Item>

            <Form.Item hidden isListField name={[field.name, 'additionId']} />
          </>
        ),
      },
      {
        label: '정가',
        width: '15%',
        render: (field, index) => (
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const contractAdditions = getFieldValue('contractAdditions')
              const { additionId } = contractAdditions[index] || { additionId: 0 }
              const addition = findAddition(additionId)
              return krw(addition?.price || 0)
            }}
          </Form.Item>
        ),
      },
      {
        label: '계약가',
        width: '25%',
        render: (field) => (
          <Form.Item noStyle isListField name={[field.name, 'price']} initialValue={0} {...mask.krw}>
            <Input addonAfter="원" />
          </Form.Item>
        ),
      },
      {
        label: '할인',
        width: '25%',
        render: (field, index) => (
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const contractAdditions = getFieldValue('contractAdditions')
              const { additionId, price } = contractAdditions[index] || { additionId: 0, price: 0 }
              const addition = findAddition(additionId)
              const additionPrice = addition?.price || 0
              const discountPrice = krw(additionPrice - price)
              const discountRate =
                additionPrice === 0 ? '0%' : `${Math.round(((additionPrice - price) / additionPrice) * 100)}%`
              return (
                <>
                  <TableColumnItem label="할인금액" value={`${discountPrice}`} />
                  <TableColumnItem label="할인률(반올림)" value={`${discountRate}`} />
                </>
              )
            }}
          </Form.Item>
        ),
      },
    ],
    [additions, loading]
  )
  const AdditionFooter = () => {
    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const contractAdditions: ContractAdditions[] = getFieldValue('contractAdditions') || []
          const totalPrice = contractAdditions
            .map(({ price }) => price)
            .reduce((a, b) => Number(a || 0) + Number(b || 0), 0)
          return <TotalPriceFooter price={totalPrice} />
        }}
      </Form.Item>
    )
  }

  const defaultValue = useMemo(
    () => ({
      additionId: additions[0]?.additionId || null,
      name: additions[0]?.name,
      price: 0,
    }),
    [additions]
  )

  return (
    <FormListTable
      columns={additionColumns}
      name="contractAdditions"
      title="부가서비스"
      footer={AdditionFooter}
      defaultValue={defaultValue}
    />
  )
}

const styles: Styles = {
  select: { width: '100%' },
}
