import { Tabs } from 'antd'
import dayjs from 'dayjs'
import { Contracts, Bills, Expenditures, Payments, RentalReservations } from 'gadjet-v2-types/dist/model'
import { useState, useEffect, useMemo } from 'react'

import DashboardAPI from '@apis/branch/dashboard'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'

import BillTable from './BillTable'
import EndContractTable from './EndContractTable'
import ExpenditureTable from './ExpenditureTable'
import PaymentTable from './PaymentTable'
import RentalReservationTable from './RentalReservationTable'
import StartContractTable from './StartContractTable'

type DaliyEvent = {
  startContracts: Contracts[]
  endContracts: Contracts[]
  billingBills: Bills[]
  expenditure: Expenditures[]
  payments: Payments[]
  rentalReservations: RentalReservations[]
}

type Props = {
  hqId: number
  branchId: number
  date: string
  visible: boolean
  onClose: () => void
}

export default function DashboardDayEventModal({ hqId, branchId, date, visible, onClose }: Props): JSX.Element {
  const [activeKey, setActiveKey] = useState('billingBills')
  const [loading, setLoading] = useState(false)
  const [event, setEvent] = useState<DaliyEvent>({
    billingBills: [],
    startContracts: [],
    endContracts: [],
    expenditure: [],
    payments: [],
    rentalReservations: [],
  })

  const getEvents = async () => {
    setLoading(true)
    const { data } = await DashboardAPI.getDashboardEventDetail({ hqId, branchId, date })
    setEvent(data)
    setLoading(false)
  }

  useEffect(() => {
    if (visible) {
      getEvents()
      setActiveKey('billingBills')
    }
  }, [visible, date])

  const title = useMemo(() => dayjs(date).format('YYYY년 MM월 DD일'), [date])

  const tabs = [
    { label: '청구', key: 'billingBills', compoent: <BillTable bills={event.billingBills} /> },
    { label: '계약시작', key: 'startContracts', compoent: <StartContractTable contracts={event.startContracts} /> },
    { label: '계약종료', key: 'endContracts', compoent: <EndContractTable contracts={event.endContracts} /> },
    { label: '지출', key: 'expenditure', compoent: <ExpenditureTable expenditure={event.expenditure} /> },
    { label: '결제', key: 'payments', compoent: <PaymentTable payments={event.payments} /> },
    {
      label: '대관/대여',
      key: 'rentalReservations',
      compoent: <RentalReservationTable rentalReservations={event.rentalReservations} />,
    },
  ]

  return (
    <ModalAlert title={title} visible={visible} onCancel={onClose} width="1000px">
      <Loading loading={loading}>
        <Tabs tabPosition="left" activeKey={activeKey} onChange={setActiveKey}>
          {tabs.map((tab) => (
            <Tabs.TabPane tab={tab.label} key={tab.key}>
              {tab.compoent}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Loading>
    </ModalAlert>
  )
}
