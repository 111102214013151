import { notification } from 'antd'

import CashAPI from '@apis/branch/cash'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  cashId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function CashDeleteModal({ hqId, branchId, cashId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!cashId) return
    await CashAPI.deleteCash({ hqId, branchId, cashId })
    notification.success({ message: '제거되었습니다.' })
  }

  return (
    <ConfirmModal
      title="현금 제거"
      okText="제거"
      visible={visible}
      onConfirm={onConfirm}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
