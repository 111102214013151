import { Table, TableColumnType, TablePaginationConfig } from 'antd'
import dayjs from 'dayjs'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import AdditionAPI from '@apis/branch/addition'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'
import TableColumnItem from '@components/molecules/TableColumn/Item'

type Props = {
  hqId: number
  branchId: number
  additionId?: number
  visible: boolean
  onClose: () => void
}

export default function AdditionContractModal({ hqId, branchId, additionId, visible, onClose }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [contracts, setContracts] = useState<Contracts[]>([])
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 })

  const onChange = ({ current = 1, pageSize = 10 }: TablePaginationConfig) => {
    setPagination({ ...pagination, current, pageSize })
  }

  const getContracts = async () => {
    if (!additionId || !visible) return
    setLoading(true)
    const { data } = await AdditionAPI.getAdditionContracts({ hqId, branchId, additionId }, pagination)

    setPagination({ ...pagination, total: data.total })
    setContracts(data.contracts)
    setLoading(false)
  }

  useEffect(() => {
    getContracts()
  }, [pagination.current, pagination.pageSize])

  useEffect(() => {
    if (visible) {
      getContracts()
    } else {
      setPagination({ current: 1, pageSize: 10, total: 0 })
    }
  }, [visible, additionId])

  const columns: TableColumnType<Contracts>[] = [
    {
      key: 'startDate',
      dataIndex: 'startDate',
      title: '기간',
      render: (_, { startDate, suspendDate }) => (
        <>
          <TableColumnItem label="시작일" value={dayjs(startDate).format('YYYY년 MM월 DD일')} />
          <TableColumnItem label="종료일" value={dayjs(suspendDate).format('YYYY년 MM월 DD일')} />
        </>
      ),
    },
    {
      key: '',
      dataIndex: '',
      title: '입주사',
      render: (_, { tenant }) => (
        <>
          <TableColumnItem label="이름" value={tenant.name} />
          <TableColumnItem label="연락처" value={tenant.contact} />
          <TableColumnItem label="이메일" value={tenant.email} />
        </>
      ),
    },
    {
      key: '',
      dataIndex: '',
      title: '대표자',
      render: (_, { director }) => (
        <>
          <TableColumnItem label="이름" value={director.name} />
          <TableColumnItem label="연락처" value={director.contact} />
          <TableColumnItem label="이메일" value={director.email} />
        </>
      ),
    },
  ]

  return (
    <ModalAlert title="부가서비스 계약" visible={visible} onCancel={onClose} width="800px">
      <Loading loading={loading}>
        <Table<Contracts>
          size="small"
          dataSource={contracts}
          columns={columns}
          pagination={pagination}
          onChange={onChange}
          rowKey={({ contractId }) => `${contractId}`}
        />
      </Loading>
    </ModalAlert>
  )
}
