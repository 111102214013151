import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@styles/button.scss'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'

type Props = {
  text: string
  noColor?: boolean
  large?: boolean
  medium?: boolean
  small?: boolean
  path: string
  reversed?: boolean
}

export default function GreenButton({ text, noColor, large, small, reversed, path, medium }: Props): JSX.Element {
  const { push } = useHistory()

  const isServiceButton = ['요청하기', '보러가기', '문의하기'].includes(text)

  const onClick = () => {
    if (text === '로그인') push(path)
    if (text === '자세히 보기') push(path)
    if (text === '보러가기') window.open('https://gadjet.notion.site/78b2e1111fcf43208f420b1815d7a479')
    if (text === '요청하기') window.location.replace('mailto:support@gadjet.io')
    if (text === '문의하기') window.location.replace('mailto:support@gadjet.io')
    if (text === '도입하기') window.open('https://moaform.com/q/3N0nSO')
  }

  return (
    <>
      <button className={cn('button', { noColor, large, small, reversed, medium })} type="button" onClick={onClick}>
        {text}
        {isServiceButton && (
          <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '12px', color: '#3ebd73', marginLeft: '3px' }} />
        )}
        {text === '자세히 보기' && (
          <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '16px', color: '#3ebd73', marginLeft: '5px' }} />
        )}
      </button>
    </>
  )
}
