/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import * as RBC from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

const DnDCalendar = withDragAndDrop(RBC.Calendar as any)

const locale = {
  ko: {
    daynames: ['일', '월', '화', '수', '목', '금', '토'],
  },
}
const formats: RBC.Formats = {
  dateFormat: 'D',
  dayFormat: 'M. D',

  eventTimeRangeFormat: ({ start, end }) => `${dayjs(start).format('HH:mm')}-${dayjs(end).format('HH:mm')}`,
  eventTimeRangeStartFormat: (e) => e.start.toString(),
  eventTimeRangeEndFormat: (e) => e.end.toString(),

  agendaDateFormat: 'MM. DD',
  agendaHeaderFormat: ({ start, end }) =>
    `${dayjs(start).format('YYYY. MM. DD')} - ${dayjs(end).format('YYYY. MM. DD')}`,
  agendaTimeFormat: 'HH:mm',
  agendaTimeRangeFormat: ({ start, end }) => `${dayjs(start).format('HH:mm')}-${dayjs(end).format('HH:mm')}`,

  timeGutterFormat: 'HH:mm',

  dayHeaderFormat: 'M. D',
  dayRangeHeaderFormat: () => '',

  monthHeaderFormat: 'YYYY. MM',
  selectRangeFormat: ({ start, end }) => `${dayjs(start).format('HH:mm')}-${dayjs(end).format('HH:mm')}`,
  weekdayFormat: (date) => locale.ko.daynames[dayjs(date).day()],
}

const localizer = new RBC.DateLocalizer({
  formats,
  firstOfWeek: () => 0,
  format: (value, format) => dayjs(value).format(format),
})

const messages: RBC.Messages = {
  today: '오늘',
  allDay: '종일',
  event: '메모',
  next: '다음',
  previous: '이전',
  day: '일',
  month: '월',
  week: '주',
  agenda: '아젠다',
  date: '날짜',
  time: '시간',
  noEventsInRange: '예약내역이 없습니다.',
}

type Props = Omit<RBC.CalendarProps, 'localizer'>

export default function Calendar(props: Props): JSX.Element {
  return <DnDCalendar {...props} localizer={localizer} messages={messages} />
}
