import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getAuth } from '@reducers/auth.reducer'
import { RootState } from '@reducers/index'

import FullPageLoading from '@components/organisms/FullPageLoading'

import AuthorizedRoutes from './AuthorizedRouter'
import UnauthorizedRoutes from './UnauthorizedRouter'

export default function Routes(): JSX.Element {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { loading, manager } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    dispatch(getAuth())
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const isAuthorized = useMemo(() => !!manager, [manager])

  if (loading) return <FullPageLoading />

  return isAuthorized ? <AuthorizedRoutes /> : <UnauthorizedRoutes />
}
