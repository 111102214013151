import { Modal } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

export default function UpdatePopUp(): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)

  const handleOk = () => {
    setIsModalVisible(false)
    const expiry: string | null = dayjs().add(7, 'day').format('YYYY/MM/DD')
    localStorage.setItem('updateCheck', expiry)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onClickViewDetail = () => {
    window.open('https://gadjet.notion.site/220502-ff4eb05ca95549399866b33ecb7a32b5')
  }

  return (
    <>
      <Modal
        closable={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        mask={false}
        okText="7일간 다시보지 않기"
        cancelText="닫기"
        focusTriggerAfterClose={false}
        width="max-content"
        bodyStyle={{ padding: 0, margin: 0 }}
      >
        <img
          src="/images/popup/gjet_modal_service-check.png"
          alt="popup-service-check"
          aria-hidden="true"
          style={{ width: '340px', height: '100%', margin: 0 }}
        />
        <img
          src="/images/popup/gjet_modal_new-app.png"
          alt="popup-content"
          onClick={onClickViewDetail}
          aria-hidden="true"
          style={{ cursor: 'pointer', width: '340px', height: '100%', margin: 0 }}
        />
      </Modal>
    </>
  )
}
