import { BranchRoleAction } from '@pages/Authorized/Branch/RolePage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchRoleAction) => void
}

export default function BranchRoleTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    { label: '매니저 초대', buttonType: 'primary', onClick: () => onAction('invite') },
  ]

  return <PageTitle title="지점 권한" actions={actions} />
}
