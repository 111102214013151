import { Table, TableColumnType, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { Bills } from 'gadjet-v2-types/dist/model'

import { krw } from '@utils/format'

import TableColumnItem from '@components/molecules/TableColumn/Item'

type Props = {
  bills: Bills[]
}

export default function BillTable({ bills }: Props): JSX.Element {
  const columns: TableColumnType<Bills>[] = [
    {
      key: 'contractId',
      dataIndex: 'contractId',
      title: '입주사',
      render: (_, { contract }) => contract?.tenant.name || '-',
    },
    {
      key: 'space',
      dataIndex: '',
      title: '상품',
      render: (_, { spaces, additions, surcharges }) => {
        const concat = [...spaces, ...additions, ...surcharges]
        if (concat.length === 1) return concat[0].name
        if (concat.length > 1)
          return (
            <Tooltip
              title={concat.map(({ name }) => (
                <div>{name}</div>
              ))}
              placement="bottomRight"
            >
              {`${concat[0].name} 외 ${concat.length - 1}건`}
            </Tooltip>
          )
        return '-'
      },
    },
    { key: 'price', dataIndex: 'price', title: '금액', render: (_, { price }) => krw(price) },
    {
      key: 'paymentStartDate',
      dataIndex: 'paymentStartDate',
      title: '결제기간',
      render: (_, { paymentStartDate, paymentEndDate }) => (
        <>
          <TableColumnItem label="시작" value={dayjs(paymentStartDate).format('YYYY년 MM월 DD일')} />
          <TableColumnItem label="종료" value={dayjs(paymentEndDate).format('YYYY년 MM월 DD일')} />
        </>
      ),
    },
    {
      key: 'startDate',
      dataIndex: 'startDate',
      title: '제공기간',
      render: (_, { startDate, endDate }) => (
        <>
          <TableColumnItem label="시작" value={dayjs(startDate).format('YYYY년 MM월 DD일')} />
          <TableColumnItem label="종료" value={dayjs(endDate).format('YYYY년 MM월 DD일')} />
        </>
      ),
    },
  ]
  return <Table rowKey={({ billId }) => `${billId}`} dataSource={bills} columns={columns} />
}
