import { Button, Form, FormInstance, Input } from 'antd'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { ContractDirector } from 'gadjet-v2-types/dist/type'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

type Props = { form: FormInstance<Contracts>; isUpdate: boolean }

export default function ContractorTab({ form, isUpdate }: Props): JSX.Element {
  const onClickSame = () => {
    const director: ContractDirector = form.getFieldValue('director')
    form.setFieldsValue({
      contractor: {
        name: director.name,
        contact: director.contact,
        email: director.email,
        relationshipWithDirector: '본인',
      },
    })
  }

  return (
    <>
      <Form.Item>
        <Button type="default" size="small" onClick={() => onClickSame()}>
          대표자 정보 가져오기
        </Button>
      </Form.Item>
      <Form.Item label="계약자 이름" name={['contractor', 'name']} rules={[formRule.required]}>
        <Input />
      </Form.Item>
      <Form.Item label="계약자 연락처" name={['contractor', 'contact']} {...mask.contact}>
        <Input />
      </Form.Item>
      <Form.Item label="계약자 이메일" name={['contractor', 'email']}>
        <Input />
      </Form.Item>
      <Form.Item label="대표자와의 관계" name={['contractor', 'relationshipWithDirector']}>
        <Input />
      </Form.Item>
    </>
  )
}
