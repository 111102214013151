import { Table, TableColumnType, Tag } from 'antd'
import { Payments } from 'gadjet-v2-types/dist/model'
import { paymentType } from 'gadjet-v2-types/dist/type/label'

import { krw } from '@utils/format'

type Props = {
  payments: Payments[]
}

export default function PaymentTable({ payments }: Props): JSX.Element {
  const columns: TableColumnType<Payments>[] = [
    {
      key: 'billId',
      dataIndex: 'billId',
      title: '입주사',
      render: (_, { bill }) => {
        if (!bill) return '-'
        const { contract, tenant } = bill
        return contract?.tenant?.name || tenant?.name
      },
    },
    { key: 'item', dataIndex: 'item', title: '상품' },
    { key: 'type', dataIndex: 'type', title: '타입', render: (_, { type }) => <Tag>{paymentType[type]}</Tag> },
    { key: 'price', dataIndex: 'price', title: '금액', render: (price) => krw(price) },
  ]

  return <Table rowKey={({ paymentId }) => `${paymentId}`} dataSource={payments} columns={columns} />
}
