import * as PAYMENT from 'gadjet-v2-types/dist/api/branch/payment'

import axios from '@utils/axios'

const PaymentAPI = {
  // 결제내역 조회
  getPayments: async (
    { hqId, branchId }: PAYMENT.GET_LIST_PARAMS,
    query: PAYMENT.GET_LIST_QUERY
  ): Promise<{ data: PAYMENT.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/payments`, { params: query }),

  // 결제내역 상세조회
  getPaymentDetail: async ({
    hqId,
    branchId,
    paymentId,
  }: PAYMENT.GET_DETAIL_PARAMS): Promise<{ data: PAYMENT.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/payments/${paymentId}`),

  // 결제내역 추가
  addPayment: async (
    { hqId, branchId }: PAYMENT.ADD_PARAMS,
    { payment }: PAYMENT.ADD_BODY
  ): Promise<{ data: PAYMENT.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/payments`, { payment }),

  // 결제내역 수정
  updatePayment: async (
    { hqId, branchId, paymentId }: PAYMENT.UPDATE_PARAMS,
    { payment }: PAYMENT.UPDATE_BODY
  ): Promise<{ data: PAYMENT.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/payments/${paymentId}`, { payment }),

  // 결제내역 제거
  deletePayment: async ({
    hqId,
    branchId,
    paymentId,
  }: PAYMENT.DELETE_PARAMS): Promise<{ data: PAYMENT.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/payments/${paymentId}`),

  // 결제내역 요약 조회
  getPaymentSummary: async (
    { hqId, branchId }: PAYMENT.GET_SUMMARY_PARAMS,
    query: PAYMENT.GET_SUMMARY_QUERY
  ): Promise<{ data: PAYMENT.GET_SUMMARY_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/payments/summary`, { params: query }),

  // 결제내역 매출확정
  confirmPayment: async ({
    hqId,
    branchId,
    paymentId,
  }: PAYMENT.CONFIRM_PARAMS): Promise<{ data: PAYMENT.CONFIRM_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/payments/${paymentId}/confirm`),

  // 결제내역 환불
  refundPayment: async (
    { hqId, branchId, paymentId }: PAYMENT.REFUND_PARAMS,
    { account }: PAYMENT.REFUND_BODY
  ): Promise<{ data: PAYMENT.REFUND_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/payments/${paymentId}/refund`, { account }),

  // 결제내역 내보내기
  exportPayments: async (
    { hqId, branchId }: PAYMENT.EXPORT_PARAMS,
    query: PAYMENT.EXPORT_QUERY
  ): Promise<{ data: PAYMENT.EXPORT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/payments/export`, { params: query }),

  // 보증금 결제내역 내보내기
  exportDepositPayments: async (
    { hqId, branchId }: PAYMENT.EXPORT_DEPOSIT_PAYMENTS_PARAMS,
    { contractRange }: PAYMENT.EXPORT_DEPOSIT_PAYMENTS_QUERY
  ) =>
    axios.get<PAYMENT.EXPORT_DEPOSIT_PAYMENTS_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/payments/deposit/export`, {
      params: { contractRange },
    }),
}

export default PaymentAPI
