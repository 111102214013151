import { notification } from 'antd'

import ServiceAPI from '@apis/branch/service'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  serviceId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ServiceDeleteModal({
  hqId,
  branchId,
  serviceId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!serviceId) return
    await ServiceAPI.deleteService({ hqId, branchId, serviceId })
    notification.success({ message: '제거되었습니다.' })
  }

  return (
    <ConfirmModal
      visible={visible}
      title="서비스가 제거됩니다."
      description="서비스 구매내역이 사라질 수 있습니다."
      onClose={onClose}
      onDone={onDone}
      onConfirm={onConfirm}
    />
  )
}
