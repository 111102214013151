import useMenu from '@hooks/useMenu'
import { Tabs } from 'antd'
import { useQuery, useQueryClient } from 'react-query'

import BranchAPI from '@apis/branch'

import Loading from '@components/molecules/Loading'
import BranchTab from '@components/organisms/Branch/Config/Tabs/Branch'
import BranchBusinessTab from '@components/organisms/Branch/Config/Tabs/BranchBusiness'
import BranchCardTab from '@components/organisms/Branch/Config/Tabs/BranchCard'
import BranchContractDocumentTab from '@components/organisms/Branch/Config/Tabs/BranchContractDocument'
import BranchSettlementTab from '@components/organisms/Branch/Config/Tabs/BranchSettlement'
import BranchConfigTitle from '@components/organisms/Branch/Config/Title'

const QUERY_KEY = 'BRANCH_CONFIG_GET_BRANCH'

const { TabPane } = Tabs

export default function ConfigPage(): JSX.Element {
  const { hqId, branchId } = useMenu()

  const queryClient = useQueryClient()

  const query = useQuery(QUERY_KEY, () => BranchAPI.getBranch({ hqId, branchId }), { refetchOnWindowFocus: false })
  const onRefresh = () => {
    queryClient.invalidateQueries(QUERY_KEY)
  }

  const tabPanes = [
    {
      tab: '지점',
      key: 'branch',
      component: <BranchTab hqId={hqId} branchId={branchId} branch={query.data?.data} onRefresh={onRefresh} />,
    },
    {
      tab: '사업자',
      key: 'branch-business',
      component: <BranchBusinessTab hqId={hqId} branchId={branchId} branch={query.data?.data} onRefresh={onRefresh} />,
    },
    {
      tab: '계약서',
      key: 'branch-contract-document',
      component: (
        <BranchContractDocumentTab hqId={hqId} branchId={branchId} branch={query.data?.data} onRefresh={onRefresh} />
      ),
    },
    {
      tab: '정산',
      key: 'branch-settlement',
      component: (
        <BranchSettlementTab hqId={hqId} branchId={branchId} branch={query.data?.data} onRefresh={onRefresh} />
      ),
    },
    {
      tab: '결제수단',
      key: 'branch-card',
      component: <BranchCardTab hqId={hqId} branchId={branchId} branch={query.data?.data} />,
    },
  ]

  return (
    <div>
      <BranchConfigTitle />
      <Loading loading={query.isFetching}>
        <Tabs type="card">
          {tabPanes.map(({ tab, key, component }) => (
            <TabPane tab={tab} key={key}>
              {component}
            </TabPane>
          ))}
        </Tabs>
      </Loading>
    </div>
  )
}
