import * as FLOOR from 'gadjet-v2-types/dist/api/branch/floor'

import axios from '@utils/axios'

const FloorAPI = {
  // 층 조회
  getFloors: async ({ hqId, branchId }: FLOOR.GET_LIST_PARAMS): Promise<{ data: FLOOR.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/floors`),

  // 층 추가
  addFloor: async (
    { hqId, branchId }: FLOOR.ADD_PARAMS,
    { floor }: FLOOR.ADD_BODY
  ): Promise<{ data: FLOOR.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/floors`, { floor }),

  // 층 수정
  updateFloor: async (
    { hqId, branchId, floorId }: FLOOR.UPDATE_PARAMS,
    { floor }: FLOOR.UPDATE_BODY
  ): Promise<{ data: FLOOR.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/floors/${floorId}`, { floor }),

  // 층 제거
  deleteFloor: async ({ hqId, branchId, floorId }: FLOOR.DELETE_PARAMS): Promise<{ data: FLOOR.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/floors/${floorId}`),
}

export default FloorAPI
