import { call, all, put, takeLatest } from 'typed-redux-saga'

import HqNoticeAPI from '@apis/hq/notice'

import { HqNoticeAction, GET_HQ_NOTICES, setHqNotices, setHqNoticeLoading } from '@reducers/hq/notice.reducer'

function* getHqNoticesSaga(action: HqNoticeAction): Generator {
  if (action.type !== GET_HQ_NOTICES) return
  const { hqId, pagination } = action.payload
  yield* put(setHqNoticeLoading(true))
  const { data } = yield* call(
    HqNoticeAPI.getNotices,
    { hqId },
    { ...pagination, query: '', writeDate: ['2010-01-01', '2100-12-31'] }
  )

  const { total, notices } = data

  yield* put(setHqNotices(notices, total))
  yield* put(setHqNoticeLoading(false))
}

export default function* hqNoticeSaga(): Generator {
  yield* all([takeLatest(GET_HQ_NOTICES, getHqNoticesSaga)])
}
