import { Button, Form, Input, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import formRule from '@utils/formRule'

import ManagerRoleAPI from '@apis/manager/role'

import { logout } from '@reducers/auth.reducer'
import { RootState } from '@reducers/index'

import AuthFormTemplate from '@templates/AuthFormTemplate'
import AuthTemplate from '@templates/AuthTemplate'

export default function CodePage(): JSX.Element {
  const dispatch = useDispatch()
  const [form] = useForm<{ code: string }>()
  const [loading, setLoading] = useState(false)

  const manager = useSelector((state: RootState) => state.auth.manager)

  const onClickLogout = async () => {
    dispatch(logout())
  }

  const onPressDone = async () => {
    if (!manager) return
    const values = await form.validateFields()
    const { code } = values
    const { data } = await ManagerRoleAPI.acceptInvite({ managerId: manager.managerId }, { code })

    if (!data) {
      notification.error({ message: '코드를 확인하세요.' })
      return
    }
    setLoading(false)
    window.location.reload()
  }

  const initialValues = { code: '' }

  return (
    <AuthTemplate>
      <AuthFormTemplate
        title="코드입력"
        submit={{ label: '코드입력완료', onClick: onPressDone }}
        loading={loading}
        footer={
          <Button onClick={onClickLogout} size="large" block type="text">
            로그아웃
          </Button>
        }
      >
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item name="code" label="코드" rules={[formRule.required]}>
            <Input size="large" autoFocus onPressEnter={onPressDone} />
          </Form.Item>
        </Form>
      </AuthFormTemplate>
    </AuthTemplate>
  )
}
