import dayjs from 'dayjs'
import { Bills } from 'gadjet-v2-types/dist/model'

const bill = (_bill?: Partial<Bills>): Bills => ({
  billId: 0,
  branchId: 0,
  contractId: null,
  tenantId: null,
  userId: null,
  type: 'sales',
  rate: 1,
  price: 0,
  unpaidPrice: 0,
  openDate: dayjs().format('YYYY-MM-DD'),
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  paymentStartDate: dayjs().format('YYYY-MM-DD'),
  paymentEndDate: dayjs().format('YYYY-MM-DD'),
  lateFeeDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
  spaces: [],
  additions: [],
  surcharges: [],
  memo: '',
  receiptStatus: 'none',
  gadjetPayFlag: false,
  manualFlag: true,
  ..._bill,
})

export default bill
