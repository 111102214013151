import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Table, TableColumnType, Tag } from 'antd'
import dayjs from 'dayjs'
import { BranchCards } from 'gadjet-v2-types/dist/model'

import { BranchCardAction } from '@components/organisms/Branch/Config/Tabs/BranchCard'

type Props = {
  branchCards: BranchCards[]
  onAction: (action: BranchCardAction, branchCardId?: number) => void
}

export default function BranchCardTable({ branchCards, onAction }: Props): JSX.Element {
  const columns: TableColumnType<BranchCards>[] = [
    { key: 'cardName', dataIndex: 'cardName', title: '이름' },
    { key: 'cardNumber', dataIndex: 'cardNumber', title: '번호' },
    {
      key: 'registDate',
      dataIndex: 'registDate',
      title: '등록일',
      render: (registDate) => dayjs(registDate).format('YYYY-MM-DD'),
    },
    {
      key: 'billingFlag',
      dataIndex: 'billingFlag',
      title: '활성화',
      render: (_, { billingFlag }) => {
        if (!billingFlag) return <></>
        return <Tag color="blue">활성화</Tag>
      },
    },
    {
      key: 'branchCardId',
      dataIndex: 'branchCardId',
      title: '',
      width: '80px',
      render: (_, { branchCardId, billingFlag }) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item disabled={billingFlag} onClick={() => onClickActive(branchCardId)}>
                활성화
              </Menu.Item>
              <Menu.Item disabled={billingFlag} onClick={() => onClickRemove(branchCardId)}>
                제거
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  const onClickRemove = (branchCardId: number) => onAction('delete', branchCardId)
  const onClickActive = (branchCardId: number) => onAction('active', branchCardId)

  return (
    <Table<BranchCards>
      title={() => (
        <Button type="primary" onClick={() => onAction('add')}>
          카드추가
        </Button>
      )}
      columns={columns}
      dataSource={branchCards}
      rowKey={({ branchCardId }) => `${branchCardId}`}
    />
  )
}
