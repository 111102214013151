import dayjs from 'dayjs'
import { contractBillingType } from 'gadjet-v2-types/dist/type/label'
import { useMemo } from 'react'

import TableColumnItem from '@components/molecules/TableColumn/Item'

export default function BillingType({ contract }: ContractColumnProps): JSX.Element {
  const { startDate, paymentDate, paymentDueDate, billingType } = contract

  const paymentDateLabel = useMemo(() => {
    if (billingType === 'monthly') {
      return `매 월 ${paymentDate}일 부터 ${paymentDueDate}일 간`
    }
    if (billingType === 'prepayment') {
      return dayjs(startDate).format('YYYY년 M월 D일 까지')
    }

    return ''
  }, [startDate, billingType])

  return (
    <div>
      <TableColumnItem label="납부방식" value={contractBillingType[billingType]} />
      <TableColumnItem label="납부일" value={paymentDateLabel} />
    </div>
  )
}
