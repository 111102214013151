import { Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'
import { Bills } from 'gadjet-v2-types/dist/model'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { krw } from '@utils/format'

import { getHqDashboardUnpaidBills } from '@reducers/hq/dashboard.reducer'
import { RootState } from '@reducers/index'

import DashboardCard from '@components/molecules/Dashboard/Card'

type Props = { hqId: number }

export default function DashboardUnpaidBills({ hqId }: Props): JSX.Element {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const bills = useSelector((state: RootState) => state.hqDashboard.unpaidBills)

  const getUnpaidBills = async () => {
    setLoading(true)
    dispatch(
      getHqDashboardUnpaidBills(hqId, () => {
        setLoading(false)
      })
    )
  }

  useEffect(() => {
    getUnpaidBills()
  }, [])

  const columns = useMemo(
    (): TableColumnsType<Bills> => [
      { key: 'branch', dataIndex: 'branch', title: '지점', render: (_, { branch }) => branch?.name },
      { key: 'contract', dataIndex: 'contract', title: '입주사', render: (_, { contract }) => contract?.tenant.name },
      {
        key: 'paymentEndDate',
        dataIndex: 'paymentEndDate',
        title: '납부마감일',
        render: (_, { paymentEndDate }) => dayjs(paymentEndDate).format('MM월 DD일'),
      },
      {
        key: 'unpaidPrice',
        dataIndex: 'unpaidPrice',
        title: '미납금',
        render: (_, { unpaidPrice }) => <Typography.Text type="danger">{krw(unpaidPrice)}</Typography.Text>,
      },
    ],
    []
  )

  return (
    <DashboardCard title="미납 청구서" loading={loading}>
      <Table
        dataSource={bills}
        columns={columns}
        size="small"
        pagination={false}
        rowKey={({ billId }) => `${billId}`}
      />
    </DashboardCard>
  )
}
