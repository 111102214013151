import { Modal } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

export default function PopUp(): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)

  const handleOk = () => {
    setIsModalVisible(false)
    const expiry: string | null = dayjs().add(7, 'day').format('YYYY/MM/DD')
    localStorage.setItem('popUpSeen', expiry)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onClickViewDetail = () => {
    window.open('https://gadjet.notion.site/2022-04-14-09cf475396ce4f98baf5653791e03180')
  }

  return (
    <>
      <Modal
        closable={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        mask={false}
        okText="7일간 다시보지 않기"
        cancelText="닫기"
        focusTriggerAfterClose={false}
        width="700px"
        bodyStyle={{ padding: 0 }}
        centered
      >
        <img
          src="/images/popup/new-policy.png"
          alt="popup-content"
          onClick={onClickViewDetail}
          aria-hidden="true"
          style={{ cursor: 'pointer' }}
        />
      </Modal>
    </>
  )
}
