import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { Button, Divider } from 'antd'

import ModalAlert from '@components/molecules/ModalAlert'

export default function Memo({ contract }: ContractColumnProps): JSX.Element {
  const { publicMemo, privateMemo } = contract

  const [modal, onVisible, onClose] = useModal({ visible: false })

  return (
    <div>
      <Button onClick={() => onVisible()}>메모보기</Button>
      <ModalAlert title="메모" visible={modal.visible} onCancel={onClose} onOk={onClose}>
        <>
          <div style={styles.label}> 공개 메모 (계약서에 포함됩니다.)</div>
          <div style={styles.public}>{publicMemo}</div>
          <Divider />
          <div style={styles.label}>비공개 메모 (입주사가 확인할 수 없습니다.)</div>
          <div style={styles.private}>{privateMemo}</div>
        </>
      </ModalAlert>
    </div>
  )
}

const styles: Styles = {
  label: { fontSize: '12px', opacity: 0.6, marginBottom: '4px' },
  public: { padding: '5px', borderRadius: '5px', backgroundColor: '#eee', minHeight: '100px' },
  private: { padding: '5px', borderRadius: '5px', backgroundColor: '#eee', minHeight: '100px' },
}
