import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { Col, Form, FormInstance, Input, Modal, ModalProps, notification, Row, Select, Typography } from 'antd'
import dayjs from 'dayjs'
import { Branches, Credits, RentalReservations, Rentals, Tenants } from 'gadjet-v2-types/dist/model'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import formRule from '@utils/formRule'

import RentalAPI from '@apis/branch/rental'
import TenantAPI from '@apis/branch/tenant'
import HqBranchAPI from '@apis/hq/branch'
import branchQueryKey from '@apis/queryKey/branch'
import hqQueryKey from '@apis/queryKey/hq'
import tenantQueryKey from '@apis/queryKey/tenant'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'

import RentalReservationCancelModal from '../CancelModal'
import UseCredit from './UseCredit'

type Props = {
  visible: boolean
  hqId: number
  branchId: number
  rentalReservation: Partial<RentalReservations>
  onDone: () => void
  onClose: () => void
}

export default function RentalReservationFormModal({
  visible,
  hqId,
  branchId,
  rentalReservation,
  onClose,
  onDone,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm<RentalReservations>()

  const [selectBranchId, setSelectBranchId] = useState<number>(branchId)
  const [selectTenantId, setSelectTenantId] = useState<null | number>(rentalReservation?.tenantId || null)
  const [selectRentalId, setSelectRentalId] = useState<null | number>(rentalReservation?.rentalId || null)
  const [selectDate, setSelectDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [tenantSearchQuery, setTenantSearchQuery] = useState('')

  const [branches, setBranches] = useState<Branches[]>([])
  const [rentals, setRentals] = useState<Rentals[]>([])
  const [tenants, setTenants] = useState<Tenants[]>([])
  const [tenantCredit, setTenantCredit] = useState({ buy: 0, contract: 0, mileage: 0 })

  const rental = useMemo(() => rentals.find((r) => r.rentalId === selectRentalId), [rentals, selectRentalId])

  const branchQuery = useQuery(hqQueryKey.getBranches(hqId), () => HqBranchAPI.getHqBranches({ hqId }), {
    onSuccess: ({ data }) => setBranches(data),
  })

  const rentalQuery = useQuery(
    branchQueryKey.getBranchRentals(selectBranchId),
    () => RentalAPI.getRentals({ hqId, branchId: selectBranchId }, { current: 1, pageSize: 1000, query: '' }),
    {
      onSuccess: ({ data }) => {
        setRentals(data.rentals)
        if (data.rentals.length > 0 && !rental) {
          const defaultRentalId = data.rentals[0].rentalId
          form.setFieldsValue({ rentalId: defaultRentalId })
          setSelectRentalId(defaultRentalId)
        }
      },
    }
  )

  const tenantQuery = useQuery(
    branchQueryKey.getBranchTenants(selectBranchId, {
      contractStatus: ['started', 'before-started'],
      current: 1,
      pageSize: 20,
      query: tenantSearchQuery,
    }),
    () =>
      TenantAPI.getTenants(
        { hqId, branchId: selectBranchId },
        { contractStatus: ['started', 'before-started'], current: 1, pageSize: 20, query: tenantSearchQuery }
      ),
    { onSuccess: ({ data }) => setTenants(data.tenants) }
  )

  const tenantCreditQuery = useQuery(
    tenantQueryKey.getCredits(hqId, selectBranchId, selectTenantId || 0, {
      creditTypes: ['buy', 'contract', 'mileage'],
      current: 1,
      date: selectDate,
      pageSize: 1000,
    }),
    () =>
      TenantAPI.getTenantCredits(
        { hqId, branchId: selectBranchId, tenantId: selectTenantId || 0 },
        { creditTypes: ['buy', 'contract', 'mileage'], current: 1, date: form.getFieldValue('date'), pageSize: 1000 }
      ),
    {
      onSuccess: ({ data }) => setTenantCredit(combineCredits(data.credits)),
    }
  )
  const combineCredits = (credits: Credits[]) => {
    const tenantCredit = { buy: 0, contract: 0, mileage: 0 }
    credits.forEach(({ type, amount }) => {
      tenantCredit[type] += amount
    })
    return tenantCredit
  }

  const [cancelModal, onVisibleCancelModal, onCloseCancelModal] = useModal({ visible: false })

  const isUpdate = useMemo(() => !!rentalReservation.rentalReservationId, [rentalReservation])

  const onChangeTenant = (_tenantId: number | null) => {
    const _managerFlag = _tenantId === -1 || _tenantId === null
    form.setFieldsValue({ managerFlag: _managerFlag })
    setSelectTenantId(_tenantId)
  }

  const onChangeBranch = (branchId: number) => {
    setSelectBranchId(branchId)
  }

  const onChangeRental = (rentalId: number) => {
    setSelectRentalId(rentalId)
    setUsedCreditAmount()
  }

  const getPrice = useCallback(
    ({ startTime, endTime }: RentalReservations) => {
      if (!rental) return 0

      const diffMinutes = dayjs(endTime, 'HH:mm').diff(dayjs(startTime, 'HH:mm'), 'minutes')
      return rental.price * Math.round(diffMinutes / 30)
    },
    [rental]
  )

  const onChangeDate = (value: unknown, dateString: string) => setSelectDate(dateString)

  const setUsedCreditAmount = () => {
    const creditTypes = rental?.availableCreditType || []

    const isAvailableContractType = creditTypes.some((ct) => ct === 'contract')
    const isAvailableBuyType = creditTypes.some((ct) => ct === 'buy')
    const isAvailableMileageType = creditTypes.some((ct) => ct === 'mileage')

    const contractCredit = isAvailableContractType ? tenantCredit.contract : 0
    const buyCredit = isAvailableBuyType ? tenantCredit.buy : 0
    const mileageCredit = isAvailableMileageType ? tenantCredit.mileage : 0

    const price = getPrice(form.getFieldsValue())

    if (price <= contractCredit) {
      form.setFieldsValue({ usedCreditAmount: { contract: Math.max(0, price), mileage: 0, buy: 0 } })
    }
    if (price > contractCredit && price <= contractCredit + mileageCredit) {
      form.setFieldsValue({ usedCreditAmount: { contract: contractCredit, mileage: price - contractCredit, buy: 0 } })
    }
    if (price > contractCredit && price > contractCredit + mileageCredit) {
      form.setFieldsValue({
        usedCreditAmount: {
          contract: contractCredit,
          mileage: mileageCredit,
          buy: Math.min(buyCredit, Math.max(0, price - (contractCredit + mileageCredit))),
        },
      })
    }
  }

  const onOk = async () => {
    if (isUpdate) return

    const values = await form.validateFields()
    if (!values) return

    const usedCreditAmountTotal = Object.values(values.usedCreditAmount).reduce((a, b) => a + b, 0)
    const price = getPrice(values)

    if (!values.managerFlag && price !== usedCreditAmountTotal) {
      notification.error({ message: '크레딧을 확인하세요.' })
      return
    }

    setLoading(true)
    const { rentalReservationId } = rentalReservation

    let isSuccess = false

    if (!rentalReservationId) {
      const { data } = await RentalAPI.addRentalReservations(
        { hqId, branchId, rentalId: values.rentalId },
        { rentalReservation: values }
      )
      isSuccess = data !== null
    }
    if (rentalReservationId) {
      const { data } = await RentalAPI.updateRentalReservation(
        { hqId, branchId, rentalId: values.rentalId, rentalReservationId },
        { rentalReservation: values }
      )

      isSuccess = data[0] > 0
    }
    setLoading(false)
    onDone()

    if (isSuccess) {
      notification.success({ message: '예약 되었습니다.' })
      onClose()
    } else {
      notification.error({ message: '예약 실패' })
    }
  }

  const onClickCancel = () => onVisibleCancelModal()
  const onDoneCancel = () => {
    onCloseCancelModal()
    onDone()
    onClose()
  }

  const onTenantSearch = useCallback(
    debounce((query: string) => setTenantSearchQuery(query), 500),
    []
  )

  const tenantOptions = useMemo(() => {
    const sortTenants = [...tenants]
    if (rentalReservation?.tenant) {
      const index = sortTenants.findIndex((rt) => rt.tenantId === rentalReservation.tenant?.tenantId)
      sortTenants.splice(index, 1)
      sortTenants.unshift(rentalReservation.tenant)
    }
    return [
      { value: -1, label: <Typography.Text type="secondary">관리자 예약</Typography.Text> },
      ...sortTenants.map((t) => ({ value: t.tenantId, label: t.name })),
    ]
  }, [tenants, rentalReservation])

  const branchOptions = useMemo(() => branches.map((b) => ({ label: b.name, value: b.branchId })), [branches])
  const rentalOptions = useMemo(() => rentals.map((r) => ({ value: r.rentalId, label: r.name })), [rentals])

  const initialValues = {
    rentalReservationId: rentalReservation.rentalReservationId,
    branchId,
    rentalId: null,
    tenantId: null,
    date: selectDate,
    startTime: dayjs().format('HH:00'),
    endTime: dayjs().add(1, 'hours').format('HH:00'),
    usedCreditAmount: { buy: 0, contract: 0, mileage: 0 },
    memo: '',
    managerFlag: true,
  }

  const reset = () => {
    form.resetFields()
    const { tenantId = -1 } = { ...initialValues, ...rentalReservation }

    const rentalId = rentals?.length > 0 ? rentals[0].rentalId : undefined
    form.setFieldsValue({ rentalId, ...rentalReservation })

    onChangeTenant(tenantId)
    onTenantSearch('')
  }

  useEffect(() => {
    if (visible) reset()
  }, [visible, rentalReservation])

  useEffect(() => {
    setUsedCreditAmount()
  }, [tenantCredit, rental])

  const okButton = useMemo((): Partial<ModalProps> => {
    if (isUpdate)
      return {
        okText: '예약취소',
        okType: 'danger',
        okButtonProps: { loading },
        onOk: onClickCancel,
      }
    return {
      okText: '예약하기',
      okButtonProps: {
        disabled: form.getFieldsError().some(({ errors }) => errors.length > 0),
        loading,
      },
      onOk,
    }
  }, [isUpdate, onOk])

  return (
    <Modal visible={visible} onCancel={onClose} title="대여/대관 예약" cancelText="닫기" {...okButton}>
      <Loading loading={loading}>
        <Form<RentalReservations> layout="vertical" form={form} initialValues={initialValues}>
          <HiddenItems names={['managerFlag', 'rentalReservationId', 'tenantId', 'startTime', 'endTime']} />
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }: FormInstance<RentalReservations>) => {
              getPrice(getFieldsValue())
            }}
          </Form.Item>

          <Row gutter={10}>
            <Col span={8}>
              <Form.Item label="지점" name="branchId" required>
                <Select<number>
                  showSearch
                  loading={branchQuery.isLoading}
                  onChange={onChangeBranch}
                  filterOption
                  optionFilterProp="label"
                  options={branchOptions}
                  disabled={isUpdate}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                dependencies={['managerFlag']}
                label="입주사"
                name="tenantId"
                required
                getValueProps={(value) => ({ value: value || -1 })}
                getValueFromEvent={(value) => (value === -1 ? null : value)}
              >
                <Select<number>
                  showSearch
                  loading={tenantQuery.isLoading}
                  onSearch={onTenantSearch}
                  onChange={(v) => onChangeTenant(v)}
                  filterOption
                  optionFilterProp="label"
                  options={tenantOptions}
                  disabled={isUpdate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item label="대여/대관" name="rentalId" rules={[formRule.required]}>
                <Select
                  disabled={isUpdate}
                  loading={rentalQuery.isLoading}
                  options={rentalOptions}
                  onChange={onChangeRental}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                label="날짜"
                name="date"
                getValueProps={(value) => ({ value: dayjs(value) })}
                getValueFromEvent={(value) => dayjs(value).format('YYYY-MM-DD')}
                rules={[formRule.required]}
                required
              >
                <LocalDatePicker style={styles.picker} allowClear={false} onChange={onChangeDate} disabled={isUpdate} />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldsValue }) => {
                  const startTime = getFieldValue('startTime')
                  const endTime = getFieldValue('endTime')
                  return (
                    <Form.Item label="시간" required>
                      <LocalDatePicker.RangePicker
                        picker="time"
                        format="HH:mm"
                        minuteStep={30}
                        allowClear={false}
                        inputReadOnly
                        value={[dayjs(startTime, 'HH:mm'), dayjs(endTime, 'HH:mm')]}
                        onChange={(_, [s, e]) => {
                          setFieldsValue({ startTime: s, endTime: e })
                          setUsedCreditAmount()
                        }}
                        disabled={isUpdate}
                      />
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="메모" name="memo">
            <Input.TextArea style={styles.memo} disabled={isUpdate} />
          </Form.Item>

          <UseCredit
            rental={rental}
            loading={tenantCreditQuery.isLoading}
            tenantCredit={tenantCredit}
            getPrice={getPrice}
            hidden={isUpdate}
          />
        </Form>
        <RentalReservationCancelModal
          hqId={hqId}
          branchId={branchId}
          rentalId={rentalReservation.rentalId}
          rentalReservationId={rentalReservation.rentalReservationId}
          visible={cancelModal.visible}
          onClose={() => onCloseCancelModal()}
          onDone={onDoneCancel}
        />
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  memo: { height: '100px' },
  picker: { width: '100%' },
  cancelButton: { display: 'flex', margin: 'auto' },
}
