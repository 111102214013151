import { notification } from 'antd'

import RoleAPI from '@apis/branch/role'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  branchRoleId: number
  visible: boolean
  onClose: () => void
  onDone?: () => void
}
export default function BranchRoleDeleteModal({
  hqId,
  branchId,
  branchRoleId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    await RoleAPI.deleteRole({ hqId, branchId, branchRoleId })
    notification.success({ message: '제거되었습니다.' })

    if (onDone) onDone()
  }

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      visible={visible}
      title="권한 제거"
      description="권한이 제거됩니다."
    />
  )
}
