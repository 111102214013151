import { Modal, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { useState, useEffect } from 'react'

import bufferToFileDownload from '@utils/download'

import CashAPI from '@apis/branch/cash'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function CashExportModal({ hqId, branchId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<{ date: [string, string] }>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      const { data } = await CashAPI.exportCash({ hqId, branchId }, values)
      bufferToFileDownload(`현금.xlsx`, Buffer.from(data))

      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const reset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  const initialValues = {
    date: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD')],
  }

  return (
    <Modal title="내보내기" okText="내보내기" onOk={onOk} confirmLoading={loading} visible={visible} onCancel={onClose}>
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item
            name="date"
            label="기간"
            getValueProps={([f, t]) => ({ value: [dayjs(f), dayjs(t)] })}
            getValueFromEvent={(_, s) => s}
            required
          >
            <LocalDatePicker.RangePicker />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
