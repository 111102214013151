import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import style from './style.module.scss'

export default function Merit() {
  const merit = [
    {
      title: '간편하게!',
      content:
        '입주사의 번거로움을 줄여 만족도를 높여 보세요. 가젯을 이용하시면, 모든 결제가 카드로 가능해 집니다. 회의실 예약이 조금 더 쉬워 집니다. 온라인 계약, 서명이 가능해 집니다.',
    },
    {
      title: '쉽게!',
      content:
        "복잡한 업무를 쉽게 개선해 보세요. 가젯을 이용하시면, 어렵고 복잡한 '세금계산서, 현금영수증 발행'을 클릭 한 번으로 간단하게 요청 할 수 있습니다.",
    },
    {
      title: '빠르게!',
      content:
        '자동화 기능으로 업무 속도를 높여 보세요. 가젯을 이용하시면, 매월 발송하는 청구서, 연체료 안내 메일, 매번 발송하는 계약 연장 확인 메일을 자동으로 발송 할 수 있습니다.',
    },
  ]

  return (
    <>
      <div className={style.bg}>
        <div className={style.title}>
          가젯은 <span className={style.colored}>무료</span> 서비스입니다.
        </div>
        <div className={style.merit}>
          {merit.map((el) => (
            <div className={style.container}>
              <FontAwesomeIcon icon={faCheck} style={{ fontSize: '32px', color: '#3ebd73' }} />
              <div className={style.meritTitle}>{el.title}</div>
              <div className={style.meritContent}>{el.content}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
