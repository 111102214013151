import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { RentalSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setRentalPagination, setRentalSearch, searchRentals, setRentalDefault } from '@reducers/branch/rental.reducer'
import { RootState } from '@reducers/index'

import BranchRentalListTable from '@components/organisms/Branch/Rental/ListTable'
import RentalDeleteModal from '@components/organisms/Branch/Rental/Modals/RentalDeleteModal'
import RentalFormModal from '@components/organisms/Branch/Rental/Modals/RentalFormModal'
import BranchRentalSearchBox from '@components/organisms/Branch/Rental/SearchBox'
import BranchRentalTitle from '@components/organisms/Branch/Rental/Title'

export type RentalActionType = 'add' | 'update' | 'delete'

export default function RentalPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult } = useSelector((state: RootState) => state.rental)
  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal({ visible: false, rentalId: 0 })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal({ visible: false, rentalId: 0 })

  const onChangeSearch = (_search: Partial<RentalSearch>) => dispatch(setRentalSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setRentalPagination(_pagination))
    dispatch(searchRentals(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setRentalPagination({ current: 1 }))
    dispatch(searchRentals(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setRentalDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: RentalActionType, rentalId?: number) => {
    if (action === 'add') onVisibleFormModal({ rentalId: 0 })
    if (action === 'update') onVisibleFormModal({ rentalId })
    if (action === 'delete') onVisibleDeleteModal({ rentalId })
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchRentalSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchRentalTitle onAction={onAction} />

      <BranchRentalListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        rentals={searchResult.rentals}
        total={searchResult.total}
        onAction={onAction}
      />

      <RentalFormModal
        hqId={hqId}
        branchId={branchId}
        rentalId={formModal.rentalId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />

      <RentalDeleteModal
        hqId={hqId}
        branchId={branchId}
        rentalId={deleteModal.rentalId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
    </div>
  )
}
