import { BranchSpaceActionType } from '@pages/Authorized/Branch/SpacePage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchSpaceActionType) => void
}

export default function BranchContractTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    { label: '내보내기', buttonType: 'dashed', onClick: () => onAction('export') },
    { label: '업로드하기', buttonType: 'dashed', onClick: () => onAction('upload') },
    // { label: '층/구역 관리', buttonType: 'default', onClick: () => onAction('floor') },
    { label: '유형 관리', buttonType: 'default', onClick: () => onAction('space-type') },
    { label: '공간 추가', buttonType: 'primary', onClick: () => onAction('add') },
  ]
  return <PageTitle title="공간" actions={actions} />
}
