import { Styles } from '@types'
import { Button, Table, TableColumnType, TablePaginationConfig } from 'antd'
import { ProductSales } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

import { BranchProductSaleAction } from '@pages/Authorized/Branch/ProductSalePage'

import ContentWrapper from '@components/atoms/ContentWrapper'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  productSales: ProductSales[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchProductSaleAction, productSaleId: number) => void
}
export default function BranchProductSaleListTable({
  loading,
  total,
  pagination,
  productSales,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<ProductSales>[] = [
    {
      key: 'tenantId',
      dataIndex: 'tenantId',
      title: '입주사',
      render: (_, { tenant }) => tenant?.name,
    },
    {
      key: 'productId',
      dataIndex: 'productId',
      title: '이름',
      render: (_, { product, name }) => product?.name || name,
    },
    { key: 'amount', dataIndex: 'amount', title: '수량', render: (amount) => `${amount}개` },
    {
      key: 'price',
      dataIndex: 'price',
      title: '금액',
      render: (price) => `${price} 크레딧`,
    },
    { key: 'userId', dataIndex: 'userId', title: '구매자', render: (_, { user }) => user?.name },
    { key: 'datetime', dataIndex: 'datetime', title: '구매일시' },
    {
      key: 'refundFlag',
      dataIndex: 'refundFlag',
      title: '환불',
      render: (refundFlag, { productSaleId }) => {
        if (refundFlag) return <Button disabled>환불완료</Button>
        return <Button onClick={() => onAction('refund', productSaleId)}>환불하기</Button>
      },
    },
  ]

  return (
    <ContentWrapper>
      <Table<ProductSales>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ productSaleId }) => `${productSaleId}`}
        dataSource={productSales}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomRight'],
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
      />
    </ContentWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
