import useMenu from '@hooks/useMenu'
import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import PopUp from '@components/molecules/PopUp'
import UpdatePopUp from '@components/molecules/UpdatePopUp'
import EndContracts from '@components/organisms/Hq/Dashboard/EndContracts'
import RecentContracts from '@components/organisms/Hq/Dashboard/RecentContracts'
import SearchContracts from '@components/organisms/Hq/Dashboard/SearchContracts'
import Spaces from '@components/organisms/Hq/Dashboard/Spaces'
import StartContracts from '@components/organisms/Hq/Dashboard/StartContracts'
import UnpaidBills from '@components/organisms/Hq/Dashboard/UnpaidBills'

export default function DashboardPage(): JSX.Element {
  const { hqId } = useMenu()
  const [showUpdateCheck, setShowUpdateCheck] = useState<boolean>(true)
  const [displayPopUp, setDisplayPopUp] = useState<boolean>(true)

  useEffect(() => {
    const expiry: string | null = localStorage.getItem('updateCheck')
    const now: string = dayjs().format('YYYY/MM/DD')
    const startPoint: string = dayjs('2022-05-07').format('YYYY/MM/DD')

    if (now < startPoint) {
      setShowUpdateCheck(false)
    } else {
      if (!expiry || expiry < now) setShowUpdateCheck(true)
      if (expiry && expiry > now) setShowUpdateCheck(false)
    }
  }, [])

  useEffect(() => {
    const expiry: string | null = localStorage.getItem('popUpSeen')
    const now: string = dayjs().format('YYYY/MM/DD')
    const startPoint: string = dayjs('2022-04-14').format('YYYY/MM/DD')

    if (now < startPoint) {
      setDisplayPopUp(false)
    } else {
      if (!expiry || expiry < now) setDisplayPopUp(true)
      if (expiry && expiry > now) setDisplayPopUp(false)
    }
  }, [])

  return (
    <>
      {showUpdateCheck && <UpdatePopUp />}
      {displayPopUp && <PopUp />}
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <SearchContracts hqId={hqId} />
        </Col>
        <Col span={12}>
          <UnpaidBills hqId={hqId} />
        </Col>
        <Col span={8}>
          <RecentContracts hqId={hqId} />
        </Col>
        <Col span={8}>
          <StartContracts hqId={hqId} />
        </Col>
        <Col span={8}>
          <EndContracts hqId={hqId} />
        </Col>
        <Col span={24}>
          <Spaces hqId={hqId} />
        </Col>
      </Row>
    </>
  )
}
