import { MoreOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Table, TableColumnType, TablePaginationConfig } from 'antd'
import dayjs from 'dayjs'
import { Receipts } from 'gadjet-v2-types/dist/model'
import { ReceiptStatus, ReceiptType, Pagination } from 'gadjet-v2-types/dist/type'
import { ntsResultCode, receiptStatus, receiptType } from 'gadjet-v2-types/dist/type/label'

import { krw } from '@utils/format'

import { BranchReceiptActionType } from '@pages/Authorized/Branch/ReceiptPage'

import ContentWrapper from '@components/atoms/ContentWrapper'
import TableColumnItem from '@components/molecules/TableColumn/Item'
import TypeTag from '@components/molecules/TypeTag'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  receipts: Receipts[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchReceiptActionType, data?: { receiptId: number; billId: number }) => void
}

export default function BranchReceiptListTable({
  loading,
  total,
  pagination,
  receipts,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<Receipts>[] = [
    { key: 'type', dataIndex: 'type', title: '타입', render: (type: ReceiptType) => receiptType[type] },
    {
      key: 'json',
      dataIndex: 'json',
      title: '대상',
      render: (_, { type, cashBillJson, taxInvoiceJson }) => {
        let name = ''
        let number = ''

        if (type === 'cash-receipt') {
          const { customerName, identityNum } = cashBillJson
          name = customerName || ''
          number = identityNum
        }

        if (type === 'tax-invoice') {
          const { invoiceeCorpName, invoiceeCorpNum } = taxInvoiceJson
          name = invoiceeCorpName
          number = invoiceeCorpNum
        }

        return (
          <>
            <TableColumnItem label="대상" value={name} />
            <TableColumnItem label="식별번호" value={number} />
          </>
        )
      },
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: '금액',
      render: (_, { type, taxInvoiceJson, cashBillJson }) => {
        if (type === 'tax-invoice')
          return (
            <>
              <TableColumnItem label="공급가액" value={krw(taxInvoiceJson.supplyCostTotal)} />
              <TableColumnItem label="부가세" value={krw(taxInvoiceJson.taxTotal)} />
            </>
          )
        if (type === 'cash-receipt')
          return (
            <>
              <TableColumnItem label="공급가액" value={krw(cashBillJson.supplyCost)} />
              <TableColumnItem label="부가세" value={krw(cashBillJson.tax)} />
            </>
          )
        return <></>
      },
    },
    {
      key: 'requestDatetime',
      dataIndex: 'requestDatetime',
      title: '요청시간',
      render: (requestDatetime) => dayjs(requestDatetime).format('YYYY년 MM월 DD일 HH시 mm분 ss초'),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: '상태',
      render: (status: ReceiptStatus) => (
        <TypeTag<ReceiptStatus>
          label={receiptStatus[status]}
          value={status}
          switchCase={{
            types: ['default', 'ongoing', 'success', 'fail'],
            values: [
              ['none'],
              ['cash-receipt-request', 'tax-invoice-request'],
              ['tax-invoice-done', 'cash-receipt-done'],
              ['cash-receipt-cancel', 'tax-invoice-updated'],
            ],
          }}
        />
      ),
    },
    {
      key: 'receiptId',
      dataIndex: 'receiptId',
      title: '국세청',
      render: (_, r) => {
        return (
          <>
            <TableColumnItem label="승인번호" value={r.ntsConfirmNumber} />
            <TableColumnItem label="응답결과" value={ntsResultCode[r.ntsResultCode]} />
          </>
        )
      },
    },
    {
      key: 'receiptId',
      dataIndex: 'receiptId',
      align: 'center',
      title: '',
      width: '80px',
      render: (_, { receiptId, type, status, billId }) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="tax-invoice"
                hidden={type !== 'tax-invoice'}
                onClick={() => onAction('update-tax-invoice', { receiptId, billId })}
                disabled={status !== 'tax-invoice-done'}
              >
                세금계산서 수정발행
              </Menu.Item>
              <Menu.Item
                key="cash-receipt"
                hidden={type !== 'cash-receipt'}
                onClick={() => onAction('cancel-cash-receipt', { receiptId, billId })}
              >
                현금영수증 취소
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]
  return (
    <ContentWrapper>
      <Table<Receipts>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ receiptId }) => `${receiptId}`}
        dataSource={receipts}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomRight'],
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
      />
    </ContentWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
