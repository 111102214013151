import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

export type BranchRentalAction = 'add'

type Props = {
  onAction: (type: BranchRentalAction) => void
}

export default function BranchRentalTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [{ label: '추가', buttonType: 'primary', onClick: () => onAction('add') }]

  return <PageTitle title="대관/대여" actions={actions} />
}
