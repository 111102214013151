import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { Button, Col, Row, Table, TableColumnType, TablePaginationConfig } from 'antd'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { useEffect, useMemo } from 'react'

import { onChangeTable } from '@utils/antd-table'

import ContentWrapper from '@components/atoms/ContentWrapper'

import TableColumnModal from '../Modals/TableColumModal'
import columns from './Columns'
import Action, { ContractRowAction } from './Columns/Action'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  contracts: Contracts[]
  activeColumnKeys: Array<keyof Contracts>
  onChangePagination: (pagination: Pagination) => void
  onChangeColumns: (activeColumns: Array<keyof Contracts>) => void
  onRowAction: (action: ContractRowAction, c: Contracts) => void
}

export default function BranchContractListTable({
  loading,
  total,
  pagination,
  contracts,
  activeColumnKeys,
  onChangePagination,
  onChangeColumns,
  onRowAction,
}: Props): JSX.Element {
  const _columns = useMemo(() => columns(onRowAction), [onRowAction])
  const activeColumns = useMemo(() => _columns.filter((c) => activeColumnKeys.includes(c.key)), [activeColumnKeys])
  const [columnModal, onVisibleColumnModal, onCloseColumnModal] = useModal({ visible: false })

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Contracts> | SorterResult<Contracts>[]
  ) => {
    const { current, pageSize, sort } = onChangeTable(pagination, filters, sorter)
    onChangePagination({ current, pageSize, sort })
  }

  const onApplyTableColumns = (_activeColumns: Array<keyof Contracts>) => onChangeColumns(_activeColumns)

  const actionColumn: TableColumnType<Contracts> = {
    key: 'contractId',
    dataIndex: 'contractId',
    title: ' ',
    fixed: 'right',
    render: (_, contract) => <Action contract={contract} onRowAction={onRowAction} />,
  }

  useEffect(() => {
    // 계약 컬럼 기본값 설정
    if (activeColumns.length === 0) {
      const visibleColumns = _columns.map(({ key }) => key)
      onApplyTableColumns(visibleColumns)
    }
  }, [activeColumns])

  return (
    <ContentWrapper>
      <Table<Contracts>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ contractId }) => `${contractId}`}
        dataSource={contracts}
        columns={[...activeColumns, actionColumn]}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomRight'],
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
        title={() => (
          <Row>
            <Col flex={1} />
            <Button type="text" onClick={() => onVisibleColumnModal()}>
              항목편집
            </Button>
            <TableColumnModal
              columns={_columns}
              visible={columnModal.visible}
              onApply={onApplyTableColumns}
              onClose={onCloseColumnModal}
            />
          </Row>
        )}
      />
    </ContentWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
