import { takeEvery } from 'redux-saga/effects'
import { all, call, put } from 'typed-redux-saga'

import { setToken } from '@utils/axios'

import AuthAPI from '@apis/auth'

import { LOGOUT, getAuthSuccess, getAuthFail, GET_AUTH, logoutSuccess, GET_AUTH_FAIL } from '@reducers/auth.reducer'
import { getMenu } from '@reducers/branch/menu.reducer'

function* getAuthSaga(): Generator {
  try {
    const { data: _manager } = yield* call(AuthAPI.getAuth, {})
    if (!_manager) throw new Error('잘못된 토큰 입니다.')
    yield* put(getAuthSuccess(_manager))
    yield* put(getMenu(_manager.managerId))
  } catch (err) {
    yield* put(getAuthFail())
  }
}

function getAuthFailSaga() {
  const LOGIN_PATH = '/login' as const
  const current = window.location.pathname
  if (LOGIN_PATH !== current && current !== '/') window.location.replace(LOGIN_PATH)
}

function* logoutSaga(): Generator {
  setToken('')
  yield* put(logoutSuccess())
  window.location.href = '/'
}

export default function* authSaga(): Generator {
  yield* all([
    takeEvery(GET_AUTH, getAuthSaga),
    takeEvery(GET_AUTH_FAIL, getAuthFailSaga),
    takeEvery(LOGOUT, logoutSaga),
  ])
}
