import { Styles } from '@types'
import { Alert, Col, Divider, Form, InputNumber, Row } from 'antd'
import { RentalReservations, Rentals } from 'gadjet-v2-types/dist/model'
import { UsedCreditAmount } from 'gadjet-v2-types/dist/type'
import { useMemo } from 'react'

import Loading from '@components/molecules/Loading'

type Props = {
  rental?: Rentals
  tenantCredit: UsedCreditAmount
  loading?: boolean
  getPrice: (rentalReservations: RentalReservations) => number
  hidden?: boolean
}

export default function RentalReservationUseCredit({
  rental,
  tenantCredit,
  loading,
  getPrice,
  hidden,
}: Props): JSX.Element {
  const available = useMemo(() => {
    const creditTypes = rental?.availableCreditType || []
    return {
      contract: creditTypes.some((ct) => ct === 'contract'),
      buy: creditTypes.some((ct) => ct === 'buy'),
      mileage: creditTypes.some((ct) => ct === 'mileage'),
    }
  }, [rental])

  return (
    <Form.Item<RentalReservations> shouldUpdate noStyle>
      {({ getFieldsValue }) => {
        const values = getFieldsValue()
        const { startTime, endTime, tenantId } = values
        if (!rental) return <></>

        const { buy = 0, contract = 0, mileage = 0 } = values.usedCreditAmount || {}
        const totalCreditAmount = buy + contract + mileage

        const price = getPrice(values)

        return (
          <Form.Item hidden={hidden || !tenantId}>
            <Loading loading={loading}>
              <>
                <Row gutter={20}>
                  <Col span={8}>
                    <div style={styles.label}>대여/대관</div>
                    <div style={styles.value}>{rental.name}</div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.label}>선택시간</div>
                    <div style={styles.value}>
                      {startTime} ~ {endTime}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.label}>필요 크레딧</div>
                    <div style={styles.value}>
                      {price}
                      <span style={styles.unit}> 크레딧</span>
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={[20, 20]}>
                  <Col span={8}>
                    <div style={styles.label}>계약 크레딧</div>
                    <Form.Item
                      noStyle
                      name={['usedCreditAmount', 'contract']}
                      rules={[{ min: 0, max: tenantCredit.contract, type: 'number', message: '' }]}
                    >
                      <InputNumber
                        style={styles.inputNumber}
                        min={0}
                        max={tenantCredit.contract}
                        disabled={!available.contract}
                      />
                    </Form.Item>
                    <div style={styles.help}>
                      {0} ~ {tenantCredit.contract}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.label}>마일리지 크레딧</div>
                    <Form.Item
                      noStyle
                      name={['usedCreditAmount', 'mileage']}
                      rules={[{ min: 0, max: tenantCredit.mileage, type: 'number', message: '' }]}
                    >
                      <InputNumber
                        style={styles.inputNumber}
                        min={0}
                        max={tenantCredit.mileage}
                        disabled={!available.mileage}
                      />
                    </Form.Item>
                    <div style={styles.help}>
                      {0} ~ {tenantCredit.mileage}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.label}>구매 크레딧</div>
                    <Form.Item
                      noStyle
                      name={['usedCreditAmount', 'buy']}
                      rules={[{ min: 0, max: tenantCredit.buy, type: 'number', message: '' }]}
                    >
                      <InputNumber
                        style={styles.inputNumber}
                        min={0}
                        max={tenantCredit.buy}
                        disabled={!available.buy}
                      />
                    </Form.Item>
                    <div style={styles.help}>
                      {0} ~ {tenantCredit.buy}
                    </div>
                  </Col>
                  {totalCreditAmount !== price && (
                    <Col span={24}>
                      <Alert
                        banner
                        showIcon
                        type="error"
                        message={totalCreditAmount !== price ? `${price}크레딧이 필요합니다.` : '크레딧이 부족합니다.'}
                      />
                    </Col>
                  )}
                </Row>
              </>
            </Loading>
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

const styles: Styles = {
  label: { opacity: 0.6, fontSize: 12, textAlign: 'center' },
  value: { fontSize: 16, textAlign: 'center' },
  unit: { fontSize: 12 },
  inputNumber: { width: '100%' },
  help: { opacity: 0.6, fontSize: 12 },
}
