import { Styles } from '@types'
import { Form, FormInstance, Input } from 'antd'
import { Contracts } from 'gadjet-v2-types/dist/model'

import formRule from '@utils/formRule'

type Props = { form: FormInstance<Contracts>; isUpdate: boolean }

export default function EtcTab({ form, isUpdate }: Props): JSX.Element {
  return (
    <>
      <Form.Item label="계약 크레딧" name="creditAmount" rules={[formRule.required]}>
        <Input type="number" addonAfter="크레딧" />
      </Form.Item>

      <Form.Item label="공개 메모" name="publicMemo" extra="계약서에 포함되는 메모입니다.">
        <Input.TextArea style={styles.textarea} />
      </Form.Item>
      <Form.Item label="비공개 메모" name="privateMemo" extra="입주사에게 공개되지 않습니다.">
        <Input.TextArea style={styles.textarea} />
      </Form.Item>
    </>
  )
}

const styles: Styles = {
  textarea: { height: '200px' },
}
