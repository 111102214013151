import * as SPACE from 'gadjet-v2-types/dist/api/branch/space'

import axios from '@utils/axios'

const SpaceAPI = {
  // 공간 조회
  getSpaces: async (
    { hqId, branchId }: SPACE.GET_LIST_PARAMS,
    query: SPACE.GET_LIST_QUERY
  ): Promise<{ data: SPACE.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/spaces`, { params: query }),

  getSpaceDetail: async ({
    hqId,
    branchId,
    spaceId,
  }: SPACE.GET_DETAIL_PARAMS): Promise<{ data: SPACE.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/spaces/${spaceId}`),

  // 공간 요약 조회
  getSpaceSummary: async (
    { hqId, branchId }: SPACE.GET_SUMMARY_PARAMS,
    query: SPACE.GET_SUMMARY_QUERY
  ): Promise<{ data: SPACE.GET_SUMMARY_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/spaces/summary`, { params: query }),

  // 공간 내보내기
  exportSpaces: async (
    { hqId, branchId }: SPACE.EXPORT_PARAMS,
    query: SPACE.EXPORT_QUERY
  ): Promise<{ data: SPACE.EXPORT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/spaces/export`, { params: query }),

  // 공간 업로드
  uploadSpaces: async (
    { hqId, branchId }: SPACE.UPLOAD_PARAMS,
    { spaces }: SPACE.UPLOAD_BODY
  ): Promise<{ data: SPACE.UPLOAD_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/spaces/upload`, { spaces }),

  // 공간 추가
  addSpace: async (
    { hqId, branchId }: SPACE.ADD_PARAMS,
    { space }: SPACE.ADD_BODY
  ): Promise<{ data: SPACE.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/spaces`, { space }),

  // 공간 수정
  updateSpace: async (
    { hqId, branchId, spaceId }: SPACE.UPDATE_PARAMS,
    { space }: SPACE.UPDATE_BODY
  ): Promise<{ data: SPACE.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/spaces/${spaceId}`, { space }),

  // 공간 제거
  deleteSpace: async ({ hqId, branchId, spaceId }: SPACE.DELETE_PARAMS): Promise<{ data: SPACE.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/spaces/${spaceId}`),

  // 공간 계약 조회
  getSpaceContracts: async (
    { hqId, branchId, spaceId }: SPACE.GET_CONTRACTS_PARAMS,
    query: SPACE.GET_CONTRACTS_QUERY
  ): Promise<{ data: SPACE.GET_CONTRACTS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/spaces/${spaceId}/contracts`, { params: query }),
}

export default SpaceAPI
