import { Styles } from '@types'
import { Form, FormInstance, Input } from 'antd'
import { Bills } from 'gadjet-v2-types/dist/model'
import { BillPrice } from 'gadjet-v2-types/dist/type'
import { ChangeEvent } from 'react'

import mask from '@utils/mask'

import FormListTable, { FormListTableColumn } from '@components/molecules/FormListTable'

type Props = {
  label: string
  name: 'spaces' | 'additions' | 'surcharges'
  form: FormInstance<Bills>
}

export default function BillItemList({ label, name, form }: Props): JSX.Element {
  const onChangePrice = (value: string, index: number) => {
    const numberValue = Number(value.replace(/,/g, ''))
    const vat = Math.round(Number(numberValue) / 10)

    const values = form.getFieldsValue()
    const fieldValue = [...values[name]]
    fieldValue[index].vat = vat

    form.setFieldsValue({ [name]: fieldValue })
  }

  const columns: FormListTableColumn[] = [
    {
      label: '이름',
      width: '30%',
      render: (field) => (
        <Form.Item name={[field.name, 'name']} noStyle>
          <Input />
        </Form.Item>
      ),
    },
    {
      label: '가격',
      width: '35%',
      render: (field, index) => (
        <Form.Item name={[field.name, 'price']} noStyle {...mask.krw}>
          <Input
            style={styles.inputNumber}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangePrice(e.target.value, index)}
          />
        </Form.Item>
      ),
    },
    {
      label: '부가세',
      width: '35%',
      render: (field) => (
        <Form.Item name={[field.name, 'vat']} noStyle {...mask.krw}>
          <Input style={styles.inputNumber} />
        </Form.Item>
      ),
    },
  ]

  const defaultValue: BillPrice = { name: '', price: 0, vat: 0 }

  return <FormListTable title={label} name={name} columns={columns} defaultValue={defaultValue} />
}

const styles: Styles = {
  inputNumber: { width: '100%' },
}
