import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Table, TableColumnsType, Tag } from 'antd'
import { HqRoles } from 'gadjet-v2-types/dist/model'

import { HqRoleAction } from '@pages/Authorized/Hq/RolePage'

type Props = { loading: boolean; roles: HqRoles[]; onAction: (action: HqRoleAction, hqRoleId: number) => void }

export default function ListTable({ loading, roles, onAction }: Props): JSX.Element {
  const columns: TableColumnsType<HqRoles> = [
    {
      key: 'manager',
      dataIndex: 'manager',
      title: '매니저',
      width: '20%',
      render: (_, { manager, adminFlag }) => (
        <div>
          {manager?.name} {adminFlag && <Tag color="blue">관리자</Tag>}
        </div>
      ),
    },
    {
      key: 'manager',
      dataIndex: 'manager',
      title: '이메일',
      render: (_, { manager }) => manager?.email || '-',
    },
    {
      key: 'hqRoleId',
      dataIndex: 'hqRoleId',
      title: '',
      align: 'center',
      fixed: 'right',
      width: '80px',
      render: (hqRoleId, { adminFlag }) => (
        <Dropdown
          overlay={
            <Menu disabled={adminFlag}>
              <Menu.Item onClick={() => onAction('update', hqRoleId)}>수정</Menu.Item>
              <Menu.Item onClick={() => onAction('delete', hqRoleId)}>제거</Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return <Table columns={columns} dataSource={roles} loading={loading} rowKey={({ hqRoleId }) => `${hqRoleId}`} />
}
