import { MoreOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Table, TableColumnType, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { Bills, Payments } from 'gadjet-v2-types/dist/model'
import { PaymentStatus, PaymentType } from 'gadjet-v2-types/dist/type'
import { paymentStatus, paymentType } from 'gadjet-v2-types/dist/type/label'
import { useEffect, useMemo, useState } from 'react'

import { krw } from '@utils/format'

import BillAPI from '@apis/branch/bill'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'
import TableColumnItem from '@components/molecules/TableColumn/Item'
import TypeTag from '@components/molecules/TypeTag'
import PaymentConfirmModal from '@components/organisms/Branch/Payment/Modals/PaymentConfirmModal'
import PaymentDeleteModal from '@components/organisms/Branch/Payment/Modals/PaymentDeleteModal'
import PaymentFormModal from '@components/organisms/Branch/Payment/Modals/PaymentFormModal'
import PaymentRefundModal from '@components/organisms/Branch/Payment/Modals/PaymentRefundModal'

type Props = {
  hqId: number
  branchId: number
  billId: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function BillPaymentModal({ hqId, branchId, billId, visible, onClose, onDone }: Props): JSX.Element {
  const [bill, setBill] = useState<Bills | null>(null)
  const [loading, setLoading] = useState(false)

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ paymentId?: number }>({
    visible: false,
    paymentId: 0,
  })
  const [confirmModal, onVisibleConfirmModal, onCloseConfirmModal] = useModal<{ paymentId?: number }>({
    visible: false,
    paymentId: 0,
  })
  const [refundModal, onVisibleRefundModal, onCloseRefundModal] = useModal<{ paymentId?: number }>({
    visible: false,
    paymentId: 0,
  })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ paymentId?: number }>({
    visible: false,
    paymentId: 0,
  })

  const getBill = async () => {
    setLoading(true)
    const { data } = await BillAPI.getBill({ hqId, branchId, billId })

    setBill(data)
    setLoading(false)
  }

  const _onDone = () => {
    getBill()
    onDone()
  }

  useEffect(() => {
    if (visible) getBill()
  }, [visible])

  const columns: TableColumnType<Payments>[] = [
    {
      key: 'approveDatetime',
      dataIndex: 'approveDatetime',
      title: '일시',
      render: (approveDatetime: string, { refundDatetime }) => (
        <>
          <TableColumnItem label="결제일시" value={dayjs(approveDatetime).format('YYYY년 MM월 DD일 HH시 mm분 ss초')} />
          {refundDatetime && (
            <TableColumnItem label="취소일시" value={dayjs(refundDatetime).format('YYYY년 MM월 DD일 HH시 mm분 ss초')} />
          )}
        </>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: '상태',
      render: (status: PaymentStatus) => (
        <TypeTag<PaymentStatus>
          value={status}
          label={paymentStatus[status]}
          switchCase={{
            values: [['ready'], ['approve'], ['refund']],
            types: ['ongoing', 'success', 'fail'],
          }}
        />
      ),
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: '타입',
      render: (type: PaymentType) => <Tag>{paymentType[type]}</Tag>,
    },
    { key: 'price', dataIndex: 'price', title: '금액', render: (price) => krw(price) },
    // { key: 'memo', dataIndex: 'memo', title: '메모', render: (memo) => <Typography.Text>{memo}</Typography.Text> },
    {
      key: 'confirmFlag',
      dataIndex: 'confirmFlag',
      title: '매출확정',
      align: 'center',
      render: (_, { confirmFlag }) =>
        confirmFlag ? <TypeTag type="success" label="확정" /> : <TypeTag type="fail" label="미확정" />,
    },
    {
      title: (
        <Tooltip
          title={
            <div>
              앱 결제 - 이용자가 가젯 앱을 통해 결제한 내역 입니다.
              <br />
              직접입력 - 관리자가 직접 가젯에 입력한 결제내역입니다.
            </div>
          }
        >
          입력방식
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      align: 'center',
      render: (_, { status, manualFlag, confirmFlag }) =>
        manualFlag ? <TypeTag type="default" label="직접입력" /> : <TypeTag type="success" label="앱 결제" />,
    },
    {
      key: 'paymentId',
      dataIndex: 'paymentId',
      title: (
        <Tooltip title="직접입력한 결제내역만 수정, 제거할 수 있습니다." placement="rightTop">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      align: 'center',
      width: '80px',
      render: (paymentId, { confirmFlag, status, manualFlag }) => (
        <Dropdown
          overlay={
            manualFlag ? (
              <Menu>
                <Menu.Item key="update" onClick={() => onVisibleFormModal({ paymentId })}>
                  수정
                </Menu.Item>
                <Menu.Item key="delete" onClick={() => onVisibleDeleteModal({ paymentId })}>
                  제거
                </Menu.Item>
              </Menu>
            ) : (
              <Menu>
                <Menu.Item
                  key="confirm"
                  onClick={() => onVisibleConfirmModal({ paymentId })}
                  disabled={status !== 'approve' || confirmFlag}
                >
                  매출확정
                </Menu.Item>
                <Menu.Item
                  key="refund"
                  onClick={() => onVisibleRefundModal({ paymentId })}
                  disabled={status !== 'approve' || confirmFlag}
                >
                  환불
                </Menu.Item>
              </Menu>
            )
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  const payments = useMemo(() => bill?.payments || [], [bill])

  return (
    <ModalAlert title="결제내역" visible={visible} onCancel={onClose} width="1000px">
      <Loading loading={loading}>
        <>
          <Table
            title={() => (
              <div style={styles.header}>
                <Button size="small" type="primary" onClick={() => onVisibleFormModal({ paymentId: undefined })}>
                  결제내역 추가
                </Button>
              </div>
            )}
            rowKey={({ paymentId }) => `${paymentId}`}
            dataSource={payments || []}
            columns={columns}
            pagination={false}
            size="small"
          />
          <PaymentFormModal
            hqId={hqId}
            branchId={branchId}
            billId={billId}
            paymentId={formModal.paymentId}
            visible={formModal.visible}
            onClose={onCloseFormModal}
            onDone={_onDone}
          />

          <PaymentConfirmModal
            visible={confirmModal.visible}
            hqId={hqId}
            branchId={branchId}
            paymentId={confirmModal.paymentId}
            onClose={onCloseConfirmModal}
            onDone={_onDone}
          />
          <PaymentRefundModal
            visible={refundModal.visible}
            hqId={hqId}
            branchId={branchId}
            paymentId={refundModal.paymentId}
            onClose={onCloseRefundModal}
            onDone={_onDone}
          />
          <PaymentDeleteModal
            visible={deleteModal.visible}
            hqId={hqId}
            branchId={branchId}
            paymentId={deleteModal.paymentId}
            onClose={onCloseDeleteModal}
            onDone={_onDone}
          />
        </>
      </Loading>
    </ModalAlert>
  )
}

const styles: Styles = { header: { display: 'flex', justifyContent: 'flex-end' } }
