import { Table, TableColumnType } from 'antd'
import dayjs from 'dayjs'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { ContractExtendStatus, ContractStatus } from 'gadjet-v2-types/dist/type'
import { contractExtendStatus, contractStatus } from 'gadjet-v2-types/dist/type/label'
import { useEffect, useState } from 'react'

import TenantAPI from '@apis/branch/tenant'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'
import TableColumnItem from '@components/molecules/TableColumn/Item'
import TypeTag from '@components/molecules/TypeTag'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  tenantId: number
  onDone?: () => void
  onClose: () => void
}

export default function TenantContractModal({
  hqId,
  branchId,
  tenantId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [contracts, setContracts] = useState<Contracts[]>([])

  const columns: TableColumnType<Contracts>[] = [
    {
      key: 'startDate',
      dataIndex: 'startDate',
      title: '기간',
      render: (_, { startDate, suspendDate }) => (
        <>
          <TableColumnItem label="시작일" value={dayjs(startDate).format('YYYY년 MM월 DD일')} />
          <TableColumnItem label="종료일" value={dayjs(suspendDate).format('YYYY년 MM월 DD일')} />
        </>
      ),
    },
    {
      key: 'director',
      dataIndex: 'director',
      title: '대표',
      render: (_, { director }) => (
        <>
          <TableColumnItem label="이름" value={director.name} />
          <TableColumnItem label="이메일" value={director.email} />
          <TableColumnItem label="연락처" value={director.contact} />
        </>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: '상태',
      render: (_, { status }) => (
        <TypeTag<ContractStatus>
          label={contractStatus[status]}
          value={status}
          switchCase={{
            values: [['before-started'], ['started'], ['extended'], ['expired', 'suspended']],
            types: ['default', 'success', 'ongoing', 'fail'],
          }}
        />
      ),
    },
    {
      key: 'extendStatus',
      dataIndex: 'extendStatus',
      title: '연장상태',
      render: (_, { extendStatus }) => (
        <TypeTag<ContractExtendStatus>
          label={contractExtendStatus[extendStatus]}
          value={extendStatus}
          switchCase={{
            values: [['none'], ['asked'], ['approval'], ['refusal']],
            types: ['default', 'ongoing', 'success', 'fail'],
          }}
        />
      ),
    },
  ]

  const getTenantContracts = async () => {
    setLoading(true)
    const { data } = await TenantAPI.getTenantContracts(
      { hqId, branchId, tenantId },
      { query: '', current: 1, pageSize: 100 }
    )
    setContracts(data.contracts)
    setLoading(false)
  }

  useEffect(() => {
    if (visible) {
      setContracts([])
      getTenantContracts()
    }
  }, [visible])

  return (
    <ModalAlert title="입주사 계약" visible={visible} onCancel={onClose} width="800px">
      <Loading loading={loading}>
        <Table dataSource={contracts} columns={columns} pagination={false} size="small" />
      </Loading>
    </ModalAlert>
  )
}
