import { Styles } from '@types'
import { Table, TableColumnType, Tag } from 'antd'
import dayjs from 'dayjs'
import { Contracts, Spaces } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'

type Props = { space: Spaces }

export default function SpaceItem({ space }: Props): JSX.Element {
  const { name, contractSpaces = [] } = space

  const contracts = contractSpaces?.map(({ contract }) => contract).filter<Contracts>((c): c is Contracts => !!c) || []

  const status = useMemo(() => {
    if (contracts.length === 0) return <Tag color="error">공실</Tag>
    const ongoing = contracts.some((contract) =>
      dayjs().isBetween(contract?.startDate || '', contract?.suspendDate || '', 'date')
    )
    if (ongoing) return <Tag color="success">계약중</Tag>
    const dayBefore = dayjs(contracts[0]?.startDate).diff(dayjs(), 'day')
    return <Tag color="blue">{dayBefore}일 전</Tag>
  }, [space])

  const columns: TableColumnType<Contracts>[] = [
    { key: 'tenant', dataIndex: 'tenant', title: '입주사', render: (_, { tenant }) => tenant.name },
    { key: 'startDate', dataIndex: 'startDate', title: '시작' },
    { key: 'suspendDate', dataIndex: 'suspendDate', title: '종료' },
  ]

  return (
    <Table<Contracts>
      style={styles.table}
      title={() => (
        <div style={styles.title}>
          <div style={styles.name}>{name}</div>
          {status}
        </div>
      )}
      columns={columns}
      dataSource={contracts}
      size="small"
      pagination={{ pageSize: 5, showSizeChanger: false }}
      locale={{ emptyText: '계약이 없습니다.' }}
      rowKey={({ contractId }) => `${contractId}`}
    />
  )
}

const styles: Styles = {
  table: {},
  title: { display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' },
  name: { fontSize: '16px', fontWeight: 'bold' },
}
