import dayjs from 'dayjs'
import { Contracts } from 'gadjet-v2-types/dist/model'

const contract = (_contract?: Partial<Contracts>): Contracts => ({
  contractId: 0,
  branchId: 0,
  tenantId: null,
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  suspendDate: dayjs().format('YYYY-MM-DD'),
  address: '',
  population: 0,
  paymentDate: 20,
  billingType: 'monthly',
  billingSplitType: 'A',
  paymentDueDate: 7,
  lateFeeType: 'none',
  lateFee: 0,
  lateFeeDeferDate: 7,
  creditAmount: 0,
  publicMemo: '',
  privateMemo: '',
  status: 'before-started',
  signatureStatus: 'none',
  signatureImageId: null,
  documentFileId: null,
  extendStatus: 'none',
  extendCheckDate: dayjs().format('YYYY-MM-DD'),
  extendContractId: null,
  extendSinceDate: '',
  lang: 'ko',
  receiver: {
    bill: [],
    invoice: [],
  },
  tenant: {
    name: '',
    contact: '',
    email: '',
    businessNumber: '',
    corporationNumber: '',
    foreignerNumber: '',
    gicsCode: null,
    type: 'sole-proprietor',
    address: '',
    businessClass: '',
    businessType: '',
    identityNumber: '0000',
  },
  director: {
    name: '',
    contact: '',
    email: '',
    residentNumber: '',
    corporationNumber: '',
  },
  contractor: {
    name: '',
    contact: '',
    email: '',
    relationshipWithDirector: '',
  },

  contractDeposit: {
    contractDepositId: 0,
    contractId: 0,
    branchId: 0,
    account: '',
    bankCode: '',
    bankName: '',
    holder: '',
    price: 0,
    status: 'unpaid',
  },
  contractSpaces: [],
  contractAdditions: [],
  signatureImage: null,
  documentFile: null,
  ..._contract,
})

export default contract
