import { COLORS } from '@const'
import { Styles } from '@types'
import { Col, Form, Input, Modal, Row, Select, Switch, Image, Button } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { Images, Rentals } from 'gadjet-v2-types/dist/model'
import { creditType } from 'gadjet-v2-types/dist/type/label'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'

import RentalAPI from '@apis/branch/rental'

import { RootState } from '@reducers/index'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'
import UploadButton from '@components/molecules/UploadButton'

type Props = {
  hqId: number
  branchId: number
  rentalId?: number

  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function RentalFormModal({ hqId, branchId, rentalId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<Rentals>()
  const option = useSelector((state: RootState) => state.option)
  const [loading, setLoading] = useState(false)
  const [imageUri, setImageUri] = useState('')

  const initialValues = useMemo(
    (): Partial<Rentals> => ({
      name: '',
      description: '',
      price: 5000,
      availableCreditType: ['buy', 'contract', 'mileage'],
      startTime: '08:00',
      endTime: '17:30',
    }),
    []
  )

  const onUploadDone = ({ imageId, uri }: Images) => {
    form.setFieldsValue({ imageId })
    setImageUri(uri)
  }

  const onOk = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)

      if (rentalId) await RentalAPI.updateRental({ hqId, branchId, rentalId }, { rental: values })
      else await RentalAPI.addRental({ hqId, branchId }, { rental: values })

      setLoading(false)
      onClose()
      onDone()
    } catch (err) {
      setLoading(false)
    }
  }

  const onChangeAllTime = () => form.setFieldsValue({ startTime: '00:00', endTime: '23:30' })

  const getRental = async () => {
    if (!rentalId) return
    setLoading(true)
    const { data } = await RentalAPI.getRentalDetail({ hqId, branchId, rentalId })

    setImageUri(data.image?.uri || '')

    form.setFieldsValue(data)
    setLoading(false)
  }

  const reset = () => {
    form.resetFields()
    setImageUri('')
  }

  useEffect(() => {
    if (visible) {
      reset()
      if (rentalId) getRental()
    }
  }, [visible, rentalId])

  return (
    <Modal title="대관/대여" visible={visible} onOk={onOk} onCancel={onClose} okText="저장">
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <HiddenItems names={['imageId', 'startTime', 'endTime']} />

          <Form.Item label="사진">
            <Image src={imageUri} style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
            <UploadButton.Image
              file={null}
              accept="image/*"
              label=""
              category="rental"
              onUploadDone={onUploadDone}
              hideDownload
            />
          </Form.Item>
          <Form.Item label="이름" name="name" required rules={[formRule.required]}>
            <Input />
          </Form.Item>
          <Form.Item label="설명" name="description">
            <Input.TextArea style={styles.textArea} />
          </Form.Item>
          <Form.Item label="비용" name="price" required rules={[formRule.required, formRule.number]}>
            <Input addonAfter="크레딧/30분" />
          </Form.Item>
          <Form.Item label="사용 가능 크레딧" name="availableCreditType">
            <Select mode="multiple">
              {option.creditTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {creditType[type]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={20}>
            <Col>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldsValue }) => {
                  const start = getFieldValue('startTime')
                  const end = getFieldValue('endTime')

                  const realEndTime = dayjs(end, 'HH:mm').add(30, 'minute').format('HH:mm')
                  const extraLabel = `${start}~${realEndTime === '00:00' ? '24:00' : realEndTime}`

                  const isAllTime = start === '00:00' && end === '23:30'

                  return (
                    <Form.Item
                      label="예약가능시간"
                      required
                      extra={<div style={styles.timeExtra}>실제이용시간: {extraLabel}</div>}
                    >
                      <LocalDatePicker.RangePicker
                        format="HH:mm"
                        picker="time"
                        minuteStep={30}
                        allowClear={false}
                        inputReadOnly
                        value={[dayjs(start, 'HH:mm'), dayjs(end, 'HH:mm')]}
                        onChange={(_, [_start, _end]) => setFieldsValue({ startTime: _start, endTime: _end })}
                      />
                      <Button
                        style={{ marginLeft: -1 }}
                        onClick={onChangeAllTime}
                        type={isAllTime ? 'primary' : 'default'}
                      >
                        24시간
                      </Button>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="주말예약" name="weekendFlag" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  textArea: { height: '100px' },
  timeExtra: { color: COLORS.primary, opacity: 0.6, fontSize: 12 },
}
