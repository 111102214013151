import { InfoCircleOutlined, CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons'
import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { Modal, notification, Alert, Button, Divider, Space } from 'antd'
import { Spaces } from 'gadjet-v2-types/dist/model'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { urlToFileDownload } from '@utils/download'
import xlsxToJson from '@utils/xlsxToJson'

import SpaceAPI from '@apis/branch/space'

import { RootState } from '@reducers/index'

import Loading from '@components/molecules/Loading'

import GuideModal from './GuideModal'
import columns from './columns'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function SpaceUploadModal({ hqId, branchId, visible, onClose, onDone }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [guide, onVisibleGuide, onCloseGuide] = useModal({ visible: false })
  const spaceTypes = useSelector((state: RootState) => state.branch.spaceTypes || [])
  const inputFile = useRef<HTMLInputElement>(null)
  const [errorMsgs, setErrorMsgs] = useState<string[]>([])
  const [spaces, setSpaces] = useState<Partial<Spaces>[]>([])

  const a = useMemo(() => columns(spaceTypes), [spaceTypes])

  const onClickDownloadTemplate = () => {
    urlToFileDownload('GADJET_공간_템플릿.xlsx', '/download/template/space.xlsx')
  }

  const onUploadTemplate = () => inputFile.current?.click()
  const onChangeFile: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files = [] } = e.target
    if (!files || files.length === 0) return
    setErrorMsgs([])
    setSpaces([])
    const file = files[0]

    const json = await xlsxToJson(file)

    const errors: { title: string; index: number }[] = []
    const rows = json.map((data, index) => {
      const space: Record<string, unknown> = {}
      Object.entries<string>(data).forEach(([dataTitle, dataValue]) => {
        const column = a.find((c) => c.title.trim() === dataTitle.trim())
        if (!column) return
        const { title, options, key, required } = column

        // required 검증
        if (required && !String(dataValue)) {
          errors.push({ title, index })
          return
        }

        // options 검증
        if (options) {
          const option = options.find((o) => o.label === dataValue)
          if (!option) {
            errors.push({ title, index })
            return
          }
          space[key] = option.value
          return
        }

        space[key] = String(dataValue).trim()
      })
      return space
    })

    if (errors.length === 0) setSpaces(rows)
    else setErrorMsgs(errors.map(({ title, index }) => `${index + 1}줄의 ${title}을 확인하세요.`))

    e.target.value = ''
  }

  const onOk = async () => {
    try {
      setLoading(true)
      await SpaceAPI.uploadSpaces({ hqId, branchId }, { spaces })
      notification.success({ message: '업로드 완료' })
      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const reset = () => {
    setErrorMsgs([])
    setSpaces([])
  }

  useEffect(() => {
    if (!visible) return
    reset()
  }, [visible])

  return (
    <Modal title="업로드" okText="업로드" onOk={onOk} confirmLoading={loading} visible={visible} onCancel={onClose}>
      <Loading loading={loading}>
        <>
          <Alert type="warning" showIcon message="공간 업로드 전 공간유형 등록이 필요합니다." />

          <Space style={styles.templateButtonContainer}>
            <Button size="small" type="primary" icon={<InfoCircleOutlined />} onClick={() => onVisibleGuide()}>
              템플릿 가이드 보기
            </Button>
            <Button size="small" icon={<CloudDownloadOutlined />} onClick={() => onClickDownloadTemplate()}>
              템플릿 다운로드
            </Button>
          </Space>

          <ol>
            <li>공간유형 등록</li>
            <li>템플릿 다운로드</li>
            <li>템플릿에 공간 데이터 입력</li>
            <li>템플릿 업로드</li>
            <li>업로드하기 버튼 클릭</li>
          </ol>

          <Button
            icon={<CloudUploadOutlined />}
            type="primary"
            style={styles.button}
            onClick={() => onUploadTemplate()}
          >
            템플릿 업로드
            <input
              ref={inputFile}
              multiple={false}
              accept=".xlsx"
              type="file"
              style={{ display: 'none' }}
              onChange={onChangeFile}
            />
          </Button>

          <Divider />
          {errorMsgs.length > 0 && (
            <Alert
              type="error"
              showIcon
              message={errorMsgs.map((msg) => (
                <div key={msg}>{msg}</div>
              ))}
            />
          )}
          {errorMsgs.length === 0 && spaces.length > 0 && (
            <Alert type="success" showIcon message={`${spaces.length}개의 공간을 업로드 할 수 있습니다.`} />
          )}

          <GuideModal visible={guide.visible} onClose={onCloseGuide} />
        </>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  templateButtonContainer: { margin: '20px 0' },
  button: { margin: '10px 0' },
}
