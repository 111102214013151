import { Row, Col, Form, Input, Button, notification, Descriptions } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { BranchCards, Branches } from 'gadjet-v2-types/dist/model'
import { useState } from 'react'

import formRule from '@utils/formRule'

import HqBranchCardAPI from '@apis/hq/branch-card'

type Props = {
  form: FormInstance<Branches>
  hqId: number
}

export default function BranchCardTab({ form, hqId }: Props): JSX.Element {
  const [addedCard, setAddedCard] = useState<BranchCards>()

  const onClickAddCard = async () => {
    const values = form.getFieldValue(['billingCard'])
    const name = form.getFieldValue(['name'])
    const contact = form.getFieldValue(['contact'])
    const email = form.getFieldValue(['email'])

    const { data } = await HqBranchCardAPI.addBranchCard({ hqId }, { card: values, name, contact, email })

    if (data) {
      notification.success({ message: '결제수단이 추가되었습니다.' })
      form.setFieldsValue({ cards: data })
      setAddedCard(data)
    } else {
      notification.error({ message: '카드추가 실패' })
    }
  }

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item
            label="카드번호(숫자만)"
            name={['billingCard', 'number']}
            rules={[formRule.required]}
            initialValue=""
          >
            <Input maxLength={16} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="비밀번호 앞 2자리"
            name={['billingCard', 'password']}
            initialValue=""
            rules={[formRule.required]}
          >
            <Input type="password" maxLength={2} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="유효기간 YEAR"
            name={['billingCard', 'expYear']}
            initialValue=""
            rules={[formRule.required]}
          >
            <Input maxLength={2} placeholder="YY" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="MONTH" name={['billingCard', 'expMonth']} initialValue="" rules={[formRule.required]}>
            <Input maxLength={2} placeholder="MM" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="생년월일 또는 사업자번호"
            name={['billingCard', 'identityNumber']}
            extra="YYMMDD 또는 사업자번호 10자리"
            initialValue=""
            rules={[formRule.required]}
          >
            <Input maxLength={10} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" onClick={onClickAddCard}>
          카드추가
        </Button>
      </Form.Item>
      {addedCard && (
        <Descriptions bordered>
          <Descriptions.Item label="카드">{addedCard?.cardName}</Descriptions.Item>
          <Descriptions.Item label="번호">{addedCard?.cardNumber}</Descriptions.Item>
          <Descriptions.Item label="등록일">{dayjs(addedCard?.registDate).format('YYYY-MM-DD')}</Descriptions.Item>
        </Descriptions>
      )}
    </>
  )
}
