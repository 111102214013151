import dayjs from 'dayjs'
import { all, call, put, takeLatest } from 'typed-redux-saga'

import SpaceTypeAPI from '@apis/branch/spaceType'
import HqDashboardAPI from '@apis/hq/dashboard'

import {
  GET_HQ_DASHBOARD_END_CONTRACTS,
  GET_HQ_DASHBOARD_RECENT_CONTRACTS,
  SEARCH_HQ_DASHBOARD_CONTRACTS,
  GET_HQ_DASHBOARD_SPACE_TYPES,
  GET_HQ_DASHBOARD_START_CONTRACTS,
  GET_HQ_DASHBOARD_UNPAID_BILLS,
  HqDashboardAction,
  setHqDashboard,
} from '@reducers/hq/dashboard.reducer'

function* getHqRecentContractsSaga(action: HqDashboardAction): Generator {
  if (action.type !== GET_HQ_DASHBOARD_RECENT_CONTRACTS) return
  const { hqId, cb } = action.payload
  const { data } = yield* call(HqDashboardAPI.getRecentContracts, { hqId }, { pageSize: 1 })
  yield* put(setHqDashboard({ recentContracts: data }))

  if (cb) cb()
}

function* searchHqDashboardContractsSaga(action: HqDashboardAction): Generator {
  if (action.type !== SEARCH_HQ_DASHBOARD_CONTRACTS) return
  const { hqId, query, cb } = action.payload
  const { data } = yield* call(HqDashboardAPI.searchContracts, { hqId }, { current: 1, pageSize: 20, query })
  yield* put(setHqDashboard({ searchContracts: data.contracts }))

  if (cb) cb()
}

function* getHqStartContractsSaga(action: HqDashboardAction): Generator {
  if (action.type !== GET_HQ_DASHBOARD_START_CONTRACTS) return
  const { hqId, cb } = action.payload
  const { data } = yield* call(HqDashboardAPI.getStartContracts, { hqId }, { date: dayjs().format('YYYY-MM-DD') })
  yield* put(setHqDashboard({ startContracts: data }))

  if (cb) cb()
}

function* getHqEndContractsSaga(action: HqDashboardAction): Generator {
  if (action.type !== GET_HQ_DASHBOARD_END_CONTRACTS) return
  const { hqId, cb } = action.payload
  const { data } = yield* call(HqDashboardAPI.getEndContracts, { hqId }, { date: dayjs().format('YYYY-MM-DD') })
  yield* put(setHqDashboard({ endContracts: data }))

  if (cb) cb()
}

function* getHqUnpaidBillsSaga(action: HqDashboardAction): Generator {
  if (action.type !== GET_HQ_DASHBOARD_UNPAID_BILLS) return
  const { hqId, cb } = action.payload
  const { data } = yield* call(HqDashboardAPI.getUnpaidBills, { hqId }, { date: dayjs().format('YYYY-MM-DD') })
  yield* put(setHqDashboard({ unpaidBills: data }))

  if (cb) cb()
}

function* getHqSpaceTypesSaga(action: HqDashboardAction): Generator {
  if (action.type !== GET_HQ_DASHBOARD_SPACE_TYPES) return
  const { hqId, branchId, cb } = action.payload
  const { data } = yield* call(
    SpaceTypeAPI.getSpaceTypeSpaceContracts,
    { hqId, branchId },
    { date: dayjs().format('YYYY-MM-DD') }
  )
  yield* put(setHqDashboard({ spaceTypes: data }))

  if (cb) cb()
}

export default function* hqDashboardSaga(): Generator {
  yield* all([
    takeLatest(GET_HQ_DASHBOARD_RECENT_CONTRACTS, getHqRecentContractsSaga),
    takeLatest(SEARCH_HQ_DASHBOARD_CONTRACTS, searchHqDashboardContractsSaga),
    takeLatest(GET_HQ_DASHBOARD_START_CONTRACTS, getHqStartContractsSaga),
    takeLatest(GET_HQ_DASHBOARD_END_CONTRACTS, getHqEndContractsSaga),
    takeLatest(GET_HQ_DASHBOARD_UNPAID_BILLS, getHqUnpaidBillsSaga),
    takeLatest(GET_HQ_DASHBOARD_SPACE_TYPES, getHqSpaceTypesSaga),
  ])
}
