import { COLORS } from '@const'
import { Styles } from '@types'
import { useLocation } from 'react-router-dom'

type Props = {
  children: JSX.Element
}

export default function AuthTemplate({ children }: Props): JSX.Element {
  const HIDDEN = ['/landing', '/about']
  const location = useLocation()

  const isHidden = HIDDEN.includes(location.pathname)

  return (
    <>
      {!isHidden && (
        <div style={styles.container}>
          <div style={styles.logoWrapper}>
            <img style={styles.logo} src="/images/logo/logo_w.png" alt="logo" />
          </div>
          <div style={styles.childrenWrapper}>{children}</div>
        </div>
      )}
    </>
  )
}

const styles: Styles = {
  container: { height: '100vh', width: '100vw', display: 'flex' },
  logoWrapper: {
    width: '40vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.primary,
  },
  logo: { width: '50%' },
  childrenWrapper: {
    width: '60vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
