import { GoogleCircleFilled } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Divider, Form, Input, InputRef, notification, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { setToken } from '@utils/axios'
import { googleLogin, GoogleUserProfile } from '@utils/firebase'
import formRule from '@utils/formRule'

import AuthAPI from '@apis/auth'

import AuthFormTemplate from '@templates/AuthFormTemplate'

export default function LoginPage(): JSX.Element {
  const [form] = useForm<{ email: string; password: string }>()
  const [loading, setLoading] = useState(false)
  const password = useRef<InputRef>(null)
  const { push } = useHistory()

  const onLogin = async () => {
    const values = await form.validateFields()

    const { data } = await AuthAPI.login({}, { manager: values })
    const { token, manager } = data
    setLoading(false)

    if (!manager) {
      notification.error({ message: '이메일, 비밀번호를 확인하세요.' })
      return
    }
    onSuccessLogin(token)
  }

  const onLoginWithGoogle = async () => {
    const result = await googleLogin()
    setLoading(true)
    const profile = result.additionalUserInfo?.profile as GoogleUserProfile

    const { data } = await AuthAPI.socialLogin({}, { manager: { socialId: profile.id } })
    const { token, manager } = data

    if (!manager) {
      push('/join', profile)
      return
    }

    onSuccessLogin(token)
    setLoading(false)
  }

  const onSuccessLogin = (token: string) => {
    setToken(token)
    window.location.href = '/'
  }

  const onJoin = () => push('/join')
  const forgetPassword = () => push('/forget-password')

  return (
    <AuthFormTemplate
      title="로그인"
      submit={{ label: '로그인', onClick: onLogin }}
      footer={
        <>
          <Space
            style={{ justifyContent: 'center', display: 'flex' }}
            align="center"
            split={<Divider type="vertical" />}
          >
            <Button type="text" size="small" onClick={() => push('/')}>
              홈
            </Button>
            <Button type="text" size="small" block onClick={onJoin}>
              회원가입
            </Button>
            <Button type="text" size="small" block onClick={forgetPassword}>
              비밀번호를 잊으셨나요?
            </Button>
          </Space>
          <Divider />
          <Button onClick={onLoginWithGoogle} block size="large">
            <div style={styles.socialLoginButton}>
              <GoogleCircleFilled style={styles.google} />
              <span>Google 로그인</span>
            </div>
          </Button>
        </>
      }
      loading={loading}
    >
      <Form form={form} layout="vertical" requiredMark={false}>
        <Form.Item name="email" label="이메일" rules={[formRule.required, formRule.email]}>
          <Input size="large" autoFocus onPressEnter={() => password.current?.focus()} />
        </Form.Item>
        <Form.Item name="password" label="비밀번호" rules={[formRule.required, formRule.password]}>
          <Input size="large" ref={password} type="password" onPressEnter={() => onLogin()} />
        </Form.Item>
      </Form>
    </AuthFormTemplate>
  )
}

const styles: Styles = {
  socialLoginButton: { display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' },
  google: { color: '#DB4437', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px' },
}
