import { Route, Switch, useRouteMatch } from 'react-router-dom'

import BranchProductPage from '@pages/Authorized/Branch/ProductPage'
import BranchProductSalePage from '@pages/Authorized/Branch/ProductSalePage'

export default function BranchProductRouter(): JSX.Element {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/list`}>
        <BranchProductPage />
      </Route>
      <Route path={`${url}/sale`}>
        <BranchProductSalePage />
      </Route>
    </Switch>
  )
}
