import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Table, TableColumnType, TablePaginationConfig, Tag, TagProps } from 'antd'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { Bills } from 'gadjet-v2-types/dist/model'
import { ReceiptStatus, Pagination } from 'gadjet-v2-types/dist/type'
import { receiptStatus, billType } from 'gadjet-v2-types/dist/type/label'
import { useSelector } from 'react-redux'

import { onChangeTable } from '@utils/antd-table'
import { krw } from '@utils/format'

import { RootState } from '@reducers/index'

import { BillActionType } from '@pages/Authorized/Branch/BillPage'

import ContentWrapper from '@components/atoms/ContentWrapper'
import TableColumnItem from '@components/molecules/TableColumn/Item'
import TypeTag from '@components/molecules/TypeTag'

type BillColumn = Omit<TableColumnType<Bills>, 'key'> & { key: keyof Bills }

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  bills: Bills[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BillActionType, billId: number) => void
}
// npm uninstall gadjet-v2-types
// npm i git+https://github.com/g-labs-dev/gadjet-v2-types.git
export default function BranchBillListTable({
  loading,
  total,
  pagination,
  bills,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const branch = useSelector((state: RootState) => state.branch)

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Bills> | SorterResult<Bills>[]
  ) => {
    const { current, pageSize, sort } = onChangeTable(pagination, filters, sorter)
    onChangePagination({ current, pageSize, sort })
  }

  const columns: BillColumn[] = [
    {
      key: 'billId',
      dataIndex: 'billId',
      title: '입주사',
      render: (_, { contract, tenant }) => {
        if (contract) return contract?.tenant?.name
        if (tenant) return tenant.name
        return '-'
      },
    },
    { key: 'type', dataIndex: 'type', title: '타입', render: (_, { type }) => billType[type] },
    {
      key: 'startDate',
      dataIndex: 'startDate',
      title: '제공기간',
      render: (startDate, { endDate }) => {
        return (
          <>
            <TableColumnItem label="시작일" value={startDate} />
            {endDate && <TableColumnItem label="종료일" value={endDate} />}
          </>
        )
      },
    },
    {
      key: 'paymentStartDate',
      dataIndex: 'paymentStartDate',
      title: '결제기간',
      render: (paymentStartDate, { paymentEndDate }) => {
        return (
          <>
            <TableColumnItem label="시작일" value={paymentStartDate} />
            <TableColumnItem label="종료일" value={paymentEndDate} />
          </>
        )
      },
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: '청구',
      render: (price, { billId }) => <Button onClick={() => onAction('price', billId)}>{krw(price)}</Button>,
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: '결제',
      sorter: true,
      render: (_, { price, unpaidPrice, billId }) => {
        return <Button onClick={() => onAction('payment', billId)}>{krw(price - unpaidPrice)}</Button>
      },
    },
    {
      key: 'unpaidPrice',
      dataIndex: 'unpaidPrice',
      title: '미납',
      sorter: true,
      render: (unpaidPrice) => {
        let color: TagProps['color'] = 'success'
        if (unpaidPrice > 0) color = 'error'
        if (unpaidPrice < 0) color = 'warning'
        return <Tag color={color}>{krw(unpaidPrice)}</Tag>
      },
    },
    {
      key: 'receiptStatus',
      dataIndex: 'receiptStatus',
      title: '증빙',
      render: (status: ReceiptStatus) => (
        <TypeTag<ReceiptStatus>
          label={receiptStatus[status]}
          value={status}
          switchCase={{
            types: ['default', 'ongoing', 'success', 'fail'],
            values: [
              ['none'],
              ['cash-receipt-request', 'tax-invoice-request'],
              ['tax-invoice-done', 'cash-receipt-done'],
              ['cash-receipt-cancel', 'tax-invoice-updated'],
            ],
          }}
        />
      ),
    },
    { key: 'memo', dataIndex: 'memo', title: '메모' },
    {
      key: 'billId',
      dataIndex: 'billId',
      title: '',
      align: 'center',
      render: (billId) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="tax-invoice" onClick={() => onAction('tax-invoice', billId)}>
                세금계산서 발행
              </Menu.Item>
              <Menu.Item
                key="cash-receipt"
                onClick={() => onAction('cash-receipt', billId)}
                disabled={!branch?.popbillId}
              >
                현금영수증 발행
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="send" onClick={() => onAction('send', billId)}>
                문자/이메일 발송
              </Menu.Item>
              <Menu.Item key="form" onClick={() => onAction('form', billId)}>
                수정
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => onAction('delete', billId)}>
                제거
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <ContentWrapper>
      <Table<Bills>
        scroll={{ x: true }}
        columns={columns}
        rowKey={({ billId }) => `${billId}`}
        dataSource={bills}
        loading={loading}
        pagination={{ total, ...pagination }}
        onChange={onChange}
      />
    </ContentWrapper>
  )
}
