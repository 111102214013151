import { COLORS } from '@const'
import { Styles } from '@types'
import { Hqs } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  hq: Hqs | null
  isActive?: boolean
}

export default function HqLinkButton({ hq, isActive }: Props): JSX.Element {
  const imageUri = hq?.image?.uri

  const wrapperStyle = useMemo(() => {
    if (isActive) return { ...styles.wrapper, ...styles.wrapperActive }
    return styles.wrapper
  }, [isActive])

  if (!hq) return <></>
  return (
    <Link to={`/hq/${hq.hqId}`}>
      <div style={wrapperStyle}>
        {imageUri ? <img src={imageUri} style={styles.logo} alt="logo" /> : <div style={styles.name}>{hq.name}</div>}
      </div>
    </Link>
  )
}

const styles: Styles = {
  wrapper: {
    width: '100%',
    height: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperActive: { backgroundColor: COLORS.active },
  logo: { maxWidth: '70%', maxHeight: '34px' },
  name: { fontSize: '24px', color: 'black' },
}
