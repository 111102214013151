import useMenu from '@hooks/useMenu'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { AccessLogSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setAccessLogPagination,
  setAccessLogSearch,
  searchAccessLogs,
  setAccessLogDefault,
} from '@reducers/branch/access-log.reducer'
import { RootState } from '@reducers/index'

import BranchAccessLogListTable from '@components/organisms/Branch/AccessLog/ListTable'
import BranchAccessLogSearchBox from '@components/organisms/Branch/AccessLog/SearchBox'
import BranchAccessLogTitle from '@components/organisms/Branch/AccessLog/Title'

export type BranchAccessLogAction = 'add'

export default function AccessLogPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult } = useSelector((state: RootState) => state.accessLog)

  const onChangeSearch = (_search: Partial<AccessLogSearch>) => dispatch(setAccessLogSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setAccessLogPagination(_pagination))
    dispatch(searchAccessLogs(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setAccessLogPagination({ current: 1 }))
    dispatch(searchAccessLogs(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setAccessLogDefault(hqId, branchId)), [hqId, branchId])
  // const onDone = () => onResetAndSearch()

  const onAction = (action: BranchAccessLogAction) => {}

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchAccessLogSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchAccessLogTitle onAction={onAction} />

      <BranchAccessLogListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        accessLogs={searchResult.accessLogs}
        total={searchResult.total}
      />
    </div>
  )
}
