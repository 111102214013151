import { Styles } from '@types'
import { Form, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

import bufferToFileDownload from '@utils/download'
import formRule from '@utils/formRule'

import PaymentAPI from '@apis/branch/payment'

import LocalDatePicker from '@components/atoms/LocalDatePicker'

type Props = {
  hqId: number
  branchId: number
  onClose?: () => void
  onDone?: () => void
}

export default function DepositExportModal({ hqId, branchId, onClose, onDone }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [form] = useForm<{ contractRange: [Dayjs, Dayjs] }>()

  const onOk = async () => {
    const { contractRange } = await form.validateFields()

    const from = dayjs(contractRange[0]).format('YYYY-MM-DD')
    const to = dayjs(contractRange[1]).format('YYYY-MM-DD')

    setLoading(true)

    const { data } = await PaymentAPI.exportDepositPayments({ hqId, branchId }, { contractRange: [from, to] })

    bufferToFileDownload(`보증금_${from}_${to}.xlsx`, Buffer.from(data), 'xlsx')

    setLoading(false)
    if (onDone) onDone()
  }

  const initialValues = useMemo(
    () => ({
      contractRange: [dayjs().startOf('month'), dayjs().endOf('month')],
    }),
    []
  )

  const onReset = () => {
    form.resetFields()
    setLoading(false)
  }

  useEffect(() => {
    onReset()
  }, [])

  return (
    <Modal visible onCancel={onClose} onOk={onOk} title="보증금 내보내기" okText="내보내기" confirmLoading={loading}>
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="계약기간" name="contractRange" rules={[formRule.required]}>
          <LocalDatePicker.RangePicker size="large" style={styles.datePicker} allowClear={false} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const styles: Styles = {
  datePicker: { width: '100%' },
}
