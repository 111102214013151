import '@styles/home.scss'

import GreenButton from '@components/atoms/Buttons/GreenButton'
import Companies from '@components/organisms/Landing/Intro/Companies'
import Features from '@components/organisms/Landing/Intro/Features'
import Merit from '@components/organisms/Landing/Intro/Merit'
import Service from '@components/organisms/Landing/Intro/Service'
import Start from '@components/organisms/Landing/Intro/Start'

export default function Home(): JSX.Element {
  return (
    <>
      <div
        style={{
          backgroundImage: 'url(/assets/landing/home-intro.png)',
          width: '100%',
          height: '800px',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="container">
          <div className="Intro-title">
            공유 공간을 관리하는
            <br />
            가장 쉬운 방법, <span className="colored">가젯</span>
          </div>
          <GreenButton text="도입하기" large path="" />
        </div>
      </div>

      <Service />
      <Features />
      <Merit />
      <Companies />
      <Start />
    </>
  )
}
