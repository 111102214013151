import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import dayjs, { Dayjs } from 'dayjs'
import { RentalReservations } from 'gadjet-v2-types/dist/model'
import { RentalReservationSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect, useState } from 'react'
import * as RBC from 'react-big-calendar'
import { useDispatch, useSelector } from 'react-redux'

import {
  setRentalReservationSearch,
  searchRentalReservations,
  setRentalReservationDefault,
} from '@reducers/branch/rental-reservation.reducer'
import { RootState } from '@reducers/index'

import BranchRentalReservationCalendar from '@components/organisms/Branch/RentalReservation/Calendar'
import RentalReservationFormModal from '@components/organisms/Branch/RentalReservation/FormModal'
import BranchRentalReservationSearchBox from '@components/organisms/Branch/RentalReservation/SearchBox'
import BranchRentalReservationTitle from '@components/organisms/Branch/RentalReservation/Title'

export type BranchRentalReservationAction = ''

export default function RentalReservationPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, searchResult } = useSelector((state: RootState) => state.rentalReservation)
  const [view, setView] = useState<RBC.View>('week')
  const [date, setDate] = useState(dayjs())
  const [modal, onVisible, onClose] = useModal<{
    rentalReservation: Partial<RentalReservations>
  }>({
    visible: false,
    rentalReservation: {},
  })

  const onChangeSearch = (_search: Partial<RentalReservationSearch>) => dispatch(setRentalReservationSearch(_search))
  const onSearch = () => dispatch(searchRentalReservations(hqId, branchId))
  const onResetAndSearch = useCallback(() => dispatch(setRentalReservationDefault(hqId, branchId)), [hqId, branchId])
  const onDoneRentalReservation = () => onResetAndSearch()

  const onNavigate = (_date: Dayjs) => setDate(_date)
  const onRangeChange = ({ start, end }: { start: Dayjs; end: Dayjs }) => {
    onChangeSearch({ date: [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')] })
    onSearch()
  }
  const onClickEvent = (_reservation: RentalReservations) => {
    onVisible({ rentalReservation: _reservation })
  }
  const onSelectRange = ({ start, end }: { start: Dayjs; end: Dayjs }) => {
    onVisible({
      rentalReservation: {
        date: start.format('YYYY-MM-DD'),
        startTime: start.format('HH:00'),
        endTime: end.format('HH:00'),
      },
    })
  }

  const onAction = (action: BranchRentalReservationAction) => {}

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) {
      onResetAndSearch()
    }
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchRentalReservationSearchBox
        hqId={hqId}
        branchId={branchId}
        search={search}
        onChangeSearch={onChangeSearch}
        onSearch={onSearch}
      />

      <BranchRentalReservationTitle onAction={onAction} />

      <BranchRentalReservationCalendar
        loading={loading}
        view={view}
        rentalReservations={searchResult.rentalReservations}
        onNavigate={onNavigate}
        onChangeView={setView}
        onClickEvent={onClickEvent}
        onSelectRange={onSelectRange}
        onRangeChange={onRangeChange}
        date={date}
      />

      <RentalReservationFormModal
        visible={modal.visible}
        rentalReservation={modal.rentalReservation}
        hqId={hqId}
        branchId={branchId}
        onClose={onClose}
        onDone={onDoneRentalReservation}
      />
    </div>
  )
}
