import { Styles } from '@types'
import { Modal, Form, Input, notification, Image, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Images, Services } from 'gadjet-v2-types/dist/model'
import { creditType } from 'gadjet-v2-types/dist/type/label'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'

import ServiceAPI from '@apis/branch/service'

import { RootState } from '@reducers/index'

import Loading from '@components/molecules/Loading'
import UploadButton from '@components/molecules/UploadButton'

type Props = {
  hqId: number
  branchId: number
  serviceId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ServiceFormModal({ hqId, branchId, serviceId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<Services>()
  const [loading, setLoading] = useState(false)
  const [imageUri, setImageUri] = useState('')
  const option = useSelector((state: RootState) => state.option)

  const onUploadDone = ({ imageId, uri }: Images) => {
    form.setFieldsValue({ imageId })
    setImageUri(uri)
  }

  const onOk = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      if (serviceId) await ServiceAPI.updateService({ hqId, branchId, serviceId }, { service: values })
      else await ServiceAPI.addService({ hqId, branchId }, { service: values })

      notification.success({ message: '저장되었습니다.' })

      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const getService = async () => {
    if (!serviceId) return
    setLoading(true)
    const { data } = await ServiceAPI.getServiceDetail({ hqId, branchId, serviceId })
    form.setFieldsValue(data)
    setLoading(false)
  }

  const reset = () => {
    form.resetFields()
  }
  useEffect(() => {
    if (visible) {
      reset()
      if (serviceId) getService()
    }
  }, [visible])

  const initialValues: Partial<Services> = {
    imageId: null,
    name: '',
    description: '',
    price: 0,
    guide: '',
    availableCreditType: option.creditTypes,
  }

  return (
    <Modal title="서비스" okText="저장" onOk={onOk} confirmLoading={loading} visible={visible} onCancel={onClose}>
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item label="이미지" required>
            <Image src={imageUri} style={styles.image} />
            <UploadButton.Image
              file={null}
              accept="image/*"
              label=""
              category="service"
              onUploadDone={onUploadDone}
              hideDownload
            />
          </Form.Item>
          <Form.Item name="imageId" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="이름" name="name" required rules={[formRule.required]}>
            <Input />
          </Form.Item>
          <Form.Item label="설명" name="description">
            <Input.TextArea style={styles.textArea} />
          </Form.Item>
          <Form.Item label="가격" name="price" required rules={[formRule.required]}>
            <Input type="number" addonAfter="크레딧" />
          </Form.Item>
          <Form.Item label="가이드" name="guide" extra="구매 후 안내사항입니다.">
            <Input.TextArea style={styles.textArea} />
          </Form.Item>
          <Form.Item label="사용가능 크레딧" name="availableCreditType">
            <Select mode="multiple">
              {option.creditTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {creditType[type]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  image: { width: '200px', height: '200px', marginBottom: '10px', objectFit: 'cover' },
  textArea: { height: '100px' },
}
