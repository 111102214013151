import * as CONTRACT from 'gadjet-v2-types/dist/api/branch/contract'

import axios from '@utils/axios'

const ContractAPI = {
  // 계약 목록 검색
  getContracts: async (
    { hqId, branchId }: CONTRACT.GET_LIST_PARAMS,
    query: CONTRACT.GET_LIST_QUERY
  ): Promise<{ data: CONTRACT.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/contracts`, { params: query }),

  // 계약 상세조회
  getContractDetail: async ({
    hqId,
    branchId,
    contractId,
  }: CONTRACT.GET_DETAIL_PARAMS): Promise<{ data: CONTRACT.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/contracts/${contractId}`),

  // 계약 추가
  addContract: async (
    { hqId, branchId }: CONTRACT.ADD_PARAMS,
    { contract }: CONTRACT.ADD_BODY
  ): Promise<{ data: CONTRACT.ADD_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/contracts`, { contract }),

  // 계약 수정
  updateContract: async (
    { hqId, branchId, contractId }: CONTRACT.UPDATE_PARAMS,
    { contract }: CONTRACT.UPDATE_BODY
  ): Promise<{ data: CONTRACT.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/contracts/${contractId}`, { contract }),

  // 계약 내보내기
  exportContracts: async (
    { hqId, branchId }: CONTRACT.EXPORT_PARAMS,
    query: CONTRACT.EXPORT_QUERY
  ): Promise<{ data: CONTRACT.EXPORT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/contracts/export`, { params: query }),

  // 계약 업로드
  uploadContracts: async (
    { hqId, branchId }: CONTRACT.UPLOAD_PARAMS,
    { contracts }: CONTRACT.UPLOAD_BODY
  ): Promise<{ data: CONTRACT.UPLOAD_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/contracts/upload`, { contracts }),

  // 계약 제거
  deleteContract: async ({
    hqId,
    branchId,
    contractId,
  }: CONTRACT.DELETE_PARAMS): Promise<{ data: CONTRACT.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/contracts/${contractId}`),

  // 계약 종료
  suspendContract: async (
    { hqId, branchId, contractId }: CONTRACT.SUSPEND_PARAMS,
    { suspendDate }: CONTRACT.SUSPEND_BODY
  ): Promise<{ data: CONTRACT.SUSPEND_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/contracts/${contractId}/suspend`, { suspendDate }),

  // 초대코드 발송
  sendContractInivite: async (
    { hqId, branchId, contractId }: CONTRACT.SEND_INVITE_PARAMS,
    { contacts }: CONTRACT.SEND_INVITE_BODY
  ): Promise<{ data: CONTRACT.SEND_INVITE_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/contracts/${contractId}/send-invite`, { contacts }),

  // 계약 검색
  searchContracts: async ({ hqId, branchId }: CONTRACT.SEARCH_PARAMS, { query, status }: CONTRACT.SEARCH_QUERY) =>
    axios.get<CONTRACT.SEARCH_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/contracts/search`, {
      params: { query, status },
    }),

  // 계약 서명 상태 변경
  updateContractSignatureStatus: async (
    { hqId, branchId, contractId }: CONTRACT.UPDATE_SIGNATURE_STATUS_PARAMS,
    { signatureStatus }: CONTRACT.UPDATE_SIGNATURE_STATUS_BODY
  ) =>
    axios.put<CONTRACT.UPDATE_SIGNATURE_STATUS_RESPONSE>(
      `/hqs/${hqId}/branches/${branchId}/contracts/${contractId}/signature-status`,
      { signatureStatus }
    ),

  // 계약 보증금 청구서 조회
  getContractDepositBills: async (
    { hqId, branchId, contractId }: CONTRACT.GET_CONTRACT_DEPOSIT_BILLS_PARAMS,
    {}: CONTRACT.GET_CONTRACT_DEPOSIT_BILLS_QUERY
  ) =>
    axios.get<CONTRACT.GET_CONTRACT_DEPOSIT_BILLS_RESPONSE>(
      `/hqs/${hqId}/branches/${branchId}/contracts/${contractId}/deposit-bills`
    ),
}

export default ContractAPI
