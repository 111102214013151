import { CloudDownloadOutlined, CloudUploadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { Alert, Button, Divider, Modal, notification, Space } from 'antd'
import { Additions, Contracts, Spaces } from 'gadjet-v2-types/dist/model'
import { useEffect, useRef, useState } from 'react'

import { urlToFileDownload } from '@utils/download'
import xlsxToJson from '@utils/xlsxToJson'

import AdditionAPI from '@apis/branch/addition'
import ContractAPI from '@apis/branch/contract'
import SpaceAPI from '@apis/branch/space'

import Loading from '@components/molecules/Loading'

import ContractUploadGuide from './UploadGuide'
import columns from './columns'

type Props = {
  hqId: number
  branchId: number
  onClose?: () => void
  onDone?: () => void
}

export default function ContractUploadModal({ hqId, branchId, onClose, onDone }: Props): JSX.Element {
  const [uploading, setUploading] = useState(false)
  const [errorMsgs, setErrorMsgs] = useState<string[]>([])
  const [contracts, setContracts] = useState<Partial<Contracts>[]>([])
  const [spaces, setSpaces] = useState<Spaces[]>([])
  const [additions, setAdditions] = useState<Additions[]>([])
  const [guide, onVisibleGuide, onCloseGuide] = useModal({ visible: false })
  const inputFile = useRef<HTMLInputElement>(null)

  const onClickDownloadTemplate = () => {
    urlToFileDownload('GADJET_계약_템플릿.xlsx', '/download/template/contract.xlsx')
  }

  const onUploadTemplate = () => inputFile.current?.click()

  const onChangeFile: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files = [] } = e.target
    if (!files || files.length === 0) return
    setErrorMsgs([])
    setContracts([])
    const file = files[0]

    const json = await xlsxToJson(file)

    const errors: { title: string; index: number }[] = []
    const rows = json.map((data, index) => {
      const contract: any = {}
      Object.entries<string>(data).forEach(([dataTitle, dataValue]) => {
        const column = columns.find((c) => c.title.trim() === dataTitle.trim())
        if (!column) return
        const { title, options, key, parser } = column

        const value = parser
          ? parser(dataValue, { options, branchAdditions: additions, branchSpaces: spaces })
          : dataValue

        if (value === false) {
          errors.push({ title, index })
          return
        }

        if (Array.isArray(key)) {
          const [key1, key2] = key
          if (!contract[key1]) contract[key1] = {}
          contract[key1][key2] = value
        } else {
          contract[key] = value
        }
      })
      return contract
    })

    if (errors.length === 0) setContracts(rows)
    else {
      setErrorMsgs(errors.map(({ title, index }) => `${index + 1}번째 줄의 ${title}을 확인하세요.`))
    }
    e.target.value = ''
  }

  const onOk = async () => {
    setUploading(true)
    await ContractAPI.uploadContracts({ hqId, branchId }, { contracts })
    notification.success({ message: '업로드 완료' })
    setUploading(false)
    if (onDone) onDone()
    if (onClose) onClose()
  }

  const getData = async () => {
    const { data: addtionResponse } = await AdditionAPI.getAdditions(
      { hqId, branchId },
      { current: 1, pageSize: 100, query: '' }
    )
    const { data: spaceResponse } = await SpaceAPI.getSpaces(
      { hqId, branchId },
      { current: 1, pageSize: 100, query: '', emptyFlag: false }
    )

    setAdditions(addtionResponse.additions)
    setSpaces(spaceResponse.spaces)
  }

  const reset = () => {
    setErrorMsgs([])
    setContracts([])
  }

  useEffect(() => {
    reset()
    getData()
  }, [])

  return (
    <Modal
      visible
      onCancel={onClose}
      onOk={onOk}
      title="업로드하기"
      okText="계약업로드"
      okButtonProps={{ disabled: contracts.length === 0, loading: uploading }}
    >
      <Loading loading={uploading}>
        <>
          <Alert type="warning" showIcon message="계약 업로드 전 공간 등록이 필요합니다." />

          <Space style={styles.templateButtonContainer}>
            <Button size="small" type="primary" icon={<InfoCircleOutlined />} onClick={() => onVisibleGuide()}>
              템플릿 가이드 보기
            </Button>
            <Button size="small" icon={<CloudDownloadOutlined />} onClick={() => onClickDownloadTemplate()}>
              템플릿 다운로드
            </Button>
          </Space>

          <ol>
            <li>공간등록</li>
            <li>템플릿 다운로드</li>
            <li>템플릿에 계약 데이터 입력</li>
            <li>템플릿 업로드</li>
            <li>업로드하기 버튼 클릭</li>
          </ol>

          <Button
            icon={<CloudUploadOutlined />}
            type="primary"
            style={styles.button}
            onClick={() => onUploadTemplate()}
          >
            템플릿 업로드
            <input
              ref={inputFile}
              multiple={false}
              accept=".xlsx"
              type="file"
              style={{ display: 'none' }}
              onChange={onChangeFile}
            />
          </Button>

          <Divider />
          {errorMsgs.length > 0 && (
            <Alert
              type="error"
              showIcon
              message={errorMsgs.map((msg) => (
                <div key={msg}>{msg}</div>
              ))}
            />
          )}
          {errorMsgs.length === 0 && contracts.length > 0 && (
            <Alert type="success" showIcon message={`${contracts.length}개의 계약을 업로드 할 수 있습니다.`} />
          )}

          <ContractUploadGuide visible={guide.visible} onClose={onCloseGuide} />
        </>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  templateButtonContainer: { margin: '20px 0' },
  button: { margin: '10px 0' },
}
