import { Button, Checkbox, Form, Input, InputRef, message, notification, Row } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useForm } from 'antd/lib/form/Form'
import { Managers } from 'gadjet-v2-types/dist/model'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { setToken } from '@utils/axios'
import { GoogleUserProfile } from '@utils/firebase'
import formRule from '@utils/formRule'

import AuthAPI from '@apis/auth'

import AuthFormTemplate from '@templates/AuthFormTemplate'

import HiddenItems from '@components/atoms/Form/HiddenItems'

export default function JoinPage(): JSX.Element {
  const { location } = useHistory<GoogleUserProfile | undefined>()
  const profile = useMemo(() => location.state, [location])

  const [form] = useForm<Managers>()

  const input = {
    password: useRef<InputRef>(null),
    name: useRef<InputRef>(null),
    code: useRef<InputRef>(null),
  }

  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [verification, setVerification] = useState(false)

  const [codeStatus, setCodeStatus] = useState<'none' | 'sending' | 'sent'>('none')

  const [validAge, setValidAge] = useState(false)
  const [agreePolicy, setAgreePolicy] = useState(false)

  const sendCode = async () => {
    const email = form.getFieldValue('email')

    const { data: mailVerify } = await AuthAPI.verifyEmail({}, { email })
    if (!mailVerify) {
      notification.error({ message: '이메일을 사용할 수 없습니다.' })
      return
    }
    const { data } = await AuthAPI.sendEmailCode({}, { email })
    setCode(data)
    setCodeStatus('sending')
    notification.success({ message: '코드가 발송되었습니다.' })
    input.code.current?.focus()
    setCodeStatus('sent')
  }

  const onChangeCode: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!code || code !== e.target.value) return

    setVerification(true)
    notification.success({ message: '코드인증 완료' })
    input.password.current?.focus()
  }

  const onChangeValidAge = (e: CheckboxChangeEvent) => {
    setValidAge(e.target.checked)
  }

  const onChangeAgreePolicy = (e: CheckboxChangeEvent) => {
    setAgreePolicy(e.target.checked)
  }

  const onJoin = async () => {
    const values = await form.validateFields()
    if (agreePolicy && validAge) {
      setLoading(true)
      const { data } = await AuthAPI.join({}, { manager: values })
      const { token } = data
      setToken(token)
      setLoading(false)
      window.location.reload()
    } else {
      notification.error({ message: '필수항목을 확인해주세요.' })
    }
  }

  const setSocialProfile = () => {
    const manager: Partial<Managers> = {
      email: profile?.email || '',
      joinType: profile ? 'google' : 'local',
      password: '',
      name: profile?.name || '',
      socialId: profile?.id,
    }
    form.setFieldsValue(manager)

    if (!profile) return
    setVerification(true)
    setCodeStatus('sent')
  }

  useEffect(() => {
    setSocialProfile()
  }, [profile])

  return (
    <AuthFormTemplate title="회원가입" submit={{ label: '회원가입', onClick: onJoin }} loading={loading}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <HiddenItems names={['joinType', 'socialId']} />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldError }) => (
            <Form.Item name="email" label="이메일" rules={[formRule.required, formRule.email]}>
              <Input
                size="large"
                autoFocus
                onPressEnter={() => sendCode()}
                disabled={codeStatus !== 'none'}
                suffix={
                  profile ? undefined : (
                    <Button
                      type="primary"
                      size="small"
                      onClick={sendCode}
                      disabled={verification || getFieldError(['email']).length > 0}
                    >
                      코드발송
                    </Button>
                  )
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        {!profile && (
          <Form.Item label="이메일 인증 코드" hidden={!!profile}>
            <Input size="large" ref={input.code} onChange={onChangeCode} disabled={verification} />
          </Form.Item>
        )}
        {!profile && (
          <Form.Item name="password" label="비밀번호" rules={[formRule.required, formRule.password]} hidden={!!profile}>
            <Input size="large" ref={input.password} type="password" onPressEnter={() => input.name.current?.focus()} />
          </Form.Item>
        )}
        <Form.Item name="name" label="이름">
          <Input size="large" ref={input.name} onPressEnter={onJoin} />
        </Form.Item>
        <Row>
          <Checkbox onChange={onChangeValidAge}>만 14세 이상입니다.</Checkbox>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Checkbox onChange={onChangeAgreePolicy}>
            <a href="https://gadjet.notion.site/7edb66547e6248f5b3903a83c6163643">서비스 이용약관</a>과
            <a href="https://gadjet.notion.site/_2205-b7615a3bd1a3478f83a426a0b91ab76b">{` 개인정보 처리방침`}</a>에
            동의합니다.(필수)
          </Checkbox>
        </Row>
      </Form>
    </AuthFormTemplate>
  )
}
