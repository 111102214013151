import { Route, Switch, useRouteMatch } from 'react-router-dom'

import BranchServicePage from '@pages/Authorized/Branch/ServicePage'
import BranchServiceSalePage from '@pages/Authorized/Branch/ServiceSalePage'

export default function BranchServiceRouter(): JSX.Element {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/list`}>
        <BranchServicePage />
      </Route>
      <Route path={`${url}/sale`}>
        <BranchServiceSalePage />
      </Route>
    </Switch>
  )
}
