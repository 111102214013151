import { Space, Tag } from 'antd'
import dayjs from 'dayjs'
import { ContractDeposits, Contracts } from 'gadjet-v2-types/dist/model'

import bankCodes from './bankCodes'
import * as parsers from './parsers'

type ColumnKey =
  | keyof Contracts
  | 'spaces'
  | 'additions'
  | ['tenant', keyof Contracts['tenant']]
  | ['director', keyof Contracts['director']]
  | ['contractor', keyof Contracts['contractor']]
  | ['contractDeposit', keyof ContractDeposits]

type Option = { label: string; value: string }
type ParseValue =
  | number
  | string
  | { spaceId: number; name: string; price: number }[]
  | { additionId: number; name: string; price: number }[]

const columns: {
  key: ColumnKey
  title: string
  guide: (string | JSX.Element)[]
  options?: Option[]
  parser?: (value: string, parserData: parsers.ParserData) => ParseValue | false
  required?: boolean
}[] = [
  {
    key: 'startDate',
    title: '계약 시작일',
    guide: ['YYYY-MM-DD의 형태로 입력하세요.\n(ex. 2021-01-01)'],
    required: true,
    parser: (v) => {
      if (!!v && dayjs(v).isValid()) return v
      return false
    },
  },
  {
    key: 'endDate',
    title: '계약 종료일',
    guide: ['YYYY-MM-DD의 형태로 입력하세요.\n(ex. 2021-01-01)'],
    required: true,
    parser: (v) => {
      if (!!v && dayjs(v).isValid()) return v
      return false
    },
  },
  {
    key: 'suspendDate',
    title: '계약 실제 종료일',
    guide: [
      '일반적으로 계약 종료일과 같으나, 조기퇴실 같은 경우 달라질 수 있습니다.',
      'YYYY-MM-DD의 형태로 입력하세요.\n(ex. 2021-01-01)',
    ],
    required: true,
    parser: (v) => {
      if (!!v && dayjs(v).isValid()) return v
      return false
    },
  },
  { key: 'address', title: '계약주소', guide: ['계약 주소 입니다.'] },
  {
    key: 'population',
    title: '계약인원',
    guide: ['계약 인원 입니다.', '숫자만 입력하세요.\n(ex. 4)'],
    required: true,
    parser: (v) => {
      const parsed = parsers.number(v)
      if (!parsed) return parsed
      if (!!v && !isNaN(parsed)) return parsers.number(v)
      return false
    },
  },
  {
    key: 'billingType',
    title: '납부방식',
    guide: ['납부방식 입니다.', '매 월 납입은 매 달 청구서가 생성됩니다.', '선납은 계약시 최초 1번 청구서 생성됩니다.'],
    options: [
      { label: '매 월 납입', value: 'monthly' },
      { label: '선납', value: 'prepayment' },
    ],
    required: true,
    parser: (v, options) => {
      const parsed = parsers.option(v, options)
      if (!parsed) return parsed
      if (['monthly', 'prepayment'].includes(parsed)) return parsed
      return false
    },
  },
  {
    key: 'billingSplitType',
    title: '청구방식',
    guide: [
      '납부방식이 매 월 납입 일 경우에만 선택 (납부방식이 선납일 경우 무시)',
      'A타입은 매 달 말일까지 이용료가 일할계산되어 청구되는 방식입니다.',
      'B타입은 계약 시작일로부터 30일 단위로 청구되는 방식 입니다.',
      <a href="https://blog.naver.com/thegadjet/221691098054" target="_blank" rel="noreferrer">
        자세히 보기
      </a>,
    ],
    options: [
      { label: 'A타입', value: 'A' },
      { label: 'B타입', value: 'B' },
    ],
    required: true,
    parser: (v, options) => {
      const parsed = parsers.option(v, options)
      if (!parsed) return parsed
      if (['A', 'B'].includes(parsed)) return parsed
      return false
    },
  },
  {
    key: 'paymentDate',
    title: '납부일',
    guide: [
      '납부방식이 매 월 납입 일 경우 매 월 지정한 납부일에 청구서가 발송됩니다.\n(ex. 납부일이 25 일 경우 매 월 25일에 청구서 발송)',
      '납부방식이 선납일 경우 계약 시작일 이전 납부일에 청구서가 발송됩니다.\n(ex. 계약시작일: 2021-06-01 / 납부일: 25 => 2021-05-25에 청구서 발송)',
      '숫자만 입력하세요.\n(ex. 25)',
    ],
    required: true,
    parser: (v) => {
      const parsed = parsers.number(v)
      if (!parsed) return parsed
      if (!!v && !isNaN(parsed)) return parsed
      return false
    },
  },
  {
    key: 'paymentDueDate',
    title: '납부기한',
    guide: [
      '납부일 부터 납부기한 이후 연체료가 부과될 수 있습니다.\n(ex. 납부일: 25 / 청구일: 2021-05-25 / 납부기한: 7 => 2021-06-02 까지 미납시 연체료 부과)',
      '숫자만 입력하세요.\n(ex. 7)',
    ],
    required: true,
    parser: (v) => {
      const parsed = parsers.number(v)
      if (!parsed) return parsed
      if (!!v && !isNaN(parsed)) return parsed
      return false
    },
  },
  {
    key: 'lateFeeType',
    title: '연체료 타입',
    guide: [
      '납부기한 이후 미납일 때 연체료가 부과됩니다.',
      '없음 일 경우 연체료가 부과되지 않습니다.',
      '고정금액 일 경우 연체료에 지정된 금액만큼 부과됩니다.',
      '비율 일 경우 연체료에 지정된 %만큼 부과됩니다.',
    ],
    options: [
      { label: '없음', value: 'none' },
      { label: '고정금액', value: 'fix' },
      { label: '비율', value: 'rate' },
    ],
    required: true,
    parser: (v, options) => {
      const parsed = parsers.option(v, options)
      if (!parsed) return parsed
      if (['none', 'fix', 'rate'].includes(parsed)) return parsed
      return false
    },
  },
  {
    key: 'lateFee',
    title: '연체료',
    guide: [
      '연체료 타입이 고정금액 일 경우 입력한 금액만큼 부과됩니다.',
      '연체료 타입이 비율 일 경우 입력한 %만큼 부과됩니다.',
      '숫자만 입력하세요.\n(ex. 10)',
    ],
    parser: parsers.number,
  },
  { key: 'publicMemo', title: '공개 메모', guide: ['입주사가 확인할 수 있는 메모입니다.', '계약서에 포합됩니다.'] },
  {
    key: 'privateMemo',
    title: '비공개 메모',
    guide: ['입주사가 확인할 수 없는 메모입니다.', '계약서에 포함되지 않습니다.'],
  },
  {
    key: 'creditAmount',
    title: '크레딧',
    guide: [
      '대관/대여, 상품구매, 서비스구매에 이용되는 크레딧 입니다.',
      '주 또는 월 단위로 갱신되며 이월되지 않습니다.',
      '부족한 크레딧은 입주사가 직접 구매할 수 있습니다.',
      '숫자만 입력하세요.\n(ex. 10000)',
    ],
    parser: parsers.number,
  },
  { key: ['tenant', 'name'], title: '입주사 이름', guide: ['입주사 이름'] },
  {
    key: ['tenant', 'contact'],
    title: '입주사 연락처',
    guide: ['숫자만 입력하세요.\n(ex. 01012341234)'],
    required: true,
    parser: (v) => {
      const parsed = parsers.number(v)
      return v ? parsed : false
    },
  },
  { key: ['tenant', 'email'], title: '입주사 이메일', guide: ['입주사의 이메일을 입력하세요.'] },
  {
    key: ['tenant', 'type'],
    title: '입주사 타입',
    guide: ['개인사업자, 법인사업자, 비사업자 내국인, 비사업자 외국인 중 한가지를 선택하세요.'],
    required: true,
    options: [
      { label: '개인사업자', value: 'sole-proprietor' },
      { label: '법인사업자', value: 'corporation-business' },
      { label: '비사업자 내국인', value: 'local' },
      { label: '비사업자 외국인', value: 'foreigner' },
    ],
    parser: parsers.option,
  },
  {
    key: ['tenant', 'businessNumber'],
    title: '입주사 사업자번호',
    guide: ['10자리 숫자만 입력하세요.\n(ex. 1234567890)'],
    parser: parsers.number,
  },
  {
    key: ['tenant', 'corporationNumber'],
    title: '입주사 법인번호',
    guide: ['13자리 숫자만 입력하세요.\n(ex. 1234561234567)'],
    parser: parsers.number,
  },
  {
    key: ['tenant', 'foreignerNumber'],
    title: '입주사 외국인번호',
    guide: ['13자리 숫자만 입력하세요.\n(ex. 01012341234)'],
    parser: parsers.number,
  },

  {
    key: ['director', 'name'],
    title: '대표자 이름',
    guide: [],
    required: true,
    parser: (v) => v || false,
  },
  { key: ['director', 'contact'], title: '대표자 연락처', guide: [] },
  { key: ['director', 'email'], title: '대표자 이메일', guide: [] },
  {
    key: ['director', 'residentNumber'],
    title: '대표자 주민번호',
    guide: ['13자리 숫자만 입력하세요.\n(ex. 1234561234567)'],
    parser: parsers.number,
  },
  {
    key: ['director', 'corporationNumber'],
    title: '대표자 법인번호',
    guide: ['13자리 숫자만 입력하세요.\n(ex. 1234561234567)'],
    parser: parsers.number,
  },

  {
    key: ['contractor', 'name'],
    title: '계약자 이름',
    guide: [],
    required: true,
    parser: (v) => v || false,
  },
  { key: ['contractor', 'contact'], title: '계약자 연락처', guide: [] },
  { key: ['contractor', 'email'], title: '계약자 이메일', guide: [] },
  { key: ['contractor', 'relationshipWithDirector'], title: '대표자와의 관계', guide: [] },

  {
    key: ['contractDeposit', 'price'],
    title: '보증금 금액',
    guide: ['숫자만 입력하세요.\n(ex. 300000)'],
    parser: parsers.number,
  },
  {
    key: ['contractDeposit', 'status'],
    title: '보증금 상태',
    guide: ['미납, 납부, 반환됨 중 한가지를 선택하세요.'],
    required: true,
    options: [
      { label: '미납', value: 'unpaid' },
      { label: '납부', value: 'paid' },
      { label: '반환됨', value: 'returned' },
    ],
    parser: parsers.option,
  },
  { key: ['contractDeposit', 'holder'], title: '반환계좌 예금주', guide: [] },
  {
    key: ['contractDeposit', 'bankCode'],
    title: '반환계좌 은행',
    guide: [
      '다음 중 하나를 입력하세요.',
      <Space style={{ flexWrap: 'wrap' }}>
        {bankCodes.map(({ value, label }) => (
          <Tag key={value}>{label}</Tag>
        ))}
      </Space>,
    ],
    parser: parsers.bank,
  },
  {
    key: ['contractDeposit', 'account'],
    title: '반환계좌 번호',
    guide: ['숫자만 입력하세요.\n(ex. 12312312123456)'],
    parser: parsers.number,
  },

  {
    key: 'contractSpaces',
    title: '공간',
    guide: [
      '[이름]/[금액]의 형태로 입력하세요.\n(ex. [101호]/[50000])',
      '여러 개 일 경우 줄바꿈으로 구분하세요.',
      '공간 이름은 가젯에 등록 된 공간 이름과 같아야 합니다.',
    ],
    parser: parsers.spaces,
  },
  {
    key: 'contractAdditions',
    title: '부가서비스',
    guide: [
      '[이름]/[금액]의 형태로 입력하세요.\n(ex. [101호]/[50000])',
      '여러 개 일 경우 줄바꿈으로 구분하세요.',
      '부가서비스 이름은 가젯에 등록 된 부가서비스 이름과 같아야 합니다.',
    ],
    parser: parsers.additions,
  },
]

export default columns
