import GreenButton from '@components/atoms/Buttons/GreenButton'

import style from './style.module.scss'

export default function Start(): JSX.Element {
  return (
    <>
      <div className={style.bg}>
        <div className={style.title}>가젯으로 공간을 더 쉽게 관리하세요</div>
        <GreenButton text="도입하기" large reversed path="" />
      </div>
    </>
  )
}
