import { Table, TableColumnType } from 'antd'
import dayjs from 'dayjs'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { ContractSignatureStatus } from 'gadjet-v2-types/dist/type'
import { contractSignatureStatus } from 'gadjet-v2-types/dist/type/label'

import TableColumnItem from '@components/molecules/TableColumn/Item'
import TypeTag from '@components/molecules/TypeTag'

type Props = {
  contracts: Contracts[]
}

export default function StartContractTable({ contracts }: Props): JSX.Element {
  const columns: TableColumnType<Contracts>[] = [
    { key: 'tenant', dataIndex: 'tenant', title: '입주사', render: (_, { tenant }) => tenant.name },
    {
      key: 'spaces',
      dataIndex: 'spaces',
      title: '공간',
      render: (_, { contractSpaces }) => {
        if (!contractSpaces) return '-'
        return contractSpaces.map(({ spaceId, name }) => <div key={spaceId}>{name}</div>)
      },
    },
    {
      key: 'contractId',
      dataIndex: 'contractId',
      title: '계약기간',
      render: (_, { startDate, suspendDate }) => (
        <>
          <TableColumnItem label="시작" value={dayjs(startDate).format('YYYY년 MM월 DD일')} />
          <TableColumnItem label="종료" value={dayjs(suspendDate).format('YYYY년 MM월 DD일')} />
        </>
      ),
    },
    {
      key: 'extendStatus',
      dataIndex: 'extendStatus',
      title: '서명상태',
      align: 'center',
      render: (_, { signatureStatus }) => (
        <TypeTag<ContractSignatureStatus>
          label={contractSignatureStatus[signatureStatus]}
          value={signatureStatus}
          switchCase={{
            values: [['none'], ['signed'], ['waiting']],
            types: ['default', 'success', 'ongoing'],
          }}
        />
      ),
    },
  ]
  return <Table rowKey={({ contractId }) => `${contractId}`} dataSource={contracts} columns={columns} />
}
