const chunk = <T>(array: T[], size: number): T[][] => {
  const chunked: T[][] = []

  for (const element of array) {
    const last = chunked[chunked.length - 1]

    if (!last || last.length === size) {
      chunked.push([element])
    } else {
      last.push(element)
    }
  }

  return chunked
}

export default chunk
