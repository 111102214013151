import { contractLateFeeType } from 'gadjet-v2-types/dist/type/label'
import { useMemo } from 'react'

import { krw } from '@utils/format'

import TableColumnItem from '@components/molecules/TableColumn/Item'
import TypeTag from '@components/molecules/TypeTag'

export default function LateFee({ contract }: ContractColumnProps): JSX.Element {
  const { lateFee, lateFeeType } = contract

  const amountLabel = useMemo(() => {
    if (lateFeeType === 'fix') {
      return `${krw(lateFee)}`
    }
    if (lateFeeType === 'rate') {
      return `월 이용료의 ${lateFee}%`
    }
    return ''
  }, [lateFee, lateFeeType])

  if (lateFeeType === 'none') return <TypeTag label={contractLateFeeType[lateFeeType]} />

  return (
    <div>
      <TableColumnItem label="방식" value={<TypeTag label={contractLateFeeType[lateFeeType]} />} />
      <TableColumnItem label="금액" value={amountLabel} />
    </div>
  )
}
