import useModal from '@hooks/useModal'
import { Divider, TablePaginationConfig } from 'antd'
import dayjs from 'dayjs'
import { CreditLogs } from 'gadjet-v2-types/dist/model'
import { useEffect, useState } from 'react'

import TenantAPI from '@apis/branch/tenant'

import ModalAlert from '@components/molecules/ModalAlert'

import AddCreditModal from './AddCreditModal'
import Credit from './Credit'
import CreditLogTable from './CreditLogTable'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  tenantId: number
  onDone?: () => void
  onClose: () => void
}

export default function TenantCreditModal({ hqId, branchId, tenantId, visible, onClose, onDone }: Props): JSX.Element {
  const [addCreditModal, onVisibleAddCreditModal, onCloseAddCreditModal] = useModal({ visible: false })
  const [creditState, setCreditState] = useState({ loading: false, buy: 0, mileage: 0, contract: 0 })
  const [creditLogState, setCreditLogState] = useState<{ loading: boolean; creditLogs: CreditLogs[] }>({
    loading: false,
    creditLogs: [],
  })
  const [creditLogPagination, setCreditLogPagination] = useState({ current: 1, pageSize: 10, total: 0 })

  const getCredits = async () => {
    setCreditState({ ...creditState, loading: true })
    const { data } = await TenantAPI.getTenantCredits(
      { hqId, branchId, tenantId },
      { current: 1, date: dayjs().format('YYYY-MM-DD'), pageSize: 100, creditTypes: ['buy', 'contract', 'mileage'] }
    )
    const credit = { buy: 0, mileage: 0, contract: 0 }

    data.credits.forEach(({ type, amount }) => {
      credit[type] += amount
    })

    setCreditState({ loading: false, ...credit })
  }

  const getCreditLogs = async () => {
    setCreditLogState({ ...creditLogState, loading: true })
    const { data } = await TenantAPI.getTenantCreditLogs(
      { hqId, branchId, tenantId },
      {
        ...creditLogPagination,
        datetime: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
      }
    )
    setCreditLogPagination({ ...creditLogPagination, total: data.total })
    setCreditLogState({ creditLogs: data.creditLogs, loading: false })
  }

  const onDoneAddCredit = () => {
    getCredits()
    getCreditLogs()
  }

  const onChangeCreditLogPagination = ({ current = 1, pageSize = 10 }: TablePaginationConfig) =>
    setCreditLogPagination({ ...creditLogPagination, current, pageSize })

  useEffect(() => {
    if (!visible) return
    getCreditLogs()
  }, [creditLogPagination.current, creditLogPagination.pageSize])

  useEffect(() => {
    if (visible) {
      getCreditLogs()
      getCredits()
    }
  }, [visible])

  return (
    <ModalAlert title="입주사 크레딧" visible={visible} onCancel={onClose}>
      <div>
        <Credit buy={creditState.buy} mileage={creditState.mileage} contract={creditState.contract} />

        <Divider />

        <CreditLogTable
          loading={creditLogState.loading}
          creditLogs={creditLogState.creditLogs}
          onChange={onChangeCreditLogPagination}
          onClickAdd={onVisibleAddCreditModal}
          pagination={creditLogPagination}
        />

        <AddCreditModal
          hqId={hqId}
          branchId={branchId}
          tenantId={tenantId}
          visible={addCreditModal.visible}
          onClose={onCloseAddCreditModal}
          onDone={onDoneAddCredit}
        />
      </div>
    </ModalAlert>
  )
}
