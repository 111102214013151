import { COLORS } from '@const'
import { Row } from 'antd'
import { PaymentSummary } from 'gadjet-v2-types/dist/type'
import { paymentType } from 'gadjet-v2-types/dist/type/label'
import { useMemo } from 'react'

import { krw } from '@utils/format'

import ContentWrapper from '@components/atoms/ContentWrapper'
import SummaryItem from '@components/molecules/SummaryItem'

type Props = {
  summary: PaymentSummary
}

export default function BranchPaymentSummary({ summary }: Props): JSX.Element {
  const items = useMemo(
    () => [
      { key: 'total', label: '총 결제금액', value: krw(summary.total), style: { color: COLORS.success } },
      { key: 'card', label: paymentType.card, value: krw(summary.card), style: {} },
      { key: 'transfer', label: paymentType.transfer, value: krw(summary.transfer), style: {} },
      { key: 'direct', label: paymentType.direct, value: krw(summary.direct), style: {} },
      { key: 'cash', label: paymentType.cash, value: krw(summary.cash), style: {} },
      { key: 'refund', label: '환불', value: krw(summary.refund), style: { color: COLORS.error } },
      { key: 'oversea-card', label: paymentType['oversea-card'], value: krw(summary['oversea-card']), style: {} },
      // { key: 'paypal', label: paymentType.paypal, value: krw(summary.paypal), style: {} },
      // { key: 'alipay', label: paymentType.alipay, value: krw(summary.alipay), style: {} },
    ],
    [summary]
  )

  return (
    <ContentWrapper>
      <Row>
        {items.map(({ key, label, value, style }) => (
          <SummaryItem key={key} label={label} value={value} span={3} valueStyle={style} />
        ))}
      </Row>
    </ContentWrapper>
  )
}
