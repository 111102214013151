import { Layout } from 'antd'
import { useLocation } from 'react-router-dom'

import Footer from '@components/organisms/Layout/Landing/Footer'
import Header from '@components/organisms/Layout/Landing/Header'
import HomeHeader from '@components/organisms/Layout/Landing/HomeHeader'

type Props = {
  children: JSX.Element
}

export default function LandingTemplate({ children }: Props): JSX.Element {
  const location = useLocation()
  const isHome = location.pathname === '/'
  return (
    <>
      <Layout>
        {isHome ? <HomeHeader /> : <Header />}
        {children}
        <Footer />
      </Layout>
    </>
  )
}
