import { BranchTenantAction } from '@pages/Authorized/Branch/TenantPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchTenantAction) => void
}

export default function BranchTenantTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [{ label: '내보내기', buttonType: 'dashed', onClick: () => onAction('export') }]

  return <PageTitle title="입주사" actions={actions} />
}
