import { BranchServiceSaleAction } from '@pages/Authorized/Branch/ServiceSalePage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchServiceSaleAction) => void
}

export default function BranchServiceSaleTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [{ label: '내보내기', buttonType: 'dashed', onClick: () => onAction('export') }]

  return <PageTitle title="서비스 판매내역" actions={actions} />
}
