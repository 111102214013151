import useModal from '@hooks/useModal'
import usePagination from '@hooks/usePagination'
import { BranchRoles } from 'gadjet-v2-types/dist/model'
import { useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import RoleAPI from '@apis/branch/role'

import { AdminGlobalContext } from '@components/contexts/AdminGlobal'
import { BranchRoleProvider } from '@components/contexts/Branch/Role'
import BranchRoleListTable from '@components/organisms/Branch/Role/ListTable'
import BranchRoleDeleteModal from '@components/organisms/Branch/Role/Modals/DeleteModal'
import BranchRoleInviteModal from '@components/organisms/Branch/Role/Modals/InviteModal'
import BranchRoleUpdateModal from '@components/organisms/Branch/Role/Modals/UpdateModal'
import BranchRoleSearchBox from '@components/organisms/Branch/Role/SearchBox'
import BranchRoleTitle from '@components/organisms/Branch/Role/Title'

export type BranchRoleAction = 'invite' | 'update' | 'delete'

export default function RolePage(): JSX.Element {
  const queryClient = useQueryClient()
  const { hqId, branchId } = useContext(AdminGlobalContext)
  // branchid 잘못 할당되어짐

  const [pagination, onChangePagination] = usePagination<BranchRoles>()

  const [inviteModal, onVisibleInviteModal, onCloseInviteModal] = useModal({ visible: false })
  const [updateModal, onVisibleUpdateModal, onCloseUpdateModal] = useModal<{ branchRoleId: number }>({
    visible: false,
    branchRoleId: 0,
  })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ branchRoleId: number }>({
    visible: false,
    branchRoleId: 0,
  })

  const onAction = (action: BranchRoleAction, branchRoleId?: number) => {
    if (action === 'invite') onVisibleInviteModal()
    if (action === 'update') onVisibleUpdateModal({ branchRoleId })
    if (action === 'delete') onVisibleDeleteModal({ branchRoleId })
  }

  const query = useQuery('RoleAPI.getRoles', () => RoleAPI.getRoles({ hqId, branchId }), { initialData: { data: [] } })
  const { isFetching, data } = query

  const refresh = () => {
    queryClient.invalidateQueries('RoleAPI.getRoles')
  }

  return (
    <BranchRoleProvider>
      <BranchRoleSearchBox search={{}} onSearch={refresh} onChangeSearch={() => {}} />
      <BranchRoleTitle onAction={onAction} />
      <BranchRoleListTable
        branchRoles={data?.data || []}
        loading={isFetching}
        onChangePagination={onChangePagination}
        pagination={pagination}
        total={data?.data.length || 0}
        onAction={onAction}
      />

      <BranchRoleInviteModal
        hqId={hqId}
        branchId={branchId}
        visible={inviteModal.visible}
        onClose={onCloseInviteModal}
      />
      <BranchRoleUpdateModal
        hqId={hqId}
        branchId={branchId}
        branchRoleId={updateModal.branchRoleId}
        visible={updateModal.visible}
        onClose={onCloseUpdateModal}
      />
      <BranchRoleDeleteModal
        hqId={hqId}
        branchId={branchId}
        branchRoleId={deleteModal.branchRoleId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={refresh}
      />
    </BranchRoleProvider>
  )
}
