import { Styles } from '@types'
import { Modal, Form, InputNumber, Col, Row, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

import TenantAPI from '@apis/branch/tenant'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  tenantId: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function AddCreditModal({ hqId, branchId, tenantId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<{
    contract: { amount: number; availableRange: [string, string] }
    mileage: { amount: number }
    buy: { amount: number }
  }>()
  const [loading, setLoading] = useState(false)

  const getWeekStartDateAndEndDate = (date?: string | Dayjs) => {
    const startDate = dayjs(date).startOf('week').format('YYYY-MM-DD')
    const endDate = dayjs(date).endOf('week').format('YYYY-MM-DD')
    return { startDate, endDate }
  }

  const onOk = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)
      const { data } = await TenantAPI.addTenantCredit({ hqId, branchId, tenantId }, { credit: values })

      if (data) notification.success({ message: '크레딧이 지급/차감 되었습니다.' })
      if (!data) notification.error({ message: '크레딧이 지급/차감 실패' })

      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const initialValues = useMemo(() => {
    const { startDate, endDate } = getWeekStartDateAndEndDate()
    return {
      contract: { amount: 0, availableRange: [startDate, endDate] },
      mileage: { amount: 0 },
      buy: { amount: 0 },
    }
  }, [])

  useEffect(() => {
    if (visible) form.resetFields()
  }, [visible])

  return (
    <Modal
      title="크레딧 지급/차감"
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      confirmLoading={loading}
      okText="지급/차감"
      width="400px"
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Row gutter={[20, 20]}>
            <Col span={6}>
              <Form.Item label="계약 크레딧" name={['contract', 'amount']}>
                <InputNumber style={styles.input} />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item
                label="사용기간"
                name={['contract', 'availableRange']}
                help="계약 크레딧 사용기간 입니다."
                getValueFromEvent={(date) => {
                  const { startDate, endDate } = getWeekStartDateAndEndDate(date)
                  return [startDate, endDate]
                }}
                getValueProps={(v) => ({ value: dayjs(v ? v[0] : null) })}
              >
                <LocalDatePicker.WeekPicker
                  allowClear={false}
                  format={(date) => {
                    const { startDate, endDate } = getWeekStartDateAndEndDate(date)
                    return `${startDate} ~ ${endDate}`
                  }}
                  style={styles.input}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="마일리지 크레딧" name={['mileage', 'amount']}>
            <InputNumber style={styles.input} />
          </Form.Item>
          <Form.Item label="구매 크레딧" name={['buy', 'amount']}>
            <InputNumber style={styles.input} />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  input: { width: '100%' },
}
