import { notification } from 'antd'

import ProductAPI from '@apis/branch/product'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  productId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ProductDeleteModal({
  hqId,
  branchId,
  productId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!productId) return
    await ProductAPI.deleteProduct({ hqId, branchId, productId })
    notification.success({ message: '제거되었습니다.' })
  }

  return (
    <ConfirmModal
      visible={visible}
      title="서비스가 제거됩니다."
      description="서비스 구매내역이 사라질 수 있습니다."
      onClose={onClose}
      onDone={onDone}
      onConfirm={onConfirm}
    />
  )
}
