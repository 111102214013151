import { Styles } from '@types'

type Props = {
  label: string
  value: number | string | JSX.Element
}

export default function TableColumnItem({ label, value }: Props): JSX.Element {
  return (
    <div style={styles.div}>
      <span style={styles.label}>{label}</span>
      <span style={styles.value}>{value}</span>
    </div>
  )
}

const styles: Styles = {
  div: { padding: '4px 0' },
  label: { opacity: '0.6', marginRight: '8px' },
  value: { marginRight: '8px' },
}
