import * as EXPENDITURE from 'gadjet-v2-types/dist/api/branch/expenditure'

import axios from '@utils/axios'

const ExpenditureAPI = {
  // 지출 조회
  getExpenditure: async (
    { hqId, branchId }: EXPENDITURE.GET_LIST_PARAMS,
    query: EXPENDITURE.GET_LIST_QUERY
  ): Promise<{ data: EXPENDITURE.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/expenditure`, { params: query }),

  // 지출 상세 조회
  getExpenditureDetail: async ({
    hqId,
    branchId,
    expenditureId,
  }: EXPENDITURE.GET_DETAIL_PARAMS): Promise<{ data: EXPENDITURE.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/expenditure/${expenditureId}`),

  // 지출 요약 조회
  getExpenditureSummary: async (
    { hqId, branchId }: EXPENDITURE.GET_SUMMARY_PARAMS,
    query: EXPENDITURE.GET_SUMMARY_QUERY
  ): Promise<{ data: EXPENDITURE.GET_SUMMARY_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/expenditure/summary`, { params: query }),

  // 지출 추가
  addExpenditure: async (
    { hqId, branchId }: EXPENDITURE.ADD_PARAMS,
    { expenditure }: EXPENDITURE.ADD_BODY
  ): Promise<{ data: EXPENDITURE.ADD_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/expenditure`, { expenditure }),

  // 지출 수정
  updateExpenditure: async (
    { hqId, branchId, expenditureId }: EXPENDITURE.UPDATE_PARAMS,
    { expenditure }: EXPENDITURE.UPDATE_BODY
  ): Promise<{ data: EXPENDITURE.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/expenditure/${expenditureId}`, { expenditure }),

  // 지출 제거
  deleteExpenditure: async ({
    hqId,
    branchId,
    expenditureId,
  }: EXPENDITURE.DELETE_PARAMS): Promise<{ data: EXPENDITURE.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/expenditure/${expenditureId}`),

  // 지출 내보내기
  exportExpenditure: async (
    { hqId, branchId }: EXPENDITURE.EXPORT_PARAMS,
    query: EXPENDITURE.EXPORT_QUERY
  ): Promise<{ data: EXPENDITURE.EXPORT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/expenditure/export`, { params: query }),
}

export default ExpenditureAPI
