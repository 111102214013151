import { notification, Modal, Alert } from 'antd'
import { useEffect, useState } from 'react'

import ProductAPI from '@apis/branch/product'

import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  productSaleId: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ProductSaleRefundModal({
  hqId,
  branchId,
  productSaleId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const onOk = async () => {
    if (!productSaleId) return
    setLoading(true)
    await ProductAPI.refundProductSale({ hqId, branchId, productSaleId })
    notification.success({ message: '환불되었습니다.' })
    setLoading(false)
    onDone()
    onClose()
  }

  useEffect(() => {
    if (visible) setLoading(false)
  }, [visible])

  return (
    <Modal
      title="상품 환불"
      visible={visible}
      onOk={onOk}
      onCancel={onClose}
      okText="환불"
      cancelText="취소"
      width="400px"
    >
      <Loading loading={loading}>
        <Alert showIcon type="warning" message="환불할까요?" />
      </Loading>
    </Modal>
  )
}
