import dayjs from 'dayjs'
import { all, call, put, takeLatest } from 'typed-redux-saga'

import HqGraphAPI from '@apis/hq/graph'

import {
  GET_HQ_GRAPH_CONTRACTS,
  GET_HQ_GRAPH_EXPECT_PAYMENTS,
  GET_HQ_GRAPH_PAYMENTS,
  GET_HQ_GRAPH_SPACE_TYPES,
  HqGraphAction,
  setHqGraph,
} from '@reducers/hq/graph.reducer'

function* getHqGraphPaymentsSaga(action: HqGraphAction): Generator {
  if (action.type !== GET_HQ_GRAPH_PAYMENTS) return
  const { hqId, cb } = action.payload

  const from = dayjs().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
  const to = dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD')

  const { data } = yield* call(HqGraphAPI.getGraphPayments, { hqId }, { date: [from, to] })
  yield* put(setHqGraph({ payments: data }))

  if (cb) cb()
}

function* getHqGraphExpectPaymentsSaga(action: HqGraphAction): Generator {
  if (action.type !== GET_HQ_GRAPH_EXPECT_PAYMENTS) return
  const { hqId, cb } = action.payload

  const from = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
  const to = dayjs().add(6, 'month').endOf('month').format('YYYY-MM-DD')

  const { data } = yield* call(HqGraphAPI.getGraphExpectPayments, { hqId }, { date: [from, to] })
  yield* put(setHqGraph({ expectPayments: data }))

  if (cb) cb()
}

function* getHqGraphContractsSaga(action: HqGraphAction): Generator {
  if (action.type !== GET_HQ_GRAPH_CONTRACTS) return
  const { hqId, cb } = action.payload

  const from = dayjs().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
  const to = dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD')

  const { data } = yield* call(HqGraphAPI.getGraphContracts, { hqId }, { date: [from, to] })
  yield* put(setHqGraph({ contracts: data }))

  if (cb) cb()
}

function* getHqGraphSpaceTypesSaga(action: HqGraphAction): Generator {
  if (action.type !== GET_HQ_GRAPH_SPACE_TYPES) return
  const { hqId, cb } = action.payload

  const from = dayjs().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
  const to = dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD')

  const { data } = yield* call(HqGraphAPI.getGraphSpaceTypes, { hqId }, { date: [from, to] })
  yield* put(setHqGraph({ spaceTypes: data }))

  if (cb) cb()
}

export default function* hqGraphSaga(): Generator {
  yield* all([
    takeLatest(GET_HQ_GRAPH_PAYMENTS, getHqGraphPaymentsSaga),
    takeLatest(GET_HQ_GRAPH_EXPECT_PAYMENTS, getHqGraphExpectPaymentsSaga),
    takeLatest(GET_HQ_GRAPH_CONTRACTS, getHqGraphContractsSaga),
    takeLatest(GET_HQ_GRAPH_SPACE_TYPES, getHqGraphSpaceTypesSaga),
  ])
}
