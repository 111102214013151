import { Styles } from '@types'
import { Modal, Form, Input, notification, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { Payments } from 'gadjet-v2-types/dist/model'
import { paymentType } from 'gadjet-v2-types/dist/type/label'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

import PaymentAPI from '@apis/branch/payment'

import { RootState } from '@reducers/index'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  billId?: number
  paymentId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function PaymentFormModal({
  hqId,
  branchId,
  billId,
  paymentId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const [form] = useForm<Payments>()
  const [loading, setLoading] = useState(false)
  const option = useSelector((state: RootState) => state.option)

  const getPayment = async () => {
    if (!paymentId) return
    setLoading(true)
    const { data } = await PaymentAPI.getPaymentDetail({ hqId, branchId, paymentId })
    form.setFieldsValue(data)
    setLoading(false)
  }

  const onOk = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      if (paymentId) await PaymentAPI.updatePayment({ hqId, branchId, paymentId }, { payment: values })
      if (!paymentId) await PaymentAPI.addPayment({ hqId, branchId }, { payment: values })

      notification.success({ message: '저장되었습니다.' })

      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const reset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (visible) {
      reset()
      getPayment()
    }
  }, [visible])

  const initialValues: Partial<Payments> = {
    item: '',
    price: 0,
    billId,
    approveDatetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    type: 'card',
    manualFlag: true,
    confirmFlag: true,
    status: 'approve',
    commissionPrice: 0,
    settlementPrice: 0,
    gadjetPrice: 0,
  }

  return (
    <Modal title="결제내역" okText="저장" onOk={onOk} confirmLoading={loading} visible={visible} onCancel={onClose}>
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <HiddenItems
            names={[
              'billId',
              'manualFlag',
              'confirmFlag',
              'status',
              'commissionPrice',
              'settlementPrice',
              'gadjetPrice',
            ]}
          />

          <Form.Item
            name="approveDatetime"
            label="날짜"
            getValueProps={(d) => ({ value: dayjs(d) })}
            getValueFromEvent={(_, d) => d}
            required
            rules={[formRule.required]}
          >
            <LocalDatePicker showTime />
          </Form.Item>
          <Form.Item name="item" label="상품" required rules={[formRule.required]}>
            <Input />
          </Form.Item>
          <Form.Item name="price" label="금액" required rules={[formRule.required]} {...mask.krw}>
            <Input addonAfter="원" />
          </Form.Item>
          <Form.Item name="type" label="타입" required rules={[formRule.required]}>
            <Select>
              {option.paymentTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {paymentType[type]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="memo" label="메모">
            <Input.TextArea style={styles.textArea} maxLength={200} />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  textArea: { minHeight: '200px', resize: 'none' },
}
