import { notification } from 'antd'

import ExpenditureAPI from '@apis/branch/expenditure'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  expenditureId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ExpenditureDeleteModal({
  hqId,
  branchId,
  expenditureId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!expenditureId) return
    await ExpenditureAPI.deleteExpenditure({ hqId, branchId, expenditureId })
    notification.success({ message: '제거되었습니다.' })
  }

  return (
    <ConfirmModal
      title="지출이 제거됩니다."
      okText="제거"
      visible={visible}
      onClose={onClose}
      onDone={onDone}
      onConfirm={onConfirm}
    />
  )
}
