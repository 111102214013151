import { notification, TableColumnType, Table, Button, Popconfirm } from 'antd'
import dayjs from 'dayjs'
import { EventAttendees } from 'gadjet-v2-types/dist/model'
import { useState, useEffect } from 'react'

import EventAPI from '@apis/branch/event'

import Loading from '@components/molecules/Loading'
import ModalAlert from '@components/molecules/ModalAlert'
import TableColumnItem from '@components/molecules/TableColumn/Item'

type Props = {
  hqId: number
  branchId: number
  eventId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function EventAttendeeModal({ hqId, branchId, eventId, visible, onClose, onDone }: Props): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [attendees, setAttendees] = useState<EventAttendees[]>([])

  const onDeleteAttendee = async (eventAttendeeId: number) => {
    if (!eventId || !eventAttendeeId) return
    setLoading(true)
    await EventAPI.deleteAttendee({ hqId, branchId, eventId, eventAttendeeId })
    notification.success({ message: '참가 취소 되었습니다.' })
    getEventAttendee()
  }

  const getEventAttendee = async () => {
    if (!eventId) return
    setLoading(true)
    const { data } = await EventAPI.getAttendees({ hqId, branchId, eventId })
    setAttendees(data)
    setLoading(false)
  }

  useEffect(() => {
    if (visible && eventId) {
      getEventAttendee()
    }
  }, [visible])

  const columns: TableColumnType<EventAttendees>[] = [
    {
      key: 'userId',
      dataIndex: 'userId',
      title: '신청자',
      render: (_, { user }) => (
        <>
          <TableColumnItem label="이름" value={user?.name || ' - '} />
          <TableColumnItem label="전화번호" value={user?.contact || ' - '} />
          <TableColumnItem label="이메일" value={user?.email || ' - '} />
        </>
      ),
    },
    {
      key: 'applyDatetime',
      dataIndex: 'applyDatetime',
      title: '신청시간',
      render: (_, { applyDatetime }) => dayjs(applyDatetime).format('YYYY년 MM월 DD일 HH시 mm분'),
    },
    {
      key: 'eventAttendeeId',
      dataIndex: 'eventAttendeeId',
      render: (eventAttendeeId) => (
        <Popconfirm
          title="참가가 취소되며 크레딧이 환불됩니다."
          okText="참가취소"
          cancelButtonProps={{ hidden: true }}
          onConfirm={() => onDeleteAttendee(eventAttendeeId)}
        >
          <Button size="small" type="text">
            취소
          </Button>
        </Popconfirm>
      ),
    },
  ]

  return (
    <ModalAlert title="이벤트 참가자" visible={visible} onCancel={onClose}>
      <Loading loading={loading}>
        <Table<EventAttendees>
          columns={columns}
          dataSource={attendees}
          rowKey={({ eventAttendeeId }) => `${eventAttendeeId}`}
        />
      </Loading>
    </ModalAlert>
  )
}
