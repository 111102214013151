import { TableColumnType } from 'antd'
import { Contracts } from 'gadjet-v2-types/dist/model'

import { ContractRowAction } from './Action'
import Addition from './Addition'
import BillingType from './BillingType'
import Contractor from './Contractor'
import Deposit from './Deposit'
import Director from './Director'
import ExtendStatus from './ExtendStatus'
import Fee from './Fee'
import LateFee from './LateFee'
import Memo from './Memo'
import SignatureStatus from './SignatureStatus'
import Space from './Space'
import StartDate from './StartDate'
import Status from './Status'
import SuspendDate from './SuspendDate'
import Tenant from './Tenant'

export type ContractColumn = Omit<TableColumnType<Contracts>, 'key'> & {
  key: keyof Contracts
  description: string
}

const columns = (onRowAction: (action: ContractRowAction, contract: Contracts) => void): ContractColumn[] => [
  {
    key: 'tenant',
    title: '입주사',
    dataIndex: 'tenant',
    render: (_, contract) => <Tenant contract={contract} onRowAction={onRowAction} />,
    description: '입주사 정보',
    ellipsis: true,
  },
  {
    key: 'startDate',
    title: '계약시작',
    dataIndex: 'startDate',
    description: '계약 시작일',
    ellipsis: true,
    render: (_, contract) => <StartDate contract={contract} onRowAction={onRowAction} />,
    sorter: true,
  },
  {
    key: 'suspendDate',
    title: '계약종료',
    dataIndex: 'suspendDate',
    description: '계약 종료일',
    ellipsis: true,
    render: (_, contract) => <SuspendDate contract={contract} onRowAction={onRowAction} />,
    sorter: true,
  },
  {
    key: 'director',
    title: '대표자',
    dataIndex: 'director',
    render: (_, contract) => <Director contract={contract} onRowAction={onRowAction} />,
    description: '대표자 정보',
    ellipsis: true,
  },
  {
    key: 'contractor',
    title: '계약자',
    dataIndex: 'contractor',
    render: (_, contract) => <Contractor contract={contract} onRowAction={onRowAction} />,
    description: '계약자 정보',
    ellipsis: true,
  },
  {
    key: 'address',
    title: '주소',
    dataIndex: 'address',
    description: '-',
    ellipsis: true,
    sorter: true,
  },
  {
    key: 'population',
    title: '인원',
    dataIndex: 'population',
    description: '-',
    ellipsis: true,
  },
  {
    key: 'contractId',
    title: '이용료',
    dataIndex: 'contractId',
    description: '이용료',
    ellipsis: true,
    render: (_, contract) => <Fee contract={contract} onRowAction={onRowAction} />,
  },
  {
    key: 'billingType',
    title: '납부',
    dataIndex: 'billingType',
    description: '납부 방식, 청구서 분할 방식',
    ellipsis: true,
    render: (_, contract) => <BillingType contract={contract} onRowAction={onRowAction} />,
  },

  {
    key: 'lateFee',
    title: '연체료',
    dataIndex: 'lateFee',
    description: '연체료 타입, 연체료 금액',
    ellipsis: true,
    render: (_, contract) => <LateFee contract={contract} onRowAction={onRowAction} />,
  },
  {
    key: 'publicMemo',
    title: '메모',
    dataIndex: 'publicMemo',
    description: '공개 메모, 비공개 메모',
    ellipsis: true,
    render: (_, contract) => <Memo contract={contract} onRowAction={onRowAction} />,
  },
  {
    key: 'status',
    title: '계약상태',
    dataIndex: 'status',
    description: '시작전 / 시작됨 / 만료됨 / 조기종료됨 / 연장됨',
    ellipsis: true,
    render: (_, contract) => <Status contract={contract} onRowAction={onRowAction} />,
  },
  {
    key: 'contractSpaces',
    title: '공간',
    dataIndex: 'contractSpaces',
    render: (_, contract) => <Space contract={contract} onRowAction={onRowAction} />,
    description: '계약 공간 정보',
    ellipsis: true,
  },
  {
    key: 'contractAdditions',
    title: '부가서비스',
    dataIndex: 'contractAdditions',
    render: (_, contract) => <Addition contract={contract} onRowAction={onRowAction} />,
    description: '계약 부가서비스 정보',
    ellipsis: true,
  },
  {
    key: 'contractDeposit',
    title: '보증금',
    dataIndex: 'contractDeposit',
    render: (_, contract) => <Deposit contract={contract} onRowAction={onRowAction} />,
    description: '보증금 정보',
    ellipsis: true,
  },
  {
    key: 'signatureStatus',
    title: '계약서 / 서명',
    dataIndex: 'signatureStatus',
    description: '상태(서명불가 / 서명대기 / 서명완료) 및 계약서 조회',
    ellipsis: true,
    render: (_, contract) => <SignatureStatus contract={contract} onRowAction={onRowAction} />,
  },
  {
    key: 'extendStatus',
    title: '연장',
    dataIndex: 'extendStatus',
    description: '확인 중 / 연장동의 / 연장거절',
    ellipsis: true,
    render: (_, contract) => <ExtendStatus contract={contract} onRowAction={onRowAction} />,
  },
]

export default columns
