import '@styles/button.scss'
import { useHistory } from 'react-router-dom'

type Props = {
  text: string
  path: string
}

export default function TopTab({ text, path }: Props): JSX.Element {
  const { push } = useHistory()

  const onClick = () => {
    if (text === '주요기능') push(path)
    if (text === 'ABOUT') push(path)
    if (text === '가이드') window.open(path)
  }

  return (
    <>
      <button className="topTab" type="button" onClick={onClick} id={text}>
        {text}
      </button>
    </>
  )
}
