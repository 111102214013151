import { Route, Switch } from 'react-router-dom'

import AuthTemplate from '@templates/AuthTemplate'

import AboutPage from '@pages/Landing/About'
import FeaturesPage from '@pages/Landing/Features'
import Home from '@pages/Landing/Home'
import LandingTemplate from '@pages/Landing/Index'
import ForgetPasswordPage from '@pages/UnAuthorized/ForgetPasswordPage'
import JoinPage from '@pages/UnAuthorized/JoinPage'
import LoginPage from '@pages/UnAuthorized/LoginPage'
import ResetPasswordPage from '@pages/UnAuthorized/ResetPasswordPage'

export default function UnauthorizedRoutes(): JSX.Element {
  return (
    <Switch>
      <Route path={['/join', '/login', '/forget-password', '/reset-password']}>
        <AuthTemplate>
          <Switch>
            <Route exact path="/join">
              <JoinPage />
            </Route>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route exact path="/forget-password">
              <ForgetPasswordPage />
            </Route>
            <Route exact path="/reset-password">
              <ResetPasswordPage />
            </Route>
          </Switch>
        </AuthTemplate>
      </Route>
      <Route path={['/', '/about', '/feature']}>
        <LandingTemplate>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/about">
              <AboutPage />
            </Route>
            <Route exact path="/feature">
              <FeaturesPage />
            </Route>
          </Switch>
        </LandingTemplate>
      </Route>
    </Switch>
  )
}
