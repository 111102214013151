import { notification } from 'antd'

import BranchCardAPI from '@apis/branch/branch-card'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  branchCardId: number
  visible: false
  onClose: () => void
  onDone: () => void
}

export default function BranchCardActiveConfirm({
  hqId,
  branchId,
  branchCardId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    await BranchCardAPI.updateBranchCardBilling({ hqId, branchId, branchCardId })
    notification.success({ message: '결제수단이 활성화되었습니다.' })
    onDone()
  }

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      title="결제수단 활성화"
      description="선택한 결제수단 이외의 결제수단은 비활성화 됩니다."
      visible={visible}
    />
  )
}
