import { COLORS } from '@const'
import { Progress, Row } from 'antd'
import { BranchDashboardEventSummary } from 'gadjet-v2-types/dist/type'
import { CSSProperties, useMemo } from 'react'

import { krw } from '@utils/format'

import ContentWrapper from '@components/atoms/ContentWrapper'
import Loading from '@components/molecules/Loading'
import SummaryItem from '@components/molecules/SummaryItem'

type Props = {
  summary: BranchDashboardEventSummary
  loading: boolean
}

type ItemKey = keyof BranchDashboardEventSummary
type Item = {
  key: ItemKey
  label: string
  flex?: number
  labelStyle?: CSSProperties
  valueStyle?: CSSProperties
  value?: (summary: BranchDashboardEventSummary) => string | number
}
const summaryItems: Item[] = [
  { key: 'salePrice', label: '매출', value: ({ salePrice }) => krw(salePrice) },
  { key: 'expectSalePrice', label: '예상매출', value: ({ expectSalePrice }) => krw(expectSalePrice) },
  { key: 'unpaidPrice', label: '미납', value: ({ unpaidPrice }) => krw(unpaidPrice) },
  { key: 'expenditurePrice', label: '지출', value: ({ expenditurePrice }) => krw(expenditurePrice) },
  { key: 'billingCount', label: '청구', value: ({ billingCount }) => `${billingCount}건` },
  { key: 'contractStartCount', label: '계약 시작', value: ({ contractStartCount }) => `${contractStartCount}건` },
  { key: 'contractEndCount', label: '계약 종료', value: ({ contractEndCount }) => `${contractEndCount}건` },
  {
    key: 'rentalReservationCount',
    label: '대여/대관 예약',
    value: ({ rentalReservationCount }) => `${rentalReservationCount}건`,
  },
]

export default function DashboardSummary({ summary, loading }: Props): JSX.Element {
  const percent = useMemo(() => {
    const { expectSalePrice, salePrice } = summary
    return Math.floor((salePrice / expectSalePrice) * 100)
  }, [summary])

  const strokeColor = useMemo(() => {
    if (percent === 100) return COLORS.success
    if (percent > 70) return COLORS.primary
    if (percent > 40) return COLORS.warn
    return COLORS.error
  }, [percent])

  return (
    <ContentWrapper>
      <Loading loading={loading}>
        <>
          <Progress percent={percent} showInfo={false} strokeColor={strokeColor} />
          <Row>
            {summaryItems.map(({ key, label, value, flex = 1, labelStyle, valueStyle }) => (
              <SummaryItem
                key={key}
                label={label}
                value={value ? value(summary) : summary[key]}
                flex={flex}
                labelStyle={labelStyle}
                valueStyle={valueStyle}
              />
            ))}
          </Row>
        </>
      </Loading>
    </ContentWrapper>
  )
}
