import { BranchAdditionAction } from '@pages/Authorized/Branch/AdditionPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (action: BranchAdditionAction) => void
}

export default function BranchAdditionTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    { label: '부가서비스 추가', buttonType: 'primary', onClick: () => onAction('add') },
  ]

  return <PageTitle title="부가서비스" actions={actions} />
}
