import { notification } from 'antd'

import EventAPI from '@apis/branch/event'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  eventId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function EventDeleteModal({ hqId, branchId, eventId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!eventId) return
    await EventAPI.deleteEvent({ hqId, branchId, eventId })
    notification.success({ message: '제거되었습니다.' })
  }

  return (
    <ConfirmModal
      title="이벤트 제거"
      description="이벤트가 제거됩니다."
      okText="제거"
      visible={visible}
      onConfirm={onConfirm}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
