import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { RootState } from '@reducers/index'

import AdminTemplate from '@templates/AdminTemplate'

import CodePage from '@pages/Authorized/CodePage'
import TestPage from '@pages/Test'

import FullPageLoading from '@components/organisms/FullPageLoading'

import BranchRouter from './BranchRouter'
import HqRouter from './HqRouter'

export default function AuthorizedRoutes(): JSX.Element {
  const { loading, indexPath } = useSelector((state: RootState) => state.menu)
  const { push } = useHistory()

  useEffect(() => {
    if (loading) return
    push(indexPath)
  }, [loading])

  if (loading) return <FullPageLoading />

  return (
    <>
      <Route exact path="/code">
        <CodePage />
      </Route>
      <Route exact path="/test">
        <TestPage />
      </Route>
      <Route path={['/hq/:hqId/branch/:branchId', '/hq/:hqId']}>
        <AdminTemplate>
          <Switch>
            <Route path="/hq/:hqId/branch/:branchId">
              <BranchRouter />
            </Route>
            <Route path="/hq/:hqId">
              <HqRouter />
            </Route>
            <Redirect path="*" push to={indexPath} />
          </Switch>
        </AdminTemplate>
      </Route>
    </>
  )
}
