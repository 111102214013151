import { notification } from 'antd'

import ServiceAPI from '@apis/branch/service'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  serviceSaleId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ServiceSaleRefundModal({
  hqId,
  branchId,
  serviceSaleId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!serviceSaleId) return
    await ServiceAPI.refundServiceSale({ hqId, branchId, serviceSaleId })
    notification.success({ message: '환불되었습니다.' })
  }

  return (
    <ConfirmModal
      title="서비스 환불"
      description="크레딧이 반환됩니다."
      okText="환불"
      visible={visible}
      onClose={onClose}
      onDone={onDone}
      onConfirm={onConfirm}
    />
  )
}
