import { useRouteMatch, Route, Switch } from 'react-router-dom'

import AdditionPage from '@pages/Authorized/Branch/AdditionPage'
import BranchContractPage from '@pages/Authorized/Branch/ContractPage'
import SpacePage from '@pages/Authorized/Branch/SpacePage'

export default function BranchContractRouter(): JSX.Element {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/list`}>
        <BranchContractPage />
      </Route>
      <Route path={`${url}/space`}>
        <SpacePage />
      </Route>
      <Route path={`${url}/addition`}>
        <AdditionPage />
      </Route>
    </Switch>
  )
}
