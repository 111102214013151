/* eslint-disable react/no-array-index-key */
import { Styles } from '@types'
import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import { BranchDashboardDayEvent } from 'gadjet-v2-types/dist/type'
import { useMemo } from 'react'

import chunk from '@utils/chunk'

import ContentWrapper from '@components/atoms/ContentWrapper'
import Loading from '@components/molecules/Loading'

import DashboardCalendarDate from './DashboardCalendarDate'

const emptyDate: Date = { date: '' }

type Date = { date: string; event?: BranchDashboardDayEvent }
type Week = Date[]

type Props = {
  dateRange: [string, string]
  events: { date: string; event: BranchDashboardDayEvent }[]
  loading: boolean
  onClickDate: (date: string) => void
}

const daynames = ['일', '월', '화', '수', '목', '금', '토']

export default function DashboardCalendar({ dateRange, events, loading, onClickDate }: Props): JSX.Element {
  const weeks = useMemo((): Week[] => {
    const [from, to] = dateRange

    const startDay = dayjs(from).day()
    const endDay = dayjs(to).day()

    const dates: Date[] = [...Array(startDay).fill(emptyDate), ...events, ...Array(6 - endDay).fill(emptyDate)]

    return chunk(dates, 7)
  }, [dateRange, events])

  return (
    <ContentWrapper>
      <Loading loading={loading}>
        <>
          <Row>
            {daynames.map((name) => (
              <Col key={name} flex={1}>
                <div style={styles.dayname}>{name}</div>
              </Col>
            ))}
          </Row>
          {weeks.map((week, weekIndex) => (
            <Row key={weekIndex}>
              {week.map((date, dateIndex) => (
                <Col key={dateIndex} flex="1 0 0">
                  <DashboardCalendarDate date={date.date} event={date.event} onClickDate={onClickDate} />
                </Col>
              ))}
            </Row>
          ))}
        </>
      </Loading>
    </ContentWrapper>
  )
}

const styles: Styles = {
  dayname: { textAlign: 'center' },
}
