import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { ReceiptSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  searchReceipts,
  setReceiptDefault,
  setReceiptPagination,
  setReceiptSearch,
} from '@reducers/branch/receipt.reducer'
import { RootState } from '@reducers/index'

import TaxInvoiceModal from '@components/organisms/Branch/Bill/Modals/TaxInoviceModal'
import BranchReceiptListTable from '@components/organisms/Branch/Receipt/ListTable'
import CancelCashReceiptModal from '@components/organisms/Branch/Receipt/Modals/CancelCashReceiptModal'
import BranchReceiptSearchBox from '@components/organisms/Branch/Receipt/SearchBox'
import BranchReceiptSummary from '@components/organisms/Branch/Receipt/Summary'
import BranchReceiptTitle from '@components/organisms/Branch/Receipt/Title'

export type BranchReceiptActionType = 'update-tax-invoice' | 'cancel-cash-receipt'

export default function ReceiptPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const [taxInvoiceModal, onVisibleTaxInvoiceModal, onCloseTaxInvoiceModal] = useModal({
    visible: false,
    receiptId: 0,
    billId: 0,
  })
  const [cashReceiptModal, onVisibleCashReceiptModal, onCloseCashReceiptModal] = useModal({
    visible: false,
    receiptId: 0,
    billId: 0,
  })

  const { loading, search, pagination, searchResult, summary } = useSelector((state: RootState) => state.receipt)
  const { branch } = useSelector((state: RootState) => state.menu)

  const onChangeSearch = (_search: Partial<ReceiptSearch>) => dispatch(setReceiptSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setReceiptPagination(_pagination))
    dispatch(searchReceipts(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setReceiptPagination({ current: 1 }))
    dispatch(searchReceipts(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setReceiptDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => dispatch(searchReceipts(hqId, branchId))

  const onAction = (actionType: BranchReceiptActionType, data?: { receiptId: number; billId: number }) => {
    if (actionType === 'update-tax-invoice') onVisibleTaxInvoiceModal(data)
    if (actionType === 'cancel-cash-receipt') onVisibleCashReceiptModal(data)
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchReceiptSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchReceiptSummary summary={summary} />

      <BranchReceiptTitle onAction={onAction} popbillId={branch?.popbillId} />

      <BranchReceiptListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        receipts={searchResult.receipts}
        total={searchResult.total}
        onAction={onAction}
      />

      <TaxInvoiceModal
        hqId={hqId}
        branchId={branchId}
        billId={taxInvoiceModal.billId}
        receiptId={taxInvoiceModal.receiptId}
        visible={taxInvoiceModal.visible}
        onClose={onCloseTaxInvoiceModal}
        onDone={onDone}
      />
      <CancelCashReceiptModal
        hqId={hqId}
        branchId={branchId}
        billId={cashReceiptModal.billId}
        receiptId={cashReceiptModal.receiptId}
        visible={cashReceiptModal.visible}
        onClose={onCloseCashReceiptModal}
        onDone={onDone}
      />
    </div>
  )
}
