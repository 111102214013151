import { combineReducers } from 'redux'
import { persistReducer, PersistConfig } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'

import auth, { AuthAction } from './auth.reducer'
import accessLog, { AccessLogAction } from './branch/access-log.reducer'
import addition, { AdditionAction } from './branch/addition.reducer'
import bill, { BillAction } from './branch/bill.reducer'
import branch, { BranchAction } from './branch/branch.reducer'
import cash, { CashAction } from './branch/cash.reducer'
import contract, { ContractAction } from './branch/contract.reducer'
import event, { EventAction } from './branch/event.reducer'
import expenditure, { ExpenditureAction } from './branch/expenditure.reducer'
import menu, { MenuAction } from './branch/menu.reducer'
import notice, { NoticeAction } from './branch/notice.reducer'
import notification, { NotificationAction } from './branch/notification.reducer'
import payment, { PaymentAction } from './branch/payment.reducer'
import productSale, { ProductSaleAction } from './branch/product-sale.reducer'
import product, { ProductAction } from './branch/product.reducer'
import receipt, { ReceiptAction } from './branch/receipt.reducer'
import rentalReservation, { RentalReservationAction } from './branch/rental-reservation.reducer'
import rental, { RentalAction } from './branch/rental.reducer'
import serviceSale, { ServiceSaleAction } from './branch/service-sale.reducer'
import service, { ServiceAction } from './branch/service.reducer'
import space, { SpaceAction } from './branch/space.reducer'
import tenant, { TenantAction } from './branch/tenant.reducer'
import hqBranch, { HqBranchAction } from './hq/branch.reducer'
import hqConfig, { HqConfigAction } from './hq/config.reducer'
import hqDashboard, { HqDashboardAction } from './hq/dashboard.reducer'
import hqGraph, { HqGraphAction } from './hq/graph.reducer'
import hq, { HqAction } from './hq/hq.reducer'
import hqNotice, { HqNoticeAction } from './hq/notice.reducer'
import hqRole, { HqRoleAction } from './hq/role.reducer'
import option, { OptionAction } from './option.reducer'
import storage, { StorageAction } from './storage.reducer'

const rootReducer = combineReducers({
  auth,
  menu,
  storage,
  contract,
  bill,
  receipt,
  space,
  branch,
  hq,
  rental,
  rentalReservation,
  tenant,
  notice,
  product,
  productSale,
  service,
  serviceSale,
  expenditure,
  payment,
  event,
  addition,
  cash,
  accessLog,
  option,
  notification,
  hqConfig,
  hqDashboard,
  hqGraph,
  hqNotice,
  hqRole,
  hqBranch,
})

export type RootState = ReturnType<typeof rootReducer>

export type RootAction =
  | BillAction
  | AuthAction
  | MenuAction
  | StorageAction
  | ContractAction
  | ReceiptAction
  | SpaceAction
  | BranchAction
  | HqAction
  | RentalAction
  | RentalReservationAction
  | TenantAction
  | NoticeAction
  | ProductAction
  | ProductSaleAction
  | ServiceAction
  | ServiceSaleAction
  | ExpenditureAction
  | PaymentAction
  | EventAction
  | AdditionAction
  | CashAction
  | AccessLogAction
  | OptionAction
  | NotificationAction
  | HqConfigAction
  | HqDashboardAction
  | HqGraphAction
  | HqNoticeAction
  | HqRoleAction
  | HqBranchAction

const persistConfig: PersistConfig<RootState> = {
  key: 'gadjet',
  storage: localStorage,
  whitelist: ['storage'],
}

export default persistReducer(persistConfig, rootReducer)
