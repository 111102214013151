import style from './style.module.scss'

export default function Companies(): JSX.Element {
  const logos: string[] = ['dreaum.png', 'garage.png', 'microwave.png', 'roomkor.png', 'urbanshares.jpg']
  return (
    <>
      <div className={style.bg}>
        <div className={style.title}>더 쉬운 공간 관리, 90여 개의 공유 공간이 가젯과 함께하고 있습니다.</div>
        <div className={style.container}>
          {logos.map((logo) => (
            <div className={style.wrapper}>
              <img src={`/images/landing/companies/${logo}`} alt="company-logo" className={style.img} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
