import { Styles } from '@types'
import { Input, Select, Tag } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { PaymentStatus, PaymentType } from 'gadjet-v2-types/dist/type'
import { paymentStatus, paymentType } from 'gadjet-v2-types/dist/type/label'
import { PaymentSearch } from 'gadjet-v2-types/dist/type/search'
import { RangeValue } from 'rc-picker/lib/interface'
import { useMemo } from 'react'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

const options: { types: PaymentType[]; status: PaymentStatus[] } = {
  types: ['card', 'direct', 'transfer', 'oversea-card', 'cash'], // 'wechatpay', 'paypal', 'alipay' 비활성화
  status: ['approve', 'refund', 'ready'],
}

type Props = {
  search: PaymentSearch
  onChangeSearch: (search: Partial<PaymentSearch>) => void
  onSearch: () => void
}

export default function BranchPaymentSearchBox({ search, onChangeSearch, onSearch }: Props): JSX.Element {
  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => onChangeSearch({ query: e.target.value })
  const onChangePaymentType = (_paymentType: PaymentType[]) => onChangeSearch({ paymentType: _paymentType })
  const onChangePaymentStatus = (_paymentStatus: PaymentStatus[]) => onChangeSearch({ paymentStatus: _paymentStatus })

  const onChangeDateRange = (_value: RangeValue<Dayjs>) => {
    const start = _value?.[0]?.format('YYYY-MM-DD') || search.date[0]
    const end = _value?.[1]?.format('YYYY-MM-DD') || search.date[1]
    onChangeSearch({ date: [start, end] })
  }

  const rangeValue = useMemo((): [Dayjs, Dayjs] => {
    const [start, end] = search.date
    return [dayjs(start), dayjs(end)]
  }, [search])

  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="날짜" span={6}>
        <LocalDatePicker.RangePicker
          size="large"
          style={styles.datePicker}
          value={rangeValue}
          onChange={onChangeDateRange}
        />
      </SearchItem>
      <SearchItem label="검색어" span={12}>
        <Input size="large" placeholder="검색어를 입력하세요." value={search.query} onChange={onChangeQuery} />
      </SearchItem>
      <SearchItem label="" span={6}>
        <></>
      </SearchItem>
      <SearchItem label="상태" span={6}>
        <Select
          mode="multiple"
          onChange={onChangePaymentStatus}
          size="large"
          value={search.paymentStatus}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
        >
          {options.status.map((ps) => (
            <Select.Option key={ps} value={ps}>
              {paymentStatus[ps]}
            </Select.Option>
          ))}
        </Select>
      </SearchItem>
      <SearchItem label="타입" span={12}>
        <Select
          mode="multiple"
          onChange={onChangePaymentType}
          size="large"
          value={search.paymentType.filter((pt) => options.types.includes(pt))}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
        >
          {options.types.map((pt) => (
            <Select.Option key={pt} value={pt}>
              {paymentType[pt]}
            </Select.Option>
          ))}
        </Select>
      </SearchItem>
    </SearchBox>
  )
}

const styles: Styles = {
  datePicker: { width: '100%' },
  select: { width: '100%' },
}
