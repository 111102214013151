import { Styles } from '@types'
import { Drawer, notification, Select } from 'antd'
import dayjs from 'dayjs'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import config from '@configs/index'

import { messaging } from '@utils/firebase'

import AuthAPI from '@apis/auth'
import ManagerAPI from '@apis/manager/notification'

import { RootState } from '@reducers/index'

import { AdminGlobalContext } from '@components/contexts/AdminGlobal'
import Loading from '@components/molecules/Loading'
import NotificationCard from '@components/organisms/Notification/Card'

const READ_AT_KEY = 'notification-read-at' as const
const FILTER_KEY = 'notification-filter' as const
const getQueryKey = (managerId: number) => ['getManagerNotifications', managerId]

export default function NotificationDrawer(): JSX.Element {
  const branches = useSelector((state: RootState) => state.menu.branches)
  const branchOptions = useMemo(() => branches.map((b) => ({ label: b.name, value: b.branchId })), [branches])
  const managerId = useSelector((state: RootState) => state.auth.manager?.managerId || 0)
  const { setUnread, drawer, setDrawer } = useContext(AdminGlobalContext)
  const [readAt, setReadAt] = useState(
    window.localStorage.getItem(READ_AT_KEY) || dayjs().format('YYYY-MM-DD HH:mm:ss')
  )

  const [isOpened, setIsOpened] = useState(false)
  const [filter, setFilter] = useState<number[]>([])

  const queryClient = useQueryClient()
  const { isFetching, data } = useQuery(getQueryKey(managerId), () => ManagerAPI.getNotifications({ managerId }), {
    refetchOnWindowFocus: false,
  })

  const notifications = useMemo(() => {
    if (!data) return []
    return data.data.filter(({ branchId }) => filter.includes(branchId))
  }, [data, filter])

  const onToggleDrawer = () => setDrawer(!drawer)
  const { push } = useHistory()

  const onClickMove = (link: string) => {
    push(link)
    onToggleDrawer()
  }

  const read = () => {
    setReadAt(dayjs().format('YYYY-MM-DD HH:mm:ss'))
    setUnread(false)
  }

  const setOnFcm = () => {
    messaging.onMessage(({ notification: { title, body } }) => {
      notification.open({ message: title, description: body })
      queryClient.invalidateQueries(getQueryKey(managerId))
    })
  }

  const setFcmToken = async () => {
    if (!managerId) return
    const pushToken = await messaging.getToken({ vapidKey: config.FCM_VAPID_KEY })
    const savedPushToken = window.localStorage.getItem('push-token') || ''
    if (savedPushToken === pushToken) return

    await AuthAPI.updatePushToken({ managerId }, { pushToken })
    window.localStorage.setItem('push-token', pushToken)
  }

  useEffect(() => {
    const savedFilterString = window.localStorage.getItem(FILTER_KEY)

    if (savedFilterString) setFilter(JSON.parse(savedFilterString))
    else setFilter(branchOptions.map(({ value }) => value))
  }, [branchOptions])

  useEffect(() => {
    window.localStorage.setItem(FILTER_KEY, JSON.stringify(filter || []))
  }, [filter])

  useEffect(() => {
    window.localStorage.setItem(READ_AT_KEY, readAt)
  }, [readAt])

  useEffect(() => {
    const isUnread = notifications.some((n) => n.sendDatetime > readAt)
    if (isUnread) setUnread(isUnread)
  }, [notifications])

  useEffect(() => {
    if (!drawer && isOpened) read()
    if (drawer && !isOpened) setIsOpened(true)
  }, [drawer])

  useEffect(() => {
    setFcmToken()
    setOnFcm()
  }, [])

  useEffect(() => {
    window.addEventListener('focus', setFcmToken)
    return () => {
      window.removeEventListener('focus', setFcmToken)
    }
  }, [])

  return (
    <Drawer
      forceRender
      visible={drawer}
      width="400px"
      onClose={onToggleDrawer}
      title={
        <Select
          style={styles.filterSelect}
          mode="tags"
          options={branchOptions}
          value={filter}
          onChange={setFilter}
          placeholder="알림을 확인 할 지점을 선택하세요."
        />
      }
      closable={false}
      bodyStyle={styles.drawerBody}
    >
      <Loading loading={isFetching}>
        <>
          {notifications.map((notification) => (
            <NotificationCard
              key={notification.branchNotificationId}
              notification={notification}
              onClickMove={onClickMove}
              readAt={readAt}
            />
          ))}
        </>
      </Loading>
    </Drawer>
  )
}

const styles: Styles = {
  drawerBody: { padding: 0 },
  filterSelect: { width: '100%' },
}
