/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import { TOKEN_KEY, TOKEN_TYPE } from '@const'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import config from '@configs/index'

const { IS_DEV, API_HOST, API_HOST_DEV } = config

export const getToken = (): string => window.localStorage.getItem(TOKEN_KEY) || ''

const instance = axios.create({
  baseURL: !IS_DEV ? API_HOST : API_HOST_DEV,
  withCredentials: true,
  headers: { authorization: `${TOKEN_TYPE} ${getToken()}` },
})

const requestErrorHandler = (err: AxiosError) => {
  console.log('==== axios request err : ', err)
}
const responseErrorHandler = (err: AxiosError) => {
  console.log('==== axios response err : ', err)
}

instance.interceptors.request.use((request: AxiosRequestConfig) => {
  return request
}, requestErrorHandler)

instance.interceptors.response.use((response: AxiosResponse) => {
  return response
}, responseErrorHandler)

export const setToken = (token: string): void => {
  instance.defaults.headers.authorization = `${TOKEN_TYPE} ${token}`
  window.localStorage.setItem(TOKEN_KEY, token)
}

export default instance
