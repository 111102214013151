import { MoreOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Table, TableColumnType, TablePaginationConfig } from 'antd'
import { Cash } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

import { krw } from '@utils/format'

import { BranchCashAction } from '@pages/Authorized/Branch/CashPage'

import ContentWrapper from '@components/atoms/ContentWrapper'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  cash: Cash[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchCashAction, cashId: number) => void
}
export default function BranchCashListTable({
  loading,
  total,
  pagination,
  cash,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<Cash>[] = [
    { key: 'date', dataIndex: 'date', title: '날짜' },
    { key: 'price', dataIndex: 'price', title: '금액', render: (price) => krw(price) },
    { key: 'memo', dataIndex: 'memo', title: '메모' },
    { key: 'managerId', dataIndex: 'managerId', title: '담당자', render: (_, { manager }) => manager?.name },
    { key: 'reserve', dataIndex: 'reserve', title: '잔액', render: (reserve) => krw(reserve) },
    {
      key: 'cashId',
      dataIndex: 'cashId',
      title: '',
      width: '80px',
      render: (cashId) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="update" onClick={() => onAction('update', cashId)}>
                수정
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => onAction('delete', cashId)}>
                제거
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <ContentWrapper>
      <Table<Cash>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ cashId }) => `${cashId}`}
        dataSource={cash}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomRight'],
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
      />
    </ContentWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
