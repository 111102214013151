import { FormInstance, Form, Row, Col, Input, Radio, Space } from 'antd'
import { Branches } from 'gadjet-v2-types/dist/model'
import { BranchBuildingContractType, ContractCreditPolicy } from 'gadjet-v2-types/dist/type'

import addressFinder from '@utils/addressFinder'
import mask from '@utils/mask'

import UploadButton from '@components/molecules/UploadButton'

type Props = {
  form: FormInstance<Branches>
  onChangeBuildingContractType: (buildingContractType: BranchBuildingContractType) => void
}

export default function BranchContractDocumentForm({ form, onChangeBuildingContractType }: Props): JSX.Element {
  const findAddress = () => {
    const values = form.getFieldValue(['contractDocument'])
    addressFinder.open(({ address, zonecode }) => {
      form.setFieldsValue({ contractDocument: { ...values, address, zipcode: zonecode } })
    })
  }

  const onUploadDone = (id: number | null, key: 'signatureImageId' | 'termsFileId' | 'policyFileId' | 'etcFileId') => {
    const values = form.getFieldValue(['contractDocument'])
    form.setFieldsValue({ contractDocument: { ...values, [key]: id } })
  }

  const buildingContractTypeOptions: { label: string; value: BranchBuildingContractType }[] = [
    { label: '없음', value: 'none' },
    { label: '임대', value: 'rent' },
    { label: '전대', value: 'sublet' },
  ]

  const creditPolicyOptions: { label: string; value: ContractCreditPolicy }[] = [
    { label: '주 단위', value: 'weekly' },
    { label: '월 단위', value: 'monthly' },
  ]

  return (
    <>
      <Form.Item label="우편번호" name={['contractDocument', 'zipcode']}>
        <Input onClick={findAddress} readOnly />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="주소" name={['contractDocument', 'address']}>
            <Input onClick={findAddress} readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="상세주소" name={['contractDocument', 'addressDetail']}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="건물 계약" name={['contractDocument', 'buildingContractType']}>
        <Radio.Group
          options={buildingContractTypeOptions}
          optionType="button"
          onChange={(e) => onChangeBuildingContractType(e.target.value)}
        />
      </Form.Item>
      <Form.Item label="건물 보증금" name={['contractDocument', 'deposit']} {...mask.krw}>
        <Input />
      </Form.Item>
      <Form.Item label="건물 임대료" name={['contractDocument', 'rent']} {...mask.krw}>
        <Input />
      </Form.Item>
      <Form.Item label="건물 관리비" name={['contractDocument', 'managementFee']} {...mask.krw}>
        <Input />
      </Form.Item>
      <Form.Item label="계약 크레딧 정책" name={['contractDocument', 'creditPolicy']}>
        <Radio.Group options={creditPolicyOptions} optionType="button" />
      </Form.Item>
      <Form.Item shouldUpdate label="파일">
        <Space direction="vertical" style={{ width: '100%' }}>
          <UploadButton.Image
            category="signature"
            file={null}
            label="서명"
            accept="image/*"
            onUploadDone={({ imageId }) => onUploadDone(imageId, 'signatureImageId')}
          />
          <UploadButton.File
            category="branch-terms"
            file={null}
            label="이용약관"
            accept=".pdf"
            onUploadDone={({ fileId }) => onUploadDone(fileId, 'termsFileId')}
          />
          <UploadButton.File
            category="branch-policy"
            file={null}
            label="개인정보처리방침"
            accept=".pdf"
            onUploadDone={({ fileId }) => onUploadDone(fileId, 'policyFileId')}
          />
          <UploadButton.File
            category="branch-etc"
            file={null}
            label="기타"
            accept=".pdf"
            onUploadDone={({ fileId }) => onUploadDone(fileId, 'etcFileId')}
          />
        </Space>
      </Form.Item>
      {/* // HiddenForm 으로 수정 */}
      <Form.Item label="서명" name={['contractDocument', 'signatureImageId']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label="이용약관" name={['contractDocument', 'termsFileId']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label="개인정보처리방침" name={['contractDocument', 'policyFileId']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label="기타" name={['contractDocument', 'etcFileId']} hidden>
        <Input />
      </Form.Item>
    </>
  )
}
