import useMenu, { BRANCH_MENUS } from '@hooks/useMenu'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { getBranch } from '@reducers/branch/branch.reducer'

import BranchAccessLogPage from '@pages/Authorized/Branch/AccessLogPage'
import BranchCashPage from '@pages/Authorized/Branch/CashPage'
import BranchConfigPage from '@pages/Authorized/Branch/ConfigPage'
import BranchDashboardPage from '@pages/Authorized/Branch/DashboardPage'
import BranchEventPage from '@pages/Authorized/Branch/EventPage'
import BranchExpenditurePage from '@pages/Authorized/Branch/ExpenditurePage'
import BranchNoticePage from '@pages/Authorized/Branch/NoticePage'
import BranchRolePage from '@pages/Authorized/Branch/RolePage'
import BranchTenantPage from '@pages/Authorized/Branch/TenantPage'
import NotAuthorizedPage from '@pages/Error/NotAuthorizedPage'

import BranchBillRouter from './BillRouter'
import BranchContractRouter from './ContractRouter'
import BranchProductRouter from './ProductRouter'
import BranchRentalRouter from './RentalRouter'
import BranchServiceRouter from './ServiceRouter'

const routePages = BRANCH_MENUS.map((m) => {
  let component: JSX.Element = <div />

  if (m.key === 'dashboard') component = <BranchDashboardPage />
  else if (m.key === 'contract') component = <BranchContractRouter />
  else if (m.key === 'bill') component = <BranchBillRouter />
  else if (m.key === 'rental') component = <BranchRentalRouter />
  else if (m.key === 'event') component = <BranchEventPage />
  else if (m.key === 'tenant') component = <BranchTenantPage />
  else if (m.key === 'notice') component = <BranchNoticePage />
  else if (m.key === 'expenditure') component = <BranchExpenditurePage />
  else if (m.key === 'product') component = <BranchProductRouter />
  else if (m.key === 'service') component = <BranchServiceRouter />
  else if (m.key === 'cash') component = <BranchCashPage />
  else if (m.key === 'accessLog') component = <BranchAccessLogPage />
  else if (m.key === 'role') component = <BranchRolePage />
  else if (m.key === 'config') component = <BranchConfigPage />

  return { ...m, component }
})

export default function BranchRouter(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId, branchMenus } = useMenu()
  useEffect(() => {
    dispatch(getBranch(hqId, branchId))
  }, [branchId])

  const { path } = useRouteMatch()

  const routes = useMemo(
    () => routePages.filter((rp) => branchMenus.some((bm) => bm.key === rp.key)),
    [routePages, branchMenus]
  )

  return (
    <Switch key={branchId}>
      {routes.map((ar) => (
        <Route path={`${path}/${ar.path}`} key={ar.key}>
          {ar.component}
        </Route>
      ))}
      <Route path="*">
        <NotAuthorizedPage />
      </Route>
    </Switch>
  )
}
