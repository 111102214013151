import * as ROLE from 'gadjet-v2-types/dist/api/hq/role'

import axios from '@utils/axios'

const HqRoleAPI = {
  // 권한 조회
  getRoles: async ({ hqId }: ROLE.GET_LIST_PARAMS): Promise<{ data: ROLE.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/roles`),

  // 권한 상세조회
  getRoleDetail: async ({ hqId, hqRoleId }: ROLE.GET_DETAIL_PARAMS) =>
    axios.get<ROLE.GET_DETAIL_RESPONSE>(`/hqs/${hqId}/roles/${hqRoleId}`),

  // 권한 추가
  addRole: async ({ hqId }: ROLE.ADD_PARAMS, { role }: ROLE.ADD_BODY): Promise<{ data: ROLE.ADD_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/roles`, { role }),

  // 권한 수정
  updateRole: async (
    { hqId, hqRoleId }: ROLE.UPDATE_PARAMS,
    { role }: ROLE.UPDATE_BODY
  ): Promise<{ data: ROLE.UPDATE_RESPONSE }> => axios.put(`/hqs/${hqId}/roles/${hqRoleId}`, { role }),

  // 권한 제거
  deleteRole: async ({ hqId, hqRoleId }: ROLE.DELETE_PARAMS): Promise<{ data: ROLE.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/roles/${hqRoleId}`),

  // 권한 초대
  inviteRole: async (
    { hqId }: ROLE.INVITE_PARAMS,
    { hqRole, email }: ROLE.INVITE_BODY
  ): Promise<{ data: ROLE.INVITE_RESPONSE }> => axios.post(`/hqs/${hqId}/roles/invite`, { hqRole, email }),
}

export default HqRoleAPI
