import { faHandshake, faWindowMaximize, faComments } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import GreenButton from '@components/atoms/Buttons/GreenButton'

import style from './style.module.scss'

export default function Service(): JSX.Element {
  const service = [
    { icon: faHandshake, title: '방문 미팅', remark: '직접 방문해 궁금증을 해결해 드립니다.', buttonText: '요청하기' },
    { icon: faWindowMaximize, title: '가이드', remark: '그대로만 따라 오시면 됩니다.', buttonText: '보러가기' },
    {
      icon: faComments,
      title: '실시간 문의',
      remark: '궁금한 사항은 가젯 매니저에게 즉시 물어보세요.',
      buttonText: '문의하기',
    },
  ]
  return (
    <>
      <div className={style.bg}>
        <div className={style.container}>
          {service.map((el) => (
            <div className={style.wrapper}>
              <div>
                <FontAwesomeIcon icon={el.icon} style={{ fontSize: '32px', color: '#3ebd73' }} />
              </div>
              <div className={style.title}>{el.title}</div>
              <div className={style.content}>{el.remark}</div>
              <GreenButton text={el.buttonText} noColor small path="" />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
