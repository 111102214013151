import { BranchPaymentAction } from '@pages/Authorized/Branch/PaymentPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchPaymentAction) => void
}

export default function BranchPaymentTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    // { label: '', buttonType: 'primary', onClick: () => onAction('') }
  ]

  return <PageTitle title="결제내역" actions={actions} />
}
