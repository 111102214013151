import { Form, FormInstance, Input, Select, TreeSelect } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { Contracts, Tenants } from 'gadjet-v2-types/dist/model'
import { tenantType } from 'gadjet-v2-types/dist/type/label'
import debounce from 'lodash.debounce'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

import TenantAPI from '@apis/branch/tenant'

import { RootState } from '@reducers/index'

type Props = {
  form: FormInstance<Contracts>
  isUpdate: boolean
  options: { label: string; value: number }[]

  hqId: number
  branchId: number
}

export default function TenantTab({ form, isUpdate, options, hqId, branchId }: Props): JSX.Element {
  const [tenantState, setTenantState] = useState<{ loading: boolean; tenants: Tenants[] }>({
    loading: false,
    tenants: [],
  })

  const { gics = [], tenantTypes = [] } = useSelector((state: RootState) => state.option)

  const tenantTypeOptions = useMemo(
    () => tenantTypes.map((type) => ({ label: tenantType[type], value: type })),
    [tenantTypes]
  )

  const onChangeTenant = (tenantId: SelectValue) => {
    const findTenant = tenantState.tenants.find((t) => t.tenantId === tenantId)
    if (!findTenant) return
    const _tenant = form.getFieldValue('tenant')
    form.setFieldsValue({ tenant: { ..._tenant, ...findTenant } })
  }

  const [currentType, setCurrentType] = useState<SelectValue>()

  const onChangeTenantType = (tenantType: SelectValue) => {
    const value = tenantType
    setCurrentType(value)
  }

  const searchTenants = useMemo(
    () =>
      debounce(async (query: string) => {
        setTenantState({ loading: true, tenants: [] })
        const { data } = await TenantAPI.getTenants(
          { hqId, branchId },
          { query, contractStatus: [], current: 1, pageSize: 30 }
        )
        setTenantState({ loading: false, tenants: data.tenants })
      }, 400),
    []
  )

  const tenantOptions = useMemo(() => {
    const searchTenantOptions = tenantState.tenants
      .map((tenant) => {
        const { tenantId, name } = tenant
        return { value: tenantId, label: name }
      })
      .filter((tenant) => !options.some((option) => option.value === tenant.value))

    return [...options, ...searchTenantOptions]
  }, [tenantState, options])

  return (
    <>
      <Form.Item label="입주사 검색" name="tenantId" extra="GADJET에 가입 된 입주사만 검색됩니다.">
        <Select
          loading={tenantState.loading}
          onSearch={searchTenants}
          options={tenantOptions}
          showSearch
          optionFilterProp="label"
          onChange={onChangeTenant}
        />
      </Form.Item>
      <Form.Item label="입주사 이름" name={['tenant', 'name']} rules={[formRule.required]}>
        <Input />
      </Form.Item>
      <Form.Item label="주소" name={['tenant', 'address']}>
        <Input />
      </Form.Item>
      <Form.Item label="연락처" name={['tenant', 'contact']} {...mask.contact}>
        <Input />
      </Form.Item>
      <Form.Item label="이메일" name={['tenant', 'email']}>
        <Input />
      </Form.Item>
      <Form.Item label="타입" name={['tenant', 'type']}>
        <Select options={tenantTypeOptions} onChange={onChangeTenantType} />
      </Form.Item>

      {/*  To do 코드 수정 */}
      {currentType === undefined && (
        <>
          <Form.Item label="사업자번호" name={['tenant', 'businessNumber']} {...mask.businessLicense}>
            <Input />
          </Form.Item>
          <Form.Item label="법인등록번호" name={['tenant', 'corporationNumber']} {...mask.personalLicense}>
            <Input />
          </Form.Item>
        </>
      )}

      {currentType === 'sole-proprietor' && (
        <>
          <Form.Item label="사업자번호" name={['tenant', 'businessNumber']} {...mask.businessLicense}>
            <Input />
          </Form.Item>
          <Form.Item label="법인등록번호" name={['tenant', 'corporationNumber']} {...mask.personalLicense}>
            <Input />
          </Form.Item>
        </>
      )}

      {currentType === 'corporation-business' && (
        <>
          <Form.Item label="사업자번호" name={['tenant', 'businessNumber']} {...mask.businessLicense}>
            <Input />
          </Form.Item>
          <Form.Item label="법인등록번호" name={['tenant', 'corporationNumber']} {...mask.personalLicense}>
            <Input />
          </Form.Item>
        </>
      )}

      {currentType === 'local' && (
        <Form.Item label="주민등록번호" name={['tenant', 'businessNumber']} {...mask.personalLicense}>
          <Input />
        </Form.Item>
      )}

      {currentType === 'foreigner' && (
        <Form.Item label="외국인번호" name={['tenant', 'foreignerNumber']} {...mask.personalLicense}>
          <Input />
        </Form.Item>
      )}

      <Form.Item
        label="종사업장번호"
        name={['tenant', 'identityNumber']}
        help="세금계산서 발행 시 사용됩니다. ex) 0001"
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item label="산업분류" name={['tenant', 'gicsCode']}>
        <TreeSelect showSearch treeDefaultExpandAll treeNodeFilterProp="title">
          {gics.map((g1) => (
            <TreeSelect.TreeNode key={g1.code} value={g1.code} title={g1.ko}>
              {g1.children?.map((g2) => (
                <TreeSelect.TreeNode key={g2.code} value={g2.code} title={g2.ko}>
                  {g2.children?.map((g3) => (
                    <TreeSelect.TreeNode key={g3.code} value={g3.code} title={g3.ko}>
                      {g3.children?.map((g4) => (
                        <TreeSelect.TreeNode key={g4.code} value={g4.code} title={g4.ko} />
                      ))}
                    </TreeSelect.TreeNode>
                  ))}
                </TreeSelect.TreeNode>
              ))}
            </TreeSelect.TreeNode>
          ))}
        </TreeSelect>
      </Form.Item>
    </>
  )
}
