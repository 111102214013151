import { Col, Divider, Form, Input, Modal, notification, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { BranchRoles } from 'gadjet-v2-types/dist/model'
import { Role } from 'gadjet-v2-types/dist/type'
import { useContext, useEffect, useMemo, useState } from 'react'

import formRule from '@utils/formRule'

import RoleAPI from '@apis/branch/role'

import { BranchRoleContext } from '@components/contexts/Branch/Role'
import Loading from '@components/molecules/Loading'
import RoleSelector from '@components/molecules/RoleSelector'

type BranchRoleKey = keyof Omit<
  BranchRoles,
  'branchRoleId' | 'hqId' | 'branchId' | 'managerId' | 'adminFlag' | 'branch' | 'manager'
>
type FormData = { email: string; role: { [key in BranchRoleKey]: Role } }

type Props = { hqId: number; branchId: number; visible: boolean; onClose: () => void }
export default function BranchRoleInviteModal({ hqId, branchId, visible, onClose }: Props): JSX.Element {
  const { branchRoleColumnKeys, branchRoleColumnLabels } = useContext(BranchRoleContext)

  const [loading, setLoading] = useState(false)
  const [form] = useForm<FormData>()

  const onOk = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)
      await RoleAPI.inviteRole({ hqId, branchId }, { email: values.email, branchRole: values.role })
      notification.success({ message: '이메일이 발송되었습니다.' })
      onClose()
      setLoading(false)
    } catch (err) {
      notification.error({ message: '이메일 발송실패.' })
      setLoading(false)
    }
  }

  const initialValues: FormData = useMemo(() => {
    return {
      email: '',
      role: {
        dashboard: 1,
        contract: 1,
        space: 1,
        addition: 1,
        bill: 1,
        receipt: 1,
        payment: 1,
        rental: 1,
        tenant: 1,
        notice: 1,
        product: 1,
        service: 1,
        expenditure: 1,
        event: 1,
        cash: 1,
        accessLog: 1,
        role: 1,
        config: 1,
      },
    }
  }, [visible])

  useEffect(() => {
    if (!visible) return
    form.resetFields()
  }, [visible])

  return (
    <Modal title="초대하기" visible={visible} onCancel={onClose} onOk={onOk} okText="이메일 보내기">
      <Loading loading={loading}>
        <Form<FormData> form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item
            label="이메일"
            name="email"
            required
            rules={[formRule.email]}
            extra="코드가 담긴 이메일이 발송됩니다."
          >
            <Input />
          </Form.Item>
          <Divider />
          <Row gutter={[20, 20]}>
            {branchRoleColumnKeys.map((key) => (
              <Col span={8} key={key}>
                <Form.Item label={branchRoleColumnLabels[key]} name={['role', key]} valuePropName="role">
                  <RoleSelector />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      </Loading>
    </Modal>
  )
}
