import { COLORS } from '@const'
import { Styles } from '@types'
import dayjs from 'dayjs'
import { BranchDashboardDayEvent } from 'gadjet-v2-types/dist/type'
import { useMemo } from 'react'

import { krw } from '@utils/format'

type Props = {
  date: string
  event?: BranchDashboardDayEvent
  onClickDate: (date: string) => void
}

export default function DashboardCalendarDate({ date, event, onClickDate }: Props): JSX.Element {
  const dateLabel = useMemo(() => (date ? dayjs(date).format('D') : ''), [date])

  const isToday = useMemo(() => date === dayjs().format('YYYY-MM-DD'), [date])

  const dateLabelStyle = useMemo(() => {
    if (!isToday) return styles.dateLabel
    return { ...styles.dateLabel, ...styles.dateLabelToday }
  }, [isToday])

  const events = useMemo(() => {
    if (!event) return []

    return [
      {
        label: '청구',
        key: 'billingCount',
        value: `${event.billingCount}건`,
        style: COLORS.calendarEvents[0],
        hide: event.billingCount === 0,
      },
      {
        label: '계약시작',
        key: 'contractStartCount',
        value: `${event.contractStartCount}건`,
        style: COLORS.calendarEvents[1],
        hide: event.contractStartCount === 0,
      },
      {
        label: '계약종료',
        key: 'contractEndCount',
        value: `${event.contractEndCount}건`,
        style: COLORS.calendarEvents[2],
        hide: event.contractEndCount === 0,
      },
      {
        label: '지출',
        key: 'expenditurePrice',
        value: `${krw(event.expenditurePrice)}`,
        style: COLORS.calendarEvents[3],
        hide: event.expenditurePrice === 0,
      },
      {
        label: '결제',
        key: 'paymentPrice',
        value: `${krw(event.paymentPrice)}`,
        style: COLORS.calendarEvents[4],
        hide: event.paymentPrice === 0,
      },
      {
        label: '대관/대여',
        key: 'rentalReservationCount',
        value: `${event.rentalReservationCount}건`,
        style: COLORS.calendarEvents[5],
        hide: event.rentalReservationCount === 0,
      },
    ].filter(({ hide }) => !hide)
  }, [event])

  return (
    <button type="button" style={styles.wrapper} onClick={() => onClickDate(date)}>
      <div style={dateLabelStyle}>{dateLabel}</div>
      {events.map((e) => (
        <div key={e.key} style={{ ...styles.event, ...e.style }}>
          {e.label} {e.value}
        </div>
      ))}
    </button>
  )
}

const styles: Styles = {
  wrapper: {
    padding: '2px',
    border: '1px solid #eee',
    height: '170px',
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    cursor: 'pointer',
    width: '100%',
    backgroundColor: 'inherit',
  },
  dateLabel: { textAlign: 'center', width: '100%' },
  dateLabelToday: { fontWeight: 'bold', color: COLORS.primary },
  event: { width: '98%', textAlign: 'left', fontSize: '12px', padding: '1px 4px', borderRadius: '4px' },
}
