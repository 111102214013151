import { Styles } from '@types'
import { Button, Table, TableColumnType, TablePaginationConfig, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { CreditLogs } from 'gadjet-v2-types/dist/model'
import { creditType, creditUsage } from 'gadjet-v2-types/dist/type/label'

import Loading from '@components/molecules/Loading'

const { Text } = Typography

type Props = {
  pagination: TablePaginationConfig
  onChange: (pagination: TablePaginationConfig) => void
  onClickAdd: () => void
  creditLogs: CreditLogs[]
  loading: boolean
}

export default function CreditLogTable({ pagination, onChange, onClickAdd, creditLogs, loading }: Props): JSX.Element {
  const columns: TableColumnType<CreditLogs>[] = [
    {
      key: 'amount',
      dataIndex: 'amount',
      title: '변화량',
      render: (_, { amount }) => {
        if (amount < 0)
          return (
            <Text type="danger" style={styles.amountMinus}>
              {amount}
            </Text>
          )
        if (amount > 0)
          return (
            <Text type="success" style={styles.amountPlus}>
              +{amount}
            </Text>
          )
        return (
          <Text type="secondary" style={styles.amountMinus}>
            {amount}
          </Text>
        )
      },
    },
    {
      key: 'usage',
      dataIndex: 'usage',
      title: '사용처',
      render: (_, { usage }) => (!usage ? '' : <Tag>{creditUsage[usage]}</Tag>),
    },
    {
      key: 'creditType',
      dataIndex: 'creditType',
      title: '크레딧 타입',
      render: (_, c) => <Tag>{creditType[c.creditType]}</Tag>,
    },
    {
      key: 'datetime',
      dataIndex: 'datetime',
      title: '시간',
      render: (_, { datetime }) => dayjs(datetime).format('YYYY년 MM월 DD일 MM시 hh분'),
    },
  ]

  return (
    <Loading loading={loading}>
      <Table
        title={() => (
          <div style={styles.titleContainer}>
            <div style={styles.title}>크레딧 사용 기록</div>
            <Button size="small" type="primary" onClick={onClickAdd}>
              지급/차감
            </Button>
          </div>
        )}
        columns={columns}
        dataSource={creditLogs}
        size="small"
        rowKey={({ creditLogId }) => `${creditLogId}`}
        onChange={onChange}
        pagination={pagination}
      />
    </Loading>
  )
}

const styles: Styles = {
  titleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  title: { fontSize: '20px' },
}
