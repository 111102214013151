import '@styles/features.scss'
import cn from 'classnames'

import FeatureItemManage from '@components/organisms/Landing/FeatureItem'

export default function FeaturesPage(): JSX.Element {
  const features: {
    title: string

    bg: string
    tabs: { tab: string; image: string; content: string }[]
  }[] = [
    {
      title: '한눈에 확인하는 공간 운영 현황',
      tabs: [
        { tab: '운영사 대시보드', image: 'hq-dashboard.png', content: '주요 현황을 요약해 모아볼 수 있어요.' },
        {
          tab: '매출 리포트',
          image: 'sales-report.png',
          content: '최근 6개월 매출 추이는 물론, 향후 6개월 예상 매출로 목표 관리도 가능해요.',
        },
        { tab: '지점 캘린더', image: 'branch-calander.png', content: '지점 현황을 한눈에 볼 수 있어요.' },
      ],
      bg: 'dark',
    },
    {
      title: '간단하고 빠른 온라인 계약',
      tabs: [
        {
          tab: '계약 생성',
          image: 'create-contract.png',
          content: '계약 정보만 입력하면 계약서가 담당자의 가젯 앱으로 자동으로 전송돼요.',
        },
        { tab: '전자서명', image: 'digital-sign.png', content: '앱에서 바로 서명하고 계약을 완료할 수 있어요.' },
        { tab: '계약서 관리', image: 'manage-contract.png', content: '복잡한 계약서, 이제 쉽게 관리해요.' },
      ],
      bg: 'white',
    },
    {
      title: '자동으로 관리하는 청구서와 증빙',
      tabs: [
        {
          tab: '청구서 자동 발송',
          image: 'sending-bill.png',
          content: '매달 신경 쓸 필요 없이 지정한 날자에 자동으로 발송돼요.',
        },
        { tab: '이용료 결제', image: 'fee-pay.png', content: '모바일 앱에서 빠르게 결제할 수 있어요.' },
        { tab: '증빙 발행', image: 'cash-tax-bill.png', content: '현금영수증도 세금계산서도 클릭 한 번이면 발행 끝!' },
      ],

      bg: 'dark',
    },
    {
      title: '크레딧으로 더 쉽게 이용하는 유료 서비스',
      tabs: [
        {
          tab: '크레딧 시스템',
          image: 'credit-system.png',
          content: '크레딧으로 회의실 예약, 상품 및 서비스 등을 편리하게 결제할 수 있어요.',
        },
        { tab: '공간예약관리', image: 'booking-rooms.png', content: '회의실 등 공간 예약도 효율적으로 관리해요.' },
      ],
      bg: 'white',
    },
  ]
  return (
    <>
      {features.map((feature) => (
        <div className={cn('feature-bg', feature.bg)}>
          <div className="feature-title">{feature.title}</div>
          <div className="tabs-wrapper">
            <FeatureItemManage tabs={feature.tabs} />
          </div>
        </div>
      ))}
    </>
  )
}
