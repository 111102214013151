import firebase from 'firebase'

export type GoogleUserProfile = { id: string; email: string; name: string }

export const initialize = () => {
  if (firebase.apps.length > 0) return
  const firebaseConfig = {
    apiKey: 'AIzaSyAuqmZ6ZsmgdKKSWt5sFPiKlHQgVapZbTk',
    authDomain: 'gadjet-v2.firebaseapp.com',
    projectId: 'gadjet-v2',
    storageBucket: 'gadjet-v2.appspot.com',
    messagingSenderId: '780095536764',
    appId: '1:780095536764:web:d7005bbb92129ee687d3f9',
    measurementId: 'G-C1JGSF9FE8',
  }
  firebase.initializeApp(firebaseConfig)
}

initialize()

export const storage = firebase.app().storage()

export const messaging = firebase.messaging()

export const auth = firebase.auth()

export const googleLogin = () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  return auth.signInWithPopup(provider)
}

export default firebase
