import { Styles } from '@types'
import { CSSProperties } from 'react'

import Loading from '@components/molecules/Loading'

type Props = { style?: CSSProperties; children: JSX.Element; loading?: boolean }
export default function ContentWrapper({ style, children, loading = false }: Props): JSX.Element {
  return (
    <div className="content-wrapper" style={{ ...styles.div, ...style }}>
      {children}

      {loading && (
        <div style={styles.loading}>
          <Loading />
        </div>
      )}
    </div>
  )
}

const styles: Styles = {
  div: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: '4px',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
}
