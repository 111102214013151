import { notification } from 'antd'

import SpaceAPI from '@apis/branch/space'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  spaceId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function SpaceDeleteModal({ hqId, branchId, spaceId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!spaceId) return

    await SpaceAPI.deleteSpace({ hqId, branchId, spaceId })
    notification.success({ message: '제거되었습니다.' })
  }

  return (
    <ConfirmModal
      visible={visible}
      title="공간 제거"
      description="공간이 제거되면 계약에 영향을 미칠 수 있습니다."
      onConfirm={onConfirm}
      onDone={onDone}
      onClose={onClose}
    />
  )
}
