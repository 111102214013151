import * as BILL from 'gadjet-v2-types/dist/api/branch/bill'

import axios from '@utils/axios'

const BillAPI = {
  // 청구서 목록 조회
  getBills: async (
    { hqId, branchId }: BILL.GET_LIST_PARAMS,
    query: BILL.GET_LIST_QUERY
  ): Promise<{ data: BILL.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/bills`, { params: query }),

  // 청구서 상세 조회
  getBill: async ({ hqId, branchId, billId }: BILL.GET_DETAIL_PARAMS): Promise<{ data: BILL.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/bills/${billId}`),

  // 청구서 요약
  getBillSummary: async (
    { hqId, branchId }: BILL.GET_SUMMARY_PARAMS,
    query: BILL.GET_SUMMARY_QUERY
  ): Promise<{ data: BILL.GET_SUMMARY_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/bills/summary`, { params: query }),

  // 청구서 추가
  addBill: async ({ hqId, branchId }: BILL.ADD_PARAMS, { bill }: BILL.ADD_BODY): Promise<{ data: BILL.ADD_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/bills`, { bill }),

  // 청구서 수정
  updateBiil: async (
    { hqId, branchId, billId }: BILL.UPDATE_PARAMS,
    { bill }: BILL.UPDATE_BODY
  ): Promise<{ data: BILL.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/bills/${billId}`, { bill }),

  // 청구서 제거
  deleteBill: async ({ hqId, branchId, billId }: BILL.DELETE_PARAMS): Promise<{ data: BILL.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/bills/${billId}`),

  // 청구서 내보내기
  exportBiils: async (
    { hqId, branchId }: BILL.EXPORT_PARAMS,
    query: BILL.EXPORT_QUERY
  ): Promise<{ data: BILL.EXPORT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/bills/export`, { params: query }),

  // 청구서 발송
  sendBill: async (
    { hqId, branchId, billId }: BILL.SEND_PARAMS,
    { receivers }: BILL.SEND_BODY
  ): Promise<{ data: BILL.SEND_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/bills/${billId}/send`, { receivers }),

  // 보증금 청구서 보증금 반환
  returnDeposit: async (
    { hqId, branchId, billId }: BILL.RETURN_DEPOSIT_PARAMS,
    { price, type }: BILL.RETURN_DEPOSIT_BODY
  ) =>
    axios.put<BILL.RETURN_DEPOSIT_RESPONSE>(`/hqs/${hqId}/branches/${branchId}/bills/${billId}/deposit-return`, {
      price,
      type,
    }),
}

export default BillAPI
