import { Styles } from '@types'
import { Alert, Form, FormInstance, Input, Radio } from 'antd'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { ContractBillingType, ContractLateFeeType } from 'gadjet-v2-types/dist/type'
import { contractBillingType, contractLateFeeType } from 'gadjet-v2-types/dist/type/label'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

import { RootState } from '@reducers/index'

type Props = { form: FormInstance<Contracts>; isUpdate: boolean }

export default function PaymentTab({ form, isUpdate }: Props): JSX.Element {
  const { billingTypes, billingSplitType, lateFeeTypes } = useSelector((state: RootState) => state.option)

  const billingTypeOptions = useMemo(
    () => billingTypes.map((type) => ({ label: contractBillingType[type], value: type })),
    [billingTypes]
  )

  const billingSplitTypeOptions = useMemo(
    () => billingSplitType.map((type) => ({ label: `${type}타입`, value: type })),
    [billingSplitType]
  )

  const lateFeeTypeOptions = useMemo(
    () => lateFeeTypes.map((type) => ({ label: contractLateFeeType[type], value: type })),
    [lateFeeTypes]
  )

  return (
    <>
      <Alert showIcon message="결제정보는 이후 수정이 불가능합니다." type="info" style={styles.alert} />

      <Form.Item label="납부타입" name="billingType">
        <Radio.Group disabled={isUpdate}>
          {billingTypeOptions.map(({ label, value }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const isPrepayment = getFieldValue('billingType') === 'prepayment'
          if (isPrepayment) return <></>
          return (
            <>
              <Form.Item label="청구타입" name="billingSplitType">
                <Radio.Group disabled={isUpdate}>
                  {billingSplitTypeOptions.map(({ label, value }) => (
                    <Radio key={value} value={value}>
                      {label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const billingType: ContractBillingType = getFieldValue('billingType')
                  const addonBefore = billingType === 'monthly' ? '매 월' : undefined
                  return (
                    <Form.Item label="청구일" name="paymentDate" rules={[formRule.required, formRule.date]}>
                      <Input type="number" addonBefore={addonBefore} addonAfter="일" disabled={isUpdate} />
                    </Form.Item>
                  )
                }}
              </Form.Item>
              <Form.Item label="납부기한" name="paymentDueDate" rules={[formRule.required]}>
                <Input type="number" addonBefore="청구일 이후" addonAfter="일" disabled={isUpdate} />
              </Form.Item>

              <Form.Item label="연체료" name="lateFeeType">
                <Radio.Group disabled={isUpdate}>
                  {lateFeeTypeOptions.map(({ label, value }) => (
                    <Radio key={value} value={value}>
                      {label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const lateFeeType: ContractLateFeeType = getFieldValue('lateFeeType')
                  const hidden = lateFeeType === 'none'

                  const addonBefore = lateFeeType === 'fix' ? undefined : '월 이용료의'
                  const addonAfter = lateFeeType === 'fix' ? '원' : '%'

                  const priceMask = lateFeeType === 'fix' ? mask.krw : {}

                  return (
                    <>
                      <Form.Item name="lateFee" hidden={hidden} {...priceMask}>
                        <Input disabled={isUpdate} addonBefore={addonBefore} addonAfter={addonAfter} />
                      </Form.Item>
                      <Form.Item
                        label="연체료 유예기간"
                        name="lateFeeDeferDate"
                        extra="납부기한 종료 n일 후 연체료가 부과됩니다."
                        hidden={hidden}
                      >
                        <Input type="number" addonBefore="납부기한 이후" addonAfter="일" disabled={isUpdate} />
                      </Form.Item>
                    </>
                  )
                }}
              </Form.Item>
            </>
          )
        }}
      </Form.Item>
    </>
  )
}

const styles: Styles = {
  alert: { marginBottom: '30px' },
}
