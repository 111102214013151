import { Styles } from '@types'
import dayjs, { Dayjs } from 'dayjs'
import { RentalReservations } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'
import * as RBC from 'react-big-calendar'

import ContentWrapper from '@components/atoms/ContentWrapper'
import Calendar from '@components/molecules/Calendar'

type Props = {
  loading: boolean
  view: RBC.View
  date: Dayjs
  rentalReservations: RentalReservations[]
  onNavigate?: (date: Dayjs) => void
  onChangeView?: (view: RBC.View) => void
  onClickEvent?: (reservation: RentalReservations) => void
  onSelectRange?: (range: { start: Dayjs; end: Dayjs }) => void
  onRangeChange?: (range: { start: Dayjs; end: Dayjs }) => void
}
export default function BranchRentalReservationCalendar({
  loading,
  view,
  date,
  rentalReservations,
  onChangeView = () => undefined,
  onNavigate = () => undefined,
  onClickEvent = () => undefined,
  onSelectRange = () => undefined,
  onRangeChange = () => undefined,
}: Props): JSX.Element {
  const onSelectEvent = ({ resource }: RBC.Event) => onClickEvent(resource)
  const onSelecting = () => true
  const onSelectSlot = ({ start, end }: RBC.SlotInfo) => onSelectRange({ start: dayjs(start), end: dayjs(end) })
  const onChangeRange = (range: { start: RBC.stringOrDate; end: RBC.stringOrDate } | Date[]) => {
    if (Array.isArray(range)) onRangeChange({ start: dayjs(range[0]), end: dayjs(range[range.length - 1]) })
    else onRangeChange({ start: dayjs(range.start), end: dayjs(range.end) })
  }

  const events = useMemo(
    (): RBC.Event[] =>
      rentalReservations.map((r) => ({
        title: `${r.rental?.name} / ${r.tenant?.name || '관리자 예약'} / ${r.memo || '메모 없음'}`,
        start: dayjs(`${r.date} ${r.startTime}`).toDate(),
        end: dayjs(`${r.date} ${r.endTime}`).toDate(),
        resource: r,
      })),
    [rentalReservations]
  )

  return (
    <ContentWrapper loading={loading}>
      <Calendar
        view={view}
        onView={onChangeView}
        events={events}
        onSelectEvent={onSelectEvent}
        onSelecting={onSelecting}
        onSelectSlot={onSelectSlot}
        selectable="ignoreEvents"
        drilldownView="week"
        date={date.toDate()}
        onNavigate={(d) => onNavigate(dayjs(d))}
        culture="ko-KR"
        onRangeChange={onChangeRange}
        style={styles.calendar}
      />
    </ContentWrapper>
  )
}

const styles: Styles = {
  calendar: { height: '800px' },
}
