import { notification } from 'antd'

import RentalAPI from '@apis/branch/rental'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  rentalId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function RentalDeleteModal({ hqId, branchId, rentalId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!rentalId) return
    await RentalAPI.deleteRental({ hqId, branchId, rentalId })
    notification.success({ message: '제거되었습니다.' })
  }

  return (
    <ConfirmModal
      title="대관/대여 제거"
      description="대관/대여 제거시 예약내역도 사라집니다."
      onConfirm={onConfirm}
      visible={visible}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
