import useModal from '@hooks/useModal'
import { Styles } from '@types'
import { Button, Col, Form, Input, notification, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Branches } from 'gadjet-v2-types/dist/model'
import { useEffect, useMemo, useState } from 'react'

import addressFinder from '@utils/addressFinder'
import mask from '@utils/mask'

import BranchAPI from '@apis/branch'

import JoinPopbillModal from '@components/organisms/Branch/JoinPopbillModal'

type Props = { hqId: number; branchId: number; branch?: Branches; onRefresh: () => void }

export default function BranchForm({ hqId, branchId, branch, onRefresh }: Props): JSX.Element {
  const [form] = useForm<Branches>()
  const [loading, setLoading] = useState(false)
  const [joinPopbillModal, onVisibleJoinPopbillModal, onCloseJoinPopbillModal] = useModal({ visible: false })

  const popbillId = useMemo(() => branch?.popbillId, [branch])

  const onDone = () => onRefresh()

  const onSubmit = async () => {
    const values = await form.validateFields()
    setLoading(true)
    await BranchAPI.updateBranch({ hqId, branchId }, { branch: values })
    setLoading(false)
    notification.success({ message: '저장되었습니다.' })
    onRefresh()
  }

  const findAddress = () => {
    addressFinder.open(({ address, zonecode }) => {
      form.setFieldsValue({ address, zipcode: zonecode })
    })
  }

  const resetForm = () => {
    form.resetFields()
    if (branch) form.setFieldsValue(branch)
  }

  useEffect(() => {
    resetForm()
  }, [branch])

  const initialValues: Partial<Branches> = {
    name: '',
    contact: '',
    email: '',
    zipcode: '',
    address: '',
    addressDetail: '',
  }

  return (
    <Form form={form} layout="vertical" initialValues={initialValues} style={styles.form}>
      <Form.Item label="지점 이름" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="지점 연락처" name="contact" {...mask.contact}>
        <Input />
      </Form.Item>
      <Form.Item label="지점 이메일" name="email">
        <Input />
      </Form.Item>

      <Form.Item label="우편번호" name="zipcode">
        <Input onClick={findAddress} readOnly />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="주소" name="address">
            <Input onClick={findAddress} readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="상세주소" name="addressDetail">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="팝빌 아이디" extra="팝빌 회원가입 후 현금영수증을 발행할 수 있습니다.">
        <Button disabled={!!popbillId} onClick={() => onVisibleJoinPopbillModal()}>
          {popbillId || '팝빌 회원가입'}
        </Button>
      </Form.Item>

      {!!branch && (
        <JoinPopbillModal
          hqId={hqId}
          branchId={branchId}
          branch={branch}
          visible={joinPopbillModal.visible}
          onClose={onCloseJoinPopbillModal}
          onDone={onDone}
        />
      )}

      <Button type="primary" size="large" onClick={onSubmit} loading={loading}>
        저장하기
      </Button>
    </Form>
  )
}

const styles: Styles = {
  form: {},
}
