import * as RECEIPT from 'gadjet-v2-types/dist/api/branch/receipt'

import axios from '@utils/axios'

const ReceiptAPI = {
  // 증빙 상세 조회
  getReceiptDetail: async ({
    hqId,
    branchId,
    receiptId,
  }: RECEIPT.GET_DETAIL_PARAMS): Promise<{ data: RECEIPT.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/receipts/${receiptId}`),

  // 증빙 목록 조회
  getReceipts: async (
    { hqId, branchId }: RECEIPT.GET_LIST_PARAMS,
    query: RECEIPT.GET_LIST_QUERY
  ): Promise<{ data: RECEIPT.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/receipts`, { params: query }),

  // 증빙 요약 조회
  getReceiptSummary: async (
    { hqId, branchId }: RECEIPT.GET_SUMMARY_PARAMS,
    query: RECEIPT.GET_SUMMARY_QUERY
  ): Promise<{ data: RECEIPT.GET_SUMMARY_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/receipts/summary`, { params: query }),

  // 세금계산서 발행
  issueTaxInvoice: async (
    { hqId, branchId }: RECEIPT.ADD_TAX_INVOICE_PARAMS,
    { billId, taxInvoice }: RECEIPT.ADD_TAX_INVOICE_BODY
  ): Promise<{ data: RECEIPT.ADD_TAX_INVOICE_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/receipts/tax-invoice`, { billId, taxInvoice }),

  // 수정세금계산서 발행
  updateTaxInvoice: async (
    { hqId, branchId, receiptId }: RECEIPT.UPDATE_TAX_INVOICE_PARAMS,
    { billId, taxInvoice }: RECEIPT.UPDATE_TAX_INOVICE_BODY
  ): Promise<{ data: RECEIPT.UPDATE_TAX_INVOICE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/receipts/${receiptId}/tax-invoice`, { billId, taxInvoice }),

  // 현금영수증 발행
  issueCashReceipt: async (
    { hqId, branchId }: RECEIPT.ADD_CASH_RECEIPT_PARAMS,
    { billId, cashReceipt }: RECEIPT.ADD_CASH_RECEIPT_BODY
  ): Promise<{ data: RECEIPT.ADD_CASH_RECEIPT_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/receipts/cash-receipt`, { billId, cashReceipt }),

  // 현금영수증 취소
  cancelCashReceipt: async (
    { hqId, branchId, receiptId }: RECEIPT.CANCEL_CASH_RECEIPT_PARAMS,
    { billId, cashReceipt }: RECEIPT.CANCEL_CASH_RECEIPT_BODY
  ): Promise<{ data: RECEIPT.CANCEL_CASH_RECEIPT_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/receipts/${receiptId}/cash-receipt`, { billId, cashReceipt }),
}

export default ReceiptAPI
