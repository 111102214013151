/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react'

import style from './style.module.scss'

type Props = {
  tabs: { tab: string; image: string; content: string }[]
}

export default function FeatureItemManage({ tabs }: Props) {
  const [imageIdx, setImageIdx] = useState<number>(0)
  const [selected, setSelected] = useState<string>(tabs[0].tab)

  return (
    <>
      <div className={style.tabWrapper}>
        {tabs.map((item, index: number) => (
          <div
            className={selected === item.tab ? style.tabTitleClicked : style.tabTitle}
            onClick={() => {
              setSelected(item.tab)
              setImageIdx(index)
            }}
          >
            {item.tab}
          </div>
        ))}
      </div>

      <img className={style.img} src={`images/landing/features/${tabs[imageIdx].image}`} alt="feature-item" />
      <div className={style.tabContent}>{tabs[imageIdx].content}</div>
    </>
  )
}
