import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@styles/header-landing.scss'
import { useEffect, useState } from 'react'

import GreenButton from '@components/atoms/Buttons/GreenButton'
import TopTab from '@components/atoms/Buttons/TopTab'
import TopTabMobile from '@components/atoms/Buttons/TopTabMobile'

export default function HomeHeader(): JSX.Element {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
  const topTabs: { label: string; path: string }[] = [
    { label: 'ABOUT', path: '/about' },
    { label: '주요기능', path: '/feature' },
    { label: '가이드', path: 'https://gadjet.notion.site/78b2e1111fcf43208f420b1815d7a479' },
  ]

  const [scrolled, setScrolled] = useState<boolean>(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        if (window.scrollY) {
          setScrolled(true)
        } else {
          setScrolled(false)
        }
      })
    }
  }, [])

  const onClickMenu = () => {
    setIsOpenMenu((prev) => !prev)
  }

  return (
    <>
      <header className={scrolled || isOpenMenu ? 'header-landing' : 'header-clear'}>
        <div className="wrapper">
          <img
            src={`/images/logo/${scrolled || isOpenMenu ? 'logo_b.png' : 'logo_w.png'}`}
            alt="logo"
            className="img"
          />
          <div className="tab">
            <div>
              {topTabs.map((tab) => (
                <TopTab text={tab.label} path={tab.path} />
              ))}
            </div>
            <div>
              <GreenButton text="로그인" noColor path="/login" />
              <GreenButton text="도입하기" path="" />
            </div>
          </div>
          <div className="hamburger-icon">
            <FontAwesomeIcon
              icon={faBars}
              onClick={onClickMenu}
              style={{ color: scrolled || isOpenMenu ? 'black' : 'white' }}
            />
          </div>
        </div>
        {isOpenMenu && (
          <div className="menu-open">
            {topTabs.map((tab) => (
              <TopTabMobile text={tab.label} path={tab.path} />
            ))}
          </div>
        )}
      </header>
    </>
  )
}
