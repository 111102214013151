import * as EVENT from 'gadjet-v2-types/dist/api/branch/event'

import axios from '@utils/axios'

const EventAPI = {
  // 이벤트 조회
  getEvents: async (
    { hqId, branchId }: EVENT.GET_LIST_PARAMS,
    query: EVENT.GET_LIST_QUERY
  ): Promise<{ data: EVENT.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/events`, { params: query }),

  // 이벤트 상세 조회
  getEventDetail: async ({
    hqId,
    branchId,
    eventId,
  }: EVENT.GET_DETAIL_PARAMS): Promise<{ data: EVENT.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/events/${eventId}`),

  // 이벤트 추가
  addEvent: async (
    { hqId, branchId }: EVENT.ADD_PARAMS,
    { event }: EVENT.ADD_BODY
  ): Promise<{ data: EVENT.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/events`, { event }),

  // 이벤트 수정
  updateEvent: async (
    { hqId, branchId, eventId }: EVENT.UPDATE_PARAMS,
    { event }: EVENT.UPDATE_BODY
  ): Promise<{ data: EVENT.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/events/${eventId}`, { event }),

  // 이벤트 제거
  deleteEvent: async ({ hqId, branchId, eventId }: EVENT.DELETE_PARAMS): Promise<{ data: EVENT.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/events/${eventId}`),

  // 이벤트 참가자 조회
  getAttendees: async ({
    hqId,
    branchId,
    eventId,
  }: EVENT.GET_ATTENDEES_PARAMS): Promise<{ data: EVENT.GET_ATTENDEES_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/events/${eventId}/event-attendees`),

  // 이벤트 참가자 제거
  deleteAttendee: async ({
    hqId,
    branchId,
    eventId,
    eventAttendeeId,
  }: EVENT.DELETE_ATTENDEE_PARAMS): Promise<{ data: EVENT.DELETE_ATTENDEE_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/events/${eventId}/event-attendees/${eventAttendeeId}`),
}

export default EventAPI
