import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Space, Button } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'

type Props = {
  month: string
  onChange: (month: string) => void
}

export default function DashboardMonthPicker({ month, onChange }: Props): JSX.Element {
  const monthStr = useMemo(() => dayjs(month, 'YYYY-MM').format('YYYY년 MM월'), [month])

  const onClickLeft = () => onChange(dayjs(month, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM'))
  const onClickRight = () => onChange(dayjs(month, 'YYYY-MM').add(1, 'month').format('YYYY-MM'))
  const onClickThisMonth = () => onChange(dayjs().format('YYYY-MM'))

  return (
    <Space size={20}>
      <div style={styles.month}>{monthStr}</div>

      <Button.Group size="middle">
        <Button onClick={onClickLeft} icon={<LeftOutlined />} />
        <Button onClick={onClickRight} icon={<RightOutlined />} />
        <Button onClick={onClickThisMonth}>이번 달</Button>
      </Button.Group>
    </Space>
  )
}

const styles: Styles = {
  picker: { fontSize: '24px', fontWeight: 'bold' },
  month: { fontSize: '24px', fontWeight: 'bold' },
}
