import '@styles/footer.scss'

export default function Footer(): JSX.Element {
  return (
    <footer>
      <div className="footer-wrapper">
        <div className="footer-logo">
          <img src="/images/logo/logo_b.png" className="img" alt="logo" />
        </div>
        <div className="seperator" />
        <div className="companyDetails">
          주식회사 지랩스
          <br />
          대표 백기민 <br />
          02-587-4025 / support@gadjet.io <br />
          서울특별시 강남구 선릉로 578, 7층(삼성동)
        </div>
        <div className="seperator" />
        <div className="notice">
          <div className="linkSection">
            <div className="business">
              <span>제2018-서울강남-02569호</span>
              <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=3788700388">378-87-00388</a>
            </div>
            <div>
              <a href="https://gadjet.notion.site/84498c568b1b45e59a2a9167e50c7587">공지사항</a>
            </div>
          </div>
        </div>
        <div className="seperator" />
        <div className="copyright">
          <span className="operator">
            주식회사 지랩스는 결제정보의 중개서비스 또는 통신판매중개시스템의 제공자로서, 통신판매의 당사자가 아니며
            공간의 이용, 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
          </span>
          <span>Copyright ⓒ 2021 g-labs Inc. All Rights Reserved.</span>
        </div>
      </div>
    </footer>
  )
}
