import { useState, useEffect } from 'react'

import { BranchReceiptActionType } from '@pages/Authorized/Branch/ReceiptPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchReceiptActionType) => void
  popbillId?: null | string
}

export default function BranchReceiptTitle({ onAction, popbillId }: Props): JSX.Element {
  const [actions] = useState<PageTitleAction[]>([])

  useEffect(() => {}, [popbillId])

  return <PageTitle title="증빙" actions={actions} />
}
