import { Button, Form, Input, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'

import formRule from '@utils/formRule'

import AuthAPI from '@apis/auth'

import AuthFormTemplate from '@templates/AuthFormTemplate'

export default function ForgetPasswordPage(): JSX.Element {
  const [form] = useForm<{ email: string }>()
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    const checkCode = form.getFieldValue('code')

    const { data } = await AuthAPI.checkValidCode({ code: checkCode }, {})
    const password = form.getFieldValue('password')

    if (data) {
      setLoading(true)
      await AuthAPI.updatePassword({ managerId: data.managerId }, { manager: { ...data, password } })
      notification.success({ message: '비밀번호가 정상적으로 변경되었습니다.' })
    }

    if (!data) notification.error({ message: '인증코드를 확인해주세요.' })
    setLoading(false)
  }

  const sendCode = async () => {
    const email = await form.getFieldValue('email')

    const { data } = await AuthAPI.sendEmailCode({}, { email })

    if (data) notification.success({ message: '코드가 발송되었습니다.' })
    if (!data) notification.success({ message: '이메일을 확인해주세요.' })
  }

  const initialValues = { email: '' }

  return (
    <AuthFormTemplate
      title="비밀번호를 잊으셨나요?"
      submit={{ label: '변경하기', onClick: onSubmit }}
      loading={loading}
    >
      <Form form={form} initialValues={initialValues} layout="vertical" requiredMark={false}>
        <Form.Item shouldUpdate noStyle>
          <Form.Item name="email" label="이메일" rules={[formRule.required, formRule.email]}>
            <Input
              size="large"
              autoFocus
              onPressEnter={() => sendCode()}
              suffix={
                <Button type="primary" size="small" onClick={sendCode}>
                  코드발송
                </Button>
              }
            />
          </Form.Item>
        </Form.Item>
        <Form.Item label="이메일 인증 코드" name="code">
          <Input size="large" />
        </Form.Item>
        <Form.Item name="password" label="새 비밀번호" rules={[formRule.required, formRule.password]}>
          <Input size="large" type="password" onPressEnter={() => undefined} />
        </Form.Item>
      </Form>
    </AuthFormTemplate>
  )
}
