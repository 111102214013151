import { COLORS } from '@const'
import { Styles } from '@types'
import { Button } from 'antd'

import Loading from '@components/molecules/Loading'

type Props = {
  title: string
  submit: {
    label: string
    onClick: () => void
  }
  footer?: JSX.Element
  children: JSX.Element
  loading?: boolean
}

export default function AuthFormTemplate({ title, submit, footer, children, loading }: Props): JSX.Element {
  return (
    <div style={styles.wrapper}>
      <div style={styles.title}>{title}</div>
      <div>
        <Loading loading={loading}>
          <>
            {children}
            <Button type="primary" size="large" block onClick={submit.onClick} loading={loading}>
              {submit.label}
            </Button>
            <div style={styles.footerWrapper}>{footer}</div>
          </>
        </Loading>
      </div>
    </div>
  )
}

const styles: Styles = {
  wrapper: {},
  title: { fontSize: '40px', color: COLORS.primary, textAlign: 'center', fontWeight: 'bold', marginBottom: '50px' },
  childrenWrapper: { width: '400px' },
  footerWrapper: { width: '400px', padding: '30px 0' },
}
