import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { BillSearch } from 'gadjet-v2-types/dist/type/search'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { searchBills, setBillDefault, setBillPagination, setBillSearch } from '@reducers/branch/bill.reducer'
import { RootState } from '@reducers/index'

import BranchBillListTable from '@components/organisms/Branch/Bill/ListTable'
import BillAutomationConfigModal from '@components/organisms/Branch/Bill/Modals/AutomationConfigModal'
import CashReceiptModal from '@components/organisms/Branch/Bill/Modals/CashReceiptModal'
import BillDeleteModal from '@components/organisms/Branch/Bill/Modals/DeleteModal'
import BillExportModal from '@components/organisms/Branch/Bill/Modals/ExportModal'
import BillFormModal from '@components/organisms/Branch/Bill/Modals/FormModal'
import BillPaymentModal from '@components/organisms/Branch/Bill/Modals/PaymentModal'
import BillPriceModal from '@components/organisms/Branch/Bill/Modals/PriceModal'
import BillSendModal from '@components/organisms/Branch/Bill/Modals/SendModal'
import TaxInoviceModal from '@components/organisms/Branch/Bill/Modals/TaxInoviceModal'
import BranchBillSearchBox from '@components/organisms/Branch/Bill/SearchBox'
import BranchBillSummary from '@components/organisms/Branch/Bill/Summary'
import BranchBillTitle from '@components/organisms/Branch/Bill/Title'

export type BillActionType =
  | 'export'
  | 'automation-config'
  | 'form'
  | 'send'
  | 'delete'
  | 'payment'
  | 'price'
  | 'tax-invoice'
  | 'cash-receipt'

export default function BillPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, pagination, search, searchResult, summary } = useSelector((state: RootState) => state.bill)
  const spaceTypes = useSelector((state: RootState) => state.branch.spaceTypes || [])

  const [exportModal, onVisibleExportModal, onCloseExportModal] = useModal({ visible: false })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ billId: number }>({
    visible: false,
    billId: 0,
  })
  const [automationConfigModal, onVisibleAutomationConfigModal, onCloseAutomationConfigModal] = useModal({
    visible: false,
  })
  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ billId?: number }>({
    visible: false,
  })
  const [sendModal, onVisibleSendModal, onCloseSendModal] = useModal<{ billId: number }>({ visible: false, billId: 0 })
  const [priceModal, onVisiblePriceModal, onClosePriceModal] = useModal<{ billId: number }>({
    visible: false,
    billId: 0,
  })
  const [paymentModal, onVisiblePaymentModal, onClosePaymentModal] = useModal<{ billId: number }>({
    visible: false,
    billId: 0,
  })
  const [taxInvoiceModal, onVisibleTaxInvoiceModal, onCloseTaxInvoiceModal] = useModal<{ billId: number }>({
    visible: false,
    billId: 0,
  })
  const [cashReceiptModal, onVisibleCashReceiptModal, onCloseCashReceiptModal] = useModal<{ billId: number }>({
    visible: false,
    billId: 0,
  })

  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setBillPagination(_pagination))
    dispatch(searchBills(hqId, branchId))
  }
  const onChangeSearch = (_search: Partial<BillSearch>) => dispatch(setBillSearch(_search))
  const onSearch = () => {
    dispatch(setBillPagination({ current: 1 }))
    dispatch(searchBills(hqId, branchId))
  }
  const onResetAndSearch = () => dispatch(setBillDefault(hqId, branchId))

  const onDone = (reset?: boolean) => {
    if (reset) onResetAndSearch()
    else onSearch()
  }

  const onAction = (action: BillActionType, billId?: number) => {
    if (action === 'export') onVisibleExportModal()
    if (action === 'automation-config') onVisibleAutomationConfigModal()
    if (action === 'form') onVisibleFormModal({ visible: true, billId })
    if (action === 'send') onVisibleSendModal({ billId })
    if (action === 'price') onVisiblePriceModal({ billId })
    if (action === 'payment') onVisiblePaymentModal({ billId })
    if (action === 'tax-invoice') onVisibleTaxInvoiceModal({ billId })
    if (action === 'cash-receipt') onVisibleCashReceiptModal({ billId })
    if (action === 'delete') onVisibleDeleteModal({ billId })
  }

  useEffect(() => {
    onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchBillSearchBox
        search={search}
        onChangeSearch={onChangeSearch}
        spaceTypes={spaceTypes}
        onSearch={onSearch}
      />

      <BranchBillSummary summary={summary} />

      <BranchBillTitle onAction={onAction} />

      <BranchBillListTable
        loading={loading}
        total={searchResult.total}
        pagination={pagination}
        bills={searchResult.bills}
        onChangePagination={onChangePagination}
        onAction={onAction}
      />

      <BillExportModal visible={exportModal.visible} hqId={hqId} branchId={branchId} onClose={onCloseExportModal} />
      <BillFormModal
        hqId={hqId}
        branchId={branchId}
        billId={formModal.billId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />
      <BillAutomationConfigModal
        hqId={hqId}
        branchId={branchId}
        visible={automationConfigModal.visible}
        onClose={onCloseAutomationConfigModal}
      />
      <BillSendModal
        hqId={hqId}
        branchId={branchId}
        billId={sendModal.billId}
        visible={sendModal.visible}
        onClose={onCloseSendModal}
      />
      <BillPriceModal
        hqId={hqId}
        branchId={branchId}
        billId={priceModal.billId}
        visible={priceModal.visible}
        onClose={onClosePriceModal}
      />
      <BillPaymentModal
        hqId={hqId}
        branchId={branchId}
        billId={paymentModal.billId}
        visible={paymentModal.visible}
        onClose={onClosePaymentModal}
        onDone={onDone}
      />

      <BillDeleteModal
        hqId={hqId}
        branchId={branchId}
        billId={deleteModal.billId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />

      <TaxInoviceModal
        hqId={hqId}
        branchId={branchId}
        billId={taxInvoiceModal.billId}
        visible={taxInvoiceModal.visible}
        onClose={onCloseTaxInvoiceModal}
        onDone={onDone}
      />

      <CashReceiptModal
        hqId={hqId}
        branchId={branchId}
        billId={cashReceiptModal.billId}
        visible={cashReceiptModal.visible}
        onClose={onCloseCashReceiptModal}
        onDone={onDone}
      />
    </div>
  )
}
