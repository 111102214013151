import { Rule, RuleObject } from 'antd/lib/form'

type RuleKey =
  | 'required'
  | 'number'
  | 'email'
  | 'password'
  | 'businessNumber'
  | 'corporationNumber'
  | 'foreignerNumber'
  | 'residentNumber'
  | 'contact'
  | 'date'
  | 'phone'

const regex = {
  onlyNumber: /\d+/g,
}

const formRule: Record<RuleKey, Rule> = {
  required: { required: true, message: '필수입니다.' },
  number: { pattern: regex.onlyNumber, message: '숫자만 입력하세요.' },
  email: { type: 'email', message: '이메일을 입력하세요.' },
  password: { min: 6, message: '6자리 이상 입력하세요.' },
  businessNumber: { len: 10, message: '10자리 숫자를 입력하세요.', pattern: regex.onlyNumber },
  corporationNumber: { len: 13, message: '13자리 숫자를 입력하세요.', pattern: regex.onlyNumber },
  foreignerNumber: { len: 13, message: '13자리 숫자를 입력하세요.', pattern: regex.onlyNumber },
  residentNumber: { len: 13, message: '13자리 숫자를 입력하세요.', pattern: regex.onlyNumber },
  contact: { max: 11, min: 9, message: '9-11 자리 숫자를 입력하세요.' },
  phone: {
    validator: (_: RuleObject, value: number | string) => {
      const s = String(value).replace(/[^0-9]/g, '')
      if (s.length !== 11) return Promise.reject(new Error('11자리 숫자를 입력하세요.'))
      return Promise.resolve()
    },
  },
  date: {
    validator: (_: RuleObject, value: number | string) => {
      const n = Number(value)
      if (n < 1 || n > 31) return Promise.reject(new Error('1-31 사이의 날짜를 입력하세요.'))
      return Promise.resolve()
    },
  },
}

export default formRule
