import { Bills, Contracts, SpaceTypes } from 'gadjet-v2-types/dist/model'

// ActionType
export const SEARCH_HQ_DASHBOARD_CONTRACTS = 'hq/dashboard/SEARCH_HQ_DASHBOARD_CONTRACTS' as const
export const GET_HQ_DASHBOARD_RECENT_CONTRACTS = 'hq/dashboard/GET_HQ_DASHBOARD_RECENT_CONTRACTS' as const
export const GET_HQ_DASHBOARD_START_CONTRACTS = 'hq/dashboard/GET_HQ_DASHBOARD_START_CONTRACTS' as const
export const GET_HQ_DASHBOARD_END_CONTRACTS = 'hq/dashboard/GET_HQ_DASHBOARD_END_CONTRACTS' as const
export const GET_HQ_DASHBOARD_UNPAID_BILLS = 'hq/dashboard/GET_HQ_DASHBOARD_UNPAID_BILLS' as const
export const GET_HQ_DASHBOARD_SPACE_TYPES = 'hq/dashboard/GET_HQ_DASHBOARD_SPACE_TYPES' as const
export const SET_HQ_DASHBOARD = 'hq/dashboard/SET_HQ_DASHBOARD' as const

// Action
export const searchDashboardContracts = (hqId: number, query: string, cb?: () => void) => ({
  type: SEARCH_HQ_DASHBOARD_CONTRACTS,
  payload: { hqId, query, cb },
})
export const getHqDashboardRecentContracts = (hqId: number, cb?: () => void) => ({
  type: GET_HQ_DASHBOARD_RECENT_CONTRACTS,
  payload: { hqId, cb },
})
export const getHqDashboardStartContracts = (hqId: number, cb?: () => void) => ({
  type: GET_HQ_DASHBOARD_START_CONTRACTS,
  payload: { hqId, cb },
})
export const getHqDashboardEndContracts = (hqId: number, cb?: () => void) => ({
  type: GET_HQ_DASHBOARD_END_CONTRACTS,
  payload: { hqId, cb },
})
export const getHqDashboardUnpaidBills = (hqId: number, cb?: () => void) => ({
  type: GET_HQ_DASHBOARD_UNPAID_BILLS,
  payload: { hqId, cb },
})
export const getHqDashboardSpaceTypes = (hqId: number, branchId: number, cb?: () => void) => ({
  type: GET_HQ_DASHBOARD_SPACE_TYPES,
  payload: { hqId, branchId, cb },
})
export const setHqDashboard = (state: Partial<HqDashboardState>) => ({
  type: SET_HQ_DASHBOARD,
  payload: { state },
})

export type HqDashboardAction =
  | ReturnType<typeof getHqDashboardRecentContracts>
  | ReturnType<typeof searchDashboardContracts>
  | ReturnType<typeof getHqDashboardStartContracts>
  | ReturnType<typeof getHqDashboardEndContracts>
  | ReturnType<typeof getHqDashboardUnpaidBills>
  | ReturnType<typeof getHqDashboardSpaceTypes>
  | ReturnType<typeof setHqDashboard>

type HqDashboardState = {
  recentContracts: Contracts[]
  searchContracts: Contracts[]
  startContracts: Contracts[]
  endContracts: Contracts[]
  unpaidBills: Bills[]
  spaceTypes: SpaceTypes[]
}

const hqBranchState: HqDashboardState = {
  recentContracts: [],
  searchContracts: [],
  startContracts: [],
  endContracts: [],
  unpaidBills: [],
  spaceTypes: [],
}

const hqDashboardReducer = (state = hqBranchState, action: HqDashboardAction): HqDashboardState => {
  switch (action.type) {
    case SET_HQ_DASHBOARD:
      return { ...state, ...action.payload.state }
    default:
      return state
  }
}

export default hqDashboardReducer
