import { Modal, Form, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { useState, useEffect } from 'react'

import ContractAPI from '@apis/branch/contract'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  contractId: number
  onClose?: () => void
  onDone?: () => void
}

export default function ContractSuspendModal({ hqId, branchId, contractId, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<{ suspendDate: string }>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    if (!contractId) return
    try {
      setLoading(true)
      const values = await form.validateFields()

      await ContractAPI.suspendContract({ hqId, branchId, contractId }, values)

      notification.success({ message: '종료되었습니다.' })

      setLoading(false)
      if (onDone) onDone()
      if (onClose) onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const reset = () => {
    form.resetFields()
  }

  useEffect(() => {
    reset()
  }, [])

  const initialValues = {
    suspendDate: dayjs().format('YYYY-MM-DD'),
  }

  return (
    <Modal title="계약종료" okText="종료" onOk={onOk} confirmLoading={loading} visible onCancel={onClose}>
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item
            name="suspendDate"
            label="종료일"
            getValueProps={(d) => ({ value: dayjs(d) })}
            getValueFromEvent={(_, s) => s}
          >
            <LocalDatePicker />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
