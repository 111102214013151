import { BranchProductAction } from '@pages/Authorized/Branch/ProductPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchProductAction) => void
}

export default function BranchProductTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [{ label: '상품 추가', buttonType: 'primary', onClick: () => onAction('add') }]

  return <PageTitle title="상품" actions={actions} />
}
