import { Styles } from '@types'
import { Col } from 'antd'

type Props = {
  label: string
  span?: number
  children: JSX.Element
}

export default function SearchItem({ label, span, children }: Props): JSX.Element {
  return (
    <Col style={styles.col} span={span}>
      <div style={styles.label}>{label}</div>
      <div style={styles.childrenWrapper}>{children}</div>
    </Col>
  )
}

const styles: Styles = {
  col: {},
  label: { opacity: 0.6, fontSize: '12px' },
  childrenWrapper: {},
}
