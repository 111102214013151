import { Payments } from 'gadjet-v2-types/dist/model'
import { PaymentSummary, Pagination } from 'gadjet-v2-types/dist/type'
import { PaymentSearch } from 'gadjet-v2-types/dist/type/search'

import { getDefaultRange } from '@utils/datetime'

// ActionType
export const SEARCH_PAYMENTS = 'payment/SEARCH_PAYMENTS' as const
export const SET_PAYMENT_DEFAULT = 'payment/SET_DEFAULT' as const
export const SET_PAYMENT_LOADING = 'payment/SET_PAYMENT_LOADING' as const
export const SET_PAYMENT_SEARCH_RESULT = 'payment/SET_PAYMENT_SEARCH_RESULT' as const
export const SET_PAYMENT_SEARCH = 'payment/SET_PAYMENT_SEARCH' as const
export const SET_PAYMENT_PAGINATION = 'payment/SET_PAYMENT_PAGINATION' as const
export const SET_PAYMENT_SUMMARY = 'payment/SET_PAYMENT_SUMMARY' as const
export const SET_PAYMENT_PAGE_CONFIG = 'payment/SET_PAYMENT_PAGE_CONFIG' as const

// Action
export const searchPayments = (hqId: number, branchId: number) => ({
  type: SEARCH_PAYMENTS,
  payload: { hqId, branchId },
})
export const setPaymentDefault = (hqId: number, branchId: number) => ({
  type: SET_PAYMENT_DEFAULT,
  payload: { hqId, branchId },
})
export const setPaymentLoading = (loading: boolean) => ({
  type: SET_PAYMENT_LOADING,
  payload: { loading },
})
export const setPaymentSearchResult = (result: Partial<PaymentState['searchResult']>) => ({
  type: SET_PAYMENT_SEARCH_RESULT,
  payload: { result },
})
export const setPaymentSearch = (search: Partial<PaymentSearch>) => ({
  type: SET_PAYMENT_SEARCH,
  payload: { search },
})
export const setPaymentPagination = (pagination: Partial<Pagination>) => ({
  type: SET_PAYMENT_PAGINATION,
  payload: { pagination },
})
export const setPaymentSummary = (summary: PaymentSummary) => ({
  type: SET_PAYMENT_SUMMARY,
  payload: { summary },
})

export type PaymentAction =
  | ReturnType<typeof searchPayments>
  | ReturnType<typeof setPaymentDefault>
  | ReturnType<typeof setPaymentLoading>
  | ReturnType<typeof setPaymentSearchResult>
  | ReturnType<typeof setPaymentSearch>
  | ReturnType<typeof setPaymentPagination>
  | ReturnType<typeof setPaymentSummary>

type PaymentState = {
  loading: boolean
  searchResult: { branchId: number; total: number; payments: Payments[] }
  search: PaymentSearch
  pagination: Pagination
  summary: PaymentSummary
}

const paymentState: PaymentState = {
  loading: true,
  searchResult: { branchId: 0, total: 0, payments: [] },
  search: {
    query: '',
    paymentStatus: ['approve', 'refund', 'ready'],
    paymentType: ['alipay', 'card', 'direct', 'oversea-card', 'paypal', 'transfer', 'wechatpay'],
    date: getDefaultRange('month', 'date'),
  },
  pagination: { current: 1, pageSize: 10 },
  summary: {
    total: 0,
    card: 0,
    transfer: 0,
    direct: 0,
    refund: 0,
    'oversea-card': 0,
    alipay: 0,
    paypal: 0,
    wechatpay: 0,
    cash: 0,
  },
}

const paymentReducer = (state = paymentState, action: PaymentAction): PaymentState => {
  switch (action.type) {
    case SET_PAYMENT_SEARCH_RESULT:
      return { ...state, searchResult: { ...state.searchResult, ...action.payload.result } }
    case SET_PAYMENT_LOADING:
      return { ...state, loading: action.payload.loading }
    case SET_PAYMENT_SEARCH:
      return { ...state, search: { ...state.search, ...action.payload.search } }
    case SET_PAYMENT_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload.pagination } }
    case SET_PAYMENT_SUMMARY:
      return { ...state, summary: action.payload.summary }
    default:
      return state
  }
}

export default paymentReducer
