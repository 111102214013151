import { notification } from 'antd'

import AdditionAPI from '@apis/branch/addition'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  additionId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function AdditionDeleteModal({
  hqId,
  branchId,
  additionId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    if (!additionId) return
    await AdditionAPI.deleteAddition({ hqId, branchId, additionId })
    notification.success({ message: '제거되었습니다.' })
  }

  return (
    <ConfirmModal
      title="부가서비스 제거"
      description="부가서비스 제거 시 계약에 영향을 미칠 수 있습니다."
      onConfirm={onConfirm}
      visible={visible}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
