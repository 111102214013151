import '@loaders/index'
import '@styles/global.scss'
import { ConfigProvider } from 'antd'
import ReactDOM from 'react-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import locale from '@configs/locale'

import Routes from '@routes/index'

import { store, persistor } from '@utils/store'

import { ModalProvider } from '@components/contexts/ModalContext'
import AntdDefaultProps from '@components/organisms/AntdDefaultProps'
import AsyncLoader from '@components/organisms/AsyncLoader'

import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient({ defaultOptions: { queries: { suspense: false } } })

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <AntdDefaultProps />
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate persistor={persistor}>
          <ModalProvider>
            <BrowserRouter>
              <Routes />
              <AsyncLoader />
            </BrowserRouter>
          </ModalProvider>
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
