import { BillActionType } from '@pages/Authorized/Branch/BillPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BillActionType) => void
}

export default function BranchBillTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    { label: '내보내기', buttonType: 'dashed', onClick: () => onAction('export') },
    { label: '자동화 설정', buttonType: 'default', onClick: () => onAction('automation-config') },
    { label: '청구서 추가', buttonType: 'primary', onClick: () => onAction('form') },
  ]
  return <PageTitle title="청구서" actions={actions} />
}
