import * as SPACE_TYPE from 'gadjet-v2-types/dist/api/branch/space-type'

import axios from '@utils/axios'

const SpaceTypeAPI = {
  // 공간 타입 조회
  getSpaceTypes: async ({
    hqId,
    branchId,
  }: SPACE_TYPE.GET_LIST_PARAMS): Promise<{ data: SPACE_TYPE.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/space-types`),

  // 공간타입 추가
  addSpaceType: async (
    { hqId, branchId }: SPACE_TYPE.ADD_PARAMS,
    { spaceType }: SPACE_TYPE.ADD_BODY
  ): Promise<{ data: SPACE_TYPE.ADD_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/space-types`, { spaceType }),

  // 공간타입 수정
  updateSpaceType: async (
    { hqId, branchId, spaceTypeId }: SPACE_TYPE.UPDATE_PARAMS,
    { spaceType }: SPACE_TYPE.UPDATE_BODY
  ): Promise<{ data: SPACE_TYPE.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/space-types/${spaceTypeId}`, { spaceType }),

  // 공간타입 제거
  deleteSpaceType: async ({
    hqId,
    branchId,
    spaceTypeId,
  }: SPACE_TYPE.DELETE_PARAMS): Promise<{ data: SPACE_TYPE.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/space-types/${spaceTypeId}`),

  // 공간타입 공간 계약
  getSpaceTypeSpaceContracts: async (
    { hqId, branchId }: SPACE_TYPE.GET_SPACES_CONTRACTS_PARAMS,
    query: SPACE_TYPE.GET_SPACES_CONTRACTS_QUERY
  ): Promise<{ data: SPACE_TYPE.GET_SPACES_CONTRACTS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/space-types/spaces/contracts`, { params: query }),
}

export default SpaceTypeAPI
