import { Table, TableColumnType, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { Expenditures } from 'gadjet-v2-types/dist/model'
import { expenditureType } from 'gadjet-v2-types/dist/type/label'

import { krw } from '@utils/format'

type Props = {
  expenditure: Expenditures[]
}

export default function ExpenditureTable({ expenditure }: Props): JSX.Element {
  const columns: TableColumnType<Expenditures>[] = [
    { key: 'date', dataIndex: 'date', title: '날짜', render: (_, { date }) => dayjs(date).format('YYYY년 MM월 DD일') },
    { key: 'type', dataIndex: 'type', title: '타입', render: (_, { type }) => <Tag>{expenditureType[type]}</Tag> },
    { key: 'price', dataIndex: 'price', title: '금액', render: (_, { price }) => krw(price) },
    {
      key: 'memo',
      dataIndex: 'memo',
      title: '메모',
      ellipsis: { showTitle: false },
      render: (memo) => (
        <Tooltip title={memo} placement="bottomLeft">
          {memo}
        </Tooltip>
      ),
    },
  ]
  return <Table rowKey={({ expenditureId }) => `${expenditureId}`} dataSource={expenditure} columns={columns} />
}
