import { Styles } from '@types'
import { Select } from 'antd'

type Props = {
  branch: { branchId: number; name: string } | null
  branches: { branchId: number; name: string }[]
  onChange: (branchId: number) => void
}

export default function BranchSelector({ branch, branches, onChange }: Props): JSX.Element {
  return (
    <div style={styles.wrapper}>
      <Select value={branch?.branchId} onChange={onChange} size="large" style={styles.select}>
        {branches.map((b) => (
          <Select.Option key={b.branchId} value={b.branchId}>
            {b.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

const styles: Styles = {
  wrapper: { padding: '10px' },
  select: { width: '100%' },
}
