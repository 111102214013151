import { Col, Form, FormInstance, Input, Row, Select } from 'antd'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { contractDepositStatus } from 'gadjet-v2-types/dist/type/label'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

import { RootState } from '@reducers/index'

type Props = { form: FormInstance<Contracts>; isUpdate: boolean }

export default function DepositTab({ form, isUpdate }: Props): JSX.Element {
  const { depositStatus, banks } = useSelector((state: RootState) => state.option)

  const depositStatusOptions = useMemo(
    () => depositStatus.map((status) => ({ label: contractDepositStatus[status], value: status })),
    [depositStatus]
  )

  const bankOptions = useMemo(() => banks.map(({ name, bankCode }) => ({ label: name, value: bankCode })), [banks])

  const getBankName = (_bankCode: string) => bankOptions.find((bo) => bo.value === _bankCode)?.label || '-'

  const onChangeBankCode = (_bankCode: string) => {
    const bankName = getBankName(_bankCode)
    const contractDeposit = form.getFieldValue('contractDeposit')
    form.setFieldsValue({ contractDeposit: { ...contractDeposit, bankName } })
  }

  return (
    <>
      <Form.Item label="금액" name={['contractDeposit', 'price']} rules={[formRule.required]} {...mask.krw}>
        <Input addonAfter="원" />
      </Form.Item>
      <Form.Item label="상태" name={['contractDeposit', 'status']} rules={[formRule.required]}>
        <Select options={depositStatusOptions} />
      </Form.Item>

      <Form.Item label="반환 정보">
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item label="은행" name={['contractDeposit', 'bankCode']}>
              <Select<string> showSearch options={bankOptions} optionFilterProp="label" onChange={onChangeBankCode} />
            </Form.Item>
            <Form.Item name={['contractDeposit', 'bankName']} hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="계좌번호" name={['contractDeposit', 'account']}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="예금주" name={['contractDeposit', 'holder']}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </>
  )
}
