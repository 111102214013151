import { BranchRentalReservationAction } from '@pages/Authorized/Branch/RentalReservationPage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchRentalReservationAction) => void
}

export default function BranchRentalReservationTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [
    // { label: '', buttonType: 'primary', onClick: () => onAction('') }
  ]

  return <PageTitle title="대관/대여 예약내역" actions={actions} />
}
