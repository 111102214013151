import '@styles/about.scss'

export default function AboutPage(): JSX.Element {
  return (
    <>
      <div
        style={{
          backgroundImage: 'url(/assets/landing/about-title.jpg)',
          backgroundPosition: 'center',
          height: '300px',
          marginTop: '90px',
        }}
      >
        <div className="title">공유 공간을 관리하는 가장 쉬운 방법, 가젯</div>
      </div>
      <div className="content">
        <p>
          가젯은 공유오피스 GARAGE에 뿌리를 두고 있습니다. <br />
          더 효율적인 공유오피스 운영을 위해 자체 개발한 관리 솔루션인 가젯은 <br />
          GARAGE 구성원의 업무 효율을 높이는 것은 물론 업무 만족도까지 끌어올렸습니다.
        </p>
        <p>
          지랩스는 가젯이 GARAGE 뿐 아니라, 더욱 다양한 공유 공간을 관리하는 모든 사람들이 <br />
          ‘더 나은 방식’으로 일할 수 있도록 도울 것이라는 확신이 있습니다.
        </p>
        <p>
          공유 공간을 운영하고 관리하려면 어떤 것이 더 필요한지에 대한 고민을 바탕으로 가젯은 꾸준히 개선되고 있습니다.
          <br />
          공유 공간 운영사의 높아진 업무 효율이 공간 사용자에게 더 좋은 환경을 제공할 수 있을 것으로 기대합니다. <br />
          가젯은 운영사와 이용자가 만족하는 공간을 운영할 수 있도록 공유 공간 관리의 새로운 방법론을 만들어가겠습니다.
        </p>
      </div>
    </>
  )
}
