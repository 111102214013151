import { Styles } from '@types'
import { Form, Modal, notification, Tabs } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Contracts } from 'gadjet-v2-types/dist/model'
import { useEffect, useMemo, useState } from 'react'

import defaultValues from '@utils/defaultValues'

import ContractAPI from '@apis/branch/contract'

import HiddenItems from '@components/atoms/Form/HiddenItems'

import AlertTab from './Tabs/Alert'
import ContractTab from './Tabs/Contract'
import ContractorTab from './Tabs/Contractor'
import DepositTab from './Tabs/Deposit'
import DirectorTab from './Tabs/Director'
import EtcTab from './Tabs/Etc'
import FeeTab from './Tabs/Fee'
import PaymentTab from './Tabs/Payment'
import TenantTab from './Tabs/Tenant'

const INITIAL_ACTIVE_KEY = 'contract'

export type ContractDefaultSelect = { tenant: ContractDefaultOptions }
export type ContractDefaultOptions = { value: number; label: string }[]

type Props = {
  hqId: number
  branchId: number
  contractId?: number | null
  defaultContract?: Partial<Contracts>
  activeTab?: string
  onClose?: () => void
  onDone?: () => void
}

export default function ContractFormModal({
  hqId,
  branchId,
  contractId,
  defaultContract = {},
  activeTab,
  onClose,
  onDone = () => undefined,
}: Props): JSX.Element {
  const [form] = useForm<Contracts>()
  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState(INITIAL_ACTIVE_KEY)
  const [option, setOption] = useState<ContractDefaultSelect>({ tenant: [] })
  const initialValues = useMemo(() => defaultValues.contract(defaultContract), [defaultContract])

  const isUpdate = useMemo(() => !!contractId, [initialValues])
  const isAdd = useMemo(() => !contractId && !initialValues.extendContractId, [initialValues])
  const isExtend = useMemo(() => !contractId && !!initialValues.extendContractId, [initialValues])

  const onOk = async () => {
    try {
      const values = await form.validateFields()

      setLoading(true)

      if (isAdd || isExtend) await ContractAPI.addContract({ hqId, branchId }, { contract: values })
      if (contractId) await ContractAPI.updateContract({ hqId, branchId, contractId }, { contract: values })

      notification.success({ message: '저장되었습니다.' })

      if (onDone) onDone()
      if (onClose) onClose()
      setLoading(false)
    } catch (err: unknown) {
      // console.log(err.errorFields[0].name[0])
      // setActiveKey(err.errorFields[0].name[0] as any)
      // TODO validate 통과 못한 탭으로 이동

      setLoading(false)
    }
  }

  const getContract = async () => {
    if (!contractId) return
    setLoading(true)
    const { data } = await ContractAPI.getContractDetail({ hqId, branchId, contractId })
    setDetaultSelect(data)

    form.setFieldsValue(data)
    setLoading(false)
  }

  const setDetaultSelect = (contract: Contracts) => {
    const { tenantId, tenant } = contract

    const option: ContractDefaultSelect = { tenant: [] }
    if (tenantId) option.tenant.push({ value: tenantId, label: tenant.name })

    setOption(option)
  }

  const onReset = () => {
    form.resetFields()
    form.setFieldsValue(defaultContract)
    setLoading(false)
  }

  useEffect(() => {
    onReset()
    if (contractId) getContract()
    if (activeTab) setActiveKey(activeTab)
  }, [contractId, activeTab])

  const tabs = useMemo(
    () => [
      { label: '계약', key: 'contract', component: <ContractTab form={form} isUpdate={isUpdate} /> },
      {
        label: '입주사',
        key: 'tenant',
        component: (
          <TenantTab form={form} isUpdate={isUpdate} options={option.tenant} hqId={hqId} branchId={branchId} />
        ),
      },
      { label: '대표자', key: 'director', component: <DirectorTab form={form} isUpdate={isUpdate} /> },
      { label: '계약자', key: 'contractor', component: <ContractorTab form={form} isUpdate={isUpdate} /> },
      {
        label: '이용료',
        key: 'fee',
        component: <FeeTab form={form} isUpdate={isUpdate} hqId={hqId} branchId={branchId} />,
      },
      { label: '결제', key: 'payment', component: <PaymentTab form={form} isUpdate={isUpdate} /> },
      { label: '보증금', key: 'deposit', component: <DepositTab form={form} isUpdate={isUpdate} /> },
      { label: '기타', key: 'etc', component: <EtcTab form={form} isUpdate={isUpdate} /> },
      { label: '알림', key: 'alert', component: <AlertTab form={form} isUpdate={isUpdate} /> },
    ],
    [hqId, branchId, isUpdate, option]
  )
  return (
    <Modal
      visible
      onCancel={onClose}
      onOk={onOk}
      title="계약"
      confirmLoading={loading}
      bodyStyle={{ ...Modal.defaultProps?.bodyStyle, ...styles.modalBody }}
      width="1000px"
    >
      <Form form={form} layout="vertical" initialValues={initialValues} scrollToFirstError>
        <Tabs
          tabPosition="left"
          type="card"
          size="middle"
          tabBarStyle={styles.tabBar}
          activeKey={activeKey}
          onChange={setActiveKey}
        >
          {tabs.map(({ label, key, component }) => (
            <Tabs.TabPane tab={label} key={key} style={styles.tabPane} forceRender>
              {component}
            </Tabs.TabPane>
          ))}
        </Tabs>
        <HiddenItems names={['tenantId', 'extendContractId', 'extendSinceDate']} />
      </Form>
    </Modal>
  )
}

const styles: Styles = {
  tabBar: { minWidth: '100px' },
  tabPane: {},
  modalBody: { height: '100vh' },
}
