import { notification } from 'antd'

import BillAPI from '@apis/branch/bill'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  billId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function BillDeleteModal({ hqId, branchId, billId, visible, onClose, onDone }: Props): JSX.Element {
  const onConfirm = async () => {
    if (!billId) return
    await BillAPI.deleteBill({ hqId, branchId, billId })
    notification.success({ message: '제거되었습니다.' })
  }

  return (
    <ConfirmModal
      title="청구서 제거"
      description="청구서 제거 시 결제내역에 영향을 미칠 수 있습니다."
      onConfirm={onConfirm}
      visible={visible}
      onClose={onClose}
      onDone={onDone}
    />
  )
}
