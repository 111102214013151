import { Styles } from '@types'
import dayjs from 'dayjs'
import { useMemo } from 'react'

export default function SuspendDate({ contract }: ContractColumnProps): JSX.Element {
  const { suspendDate } = contract

  const dDay = useMemo(() => {
    const isSuspended = dayjs(suspendDate).isBefore(dayjs(), 'day')
    if (isSuspended) return <span style={{ ...styles.dDay, color: '#cf1322' }}>(종료)</span>

    const month = dayjs(suspendDate).diff(dayjs(), 'month')
    if (month >= 1) return <span style={{ ...styles.dDay, color: '#389e0d' }}>({month}달 후)</span>

    const isToday = dayjs(suspendDate).isSame(dayjs(), 'day')
    if (isToday) return <span style={{ ...styles.dDay, color: '#d4b106' }}>(오늘)</span>

    const day = Math.ceil(dayjs(suspendDate).diff(dayjs(), 'day', true))
    return <span style={{ ...styles.dDay, color: '#d4b106' }}>({day}일 전)</span>
  }, [contract])

  return (
    <div style={styles.div}>
      {suspendDate}
      {` `}
      {dDay}
    </div>
  )
}

const styles: Styles = {
  div: {},
  dDay: { opacity: 0.6 },
}
