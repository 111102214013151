import { notification } from 'antd'

import BranchCardAPI from '@apis/branch/branch-card'

import ConfirmModal from '@components/molecules/ConfirmModal'

type Props = {
  hqId: number
  branchId: number
  branchCardId: number
  visible: false
  onClose: () => void
  onDone: () => void
}

export default function BranchCardDeleteConfirm({
  hqId,
  branchId,
  branchCardId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const onConfirm = async () => {
    await BranchCardAPI.deleteBranchCard({ hqId, branchId, branchCardId })
    notification.success({ message: '결제수단이 제거되었습니다.' })
    onDone()
  }

  return <ConfirmModal onClose={onClose} onConfirm={onConfirm} title="결제수단 제거" visible={visible} />
}
