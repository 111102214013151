import { Banks, Gics } from 'gadjet-v2-types/dist/model'
import {
  ContractBillingSplitType,
  ContractBillingType,
  ContractDepositStatus,
  ContractLateFeeType,
  CreditType,
  ExpenditureType,
  PaymentType,
  ReceiptStatus,
  TenantType,
} from 'gadjet-v2-types/dist/type'

// ActionType
export const GET_OPTION = 'option/GET_OPTION' as const
export const SET_OPTION = 'option/SET_OPTION' as const

// Action
export const getOption = () => ({
  type: GET_OPTION,
  payload: {},
})
export const setOption = (option: Partial<OptionState>) => ({
  type: SET_OPTION,
  payload: { option },
})

export type OptionAction = ReturnType<typeof getOption> | ReturnType<typeof setOption>

type OptionState = {
  banks: Banks[]
  gics: Gics[]
  tenantTypes: TenantType[]
  billingTypes: ContractBillingType[]
  billingSplitType: ContractBillingSplitType[]
  lateFeeTypes: ContractLateFeeType[]
  depositStatus: ContractDepositStatus[]
  receiptStatus: ReceiptStatus[]
  creditTypes: CreditType[]
  expenditureTypes: ExpenditureType[]
  paymentTypes: PaymentType[]
}

const optionState: OptionState = {
  banks: [],
  gics: [],
  tenantTypes: ['sole-proprietor', 'corporation-business', 'local', 'foreigner'],
  billingTypes: ['monthly', 'prepayment'],
  billingSplitType: ['A', 'B'],
  lateFeeTypes: ['none', 'fix', 'rate'],
  depositStatus: ['unpaid', 'paid', 'returned'],
  receiptStatus: [
    'none',
    'tax-invoice-request',
    'tax-invoice-done',
    'tax-invoice-updated',
    'tax-invoice-fail',
    'cash-receipt-request',
    'cash-receipt-done',
    'cash-receipt-cancel',
    'cash-receipt-fail',
  ],
  creditTypes: ['buy', 'contract', 'mileage'],
  expenditureTypes: ['card', 'cash', 'transfer'],
  paymentTypes: ['card', 'transfer', 'direct', 'oversea-card', 'paypal', 'alipay', 'wechatpay', 'cash'],
}

const optionReducer = (state = optionState, action: OptionAction): OptionState => {
  switch (action.type) {
    case SET_OPTION:
      return { ...state, ...action.payload.option }
    default:
      return state
  }
}

export default optionReducer
