import { VISIBLE_ROLES } from '@const'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { setMenu } from '@reducers/branch/menu.reducer'
import { RootState } from '@reducers/index'

type HqMenu = { key: HqMenuKey; label: string; path: string }
export const HQ_MENUS: HqMenu[] = [
  { key: 'dashboard', label: '대시보드', path: 'dashboard' },
  { key: 'graph', label: '그래프', path: 'graph' },
  { key: 'notice', label: '공지사항', path: 'notice' },
  { key: 'role', label: '권한관리', path: 'role' },
  { key: 'branch', label: '지점추가', path: 'branch' },
  { key: 'config', label: '설정', path: 'config' },
]

type BranchMenu = {
  key: BranchMenuKey
  label: string
  path: string
  sub?: { key: string; path: string; label: string }[]
}
export const BRANCH_MENUS: BranchMenu[] = [
  { key: 'dashboard', label: '대시보드', path: 'dashboard' },
  {
    key: 'contract',
    label: '계약',
    path: 'contract',
    sub: [
      { key: 'contract-list', label: '목록', path: 'list' },
      { key: 'space', label: '공간', path: 'space' },
      { key: 'addition', label: '부가서비스', path: 'addition' },
    ],
  },

  {
    key: 'bill',
    label: '청구서',
    path: 'bill',
    sub: [
      { key: 'bill-list', label: '목록', path: 'list' },
      { key: 'receipt', label: '증빙', path: 'receipt' },
      { key: 'payment', label: '결제내역', path: 'payment' },
    ],
  },

  {
    key: 'rental',
    label: '대관/대여',
    path: 'rental',
    sub: [
      { key: 'list', path: 'list', label: '목록' },
      { key: 'reservation', path: 'reservation', label: '예약내역' },
    ],
  },
  { key: 'tenant', label: '입주사', path: 'tenant' },
  { key: 'notice', label: '공지사항', path: 'notice' },
  {
    key: 'product',
    label: '상품',
    path: 'product',
    sub: [
      { key: 'list', path: 'list', label: '목록' },
      { key: 'sale', path: 'sale', label: '판매내역' },
    ],
  },
  {
    key: 'service',
    label: '서비스',
    path: 'service',
    sub: [
      { key: 'list', path: 'list', label: '목록' },
      { key: 'sale', path: 'sale', label: '판매내역' },
    ],
  },
  { key: 'expenditure', label: '지출', path: 'expenditure' },
  { key: 'event', label: '이벤트/행사', path: 'event' },
  { key: 'cash', label: '현금', path: 'cash' },
  // { key: 'accessLog', label: '접근기록', path: 'access-log' },
  { key: 'role', label: '권한', path: 'role' },
  { key: 'config', label: '설정', path: 'config' },
]

const useMenu = () => {
  const dispatch = useDispatch()
  const { hqId, branchId } = useParams<{ hqId?: string; branchId?: string }>()
  const { hqs, branches, hq, branch } = useSelector((state: RootState) => state.menu)

  const hqRole = useMemo(() => hq?.roles?.find((r) => r.hqId === hq?.hqId), [hq])
  const branchRole = useMemo(() => branch?.roles?.find((r) => r.branchId === branch?.branchId), [branch])

  const [hqMenus, setHqMenus] = useState<HqMenu[]>([])
  const [branchMenus, setBranchMenus] = useState<BranchMenu[]>([])
  const visibleHq = useMemo(() => hqs.length > 0, [hqs])

  useEffect(() => {
    if (!hqRole) return
    if (hqRole?.adminFlag) {
      setHqMenus(HQ_MENUS)
      return
    }
    setHqMenus(HQ_MENUS.filter(({ key }) => VISIBLE_ROLES.includes(hqRole[key])))
  }, [hq, hqRole])

  useEffect(() => {
    if (!branchRole) return
    if (hqRole?.adminFlag || branchRole?.adminFlag) {
      setBranchMenus(BRANCH_MENUS)
      return
    }

    setBranchMenus(BRANCH_MENUS.filter(({ key }) => VISIBLE_ROLES.includes(branchRole[key])))
  }, [branch, branchRole])

  const setBranch = useCallback(
    (_branchId: number) =>
      dispatch(
        setMenu({
          branch: branch ? branches.find((b) => b.branchId === _branchId) : branch,
        })
      ),
    [branches]
  )

  const setHq = useCallback(
    (_hqId: number) =>
      dispatch(
        setMenu({
          hq: hq ? hqs.find((h) => h.hqId === _hqId) : hq,
        })
      ),
    [hqs]
  )

  useEffect(() => {
    if (hqId) setHq(Number(hqId))
    if (branchId) setBranch(Number(branchId))
  }, [hqId, branchId])

  return {
    hqId: hq?.hqId || branch?.hqId || 0,
    branchId: branch?.branchId || 0,
    hqMenus: hqMenus.map((m) => ({ ...m, path: `/hq/${hq?.hqId}/${m.path}` })),
    branchMenus: branchMenus.map((m) => ({ ...m, path: `/hq/${branch?.hqId}/branch/${branch?.branchId}/${m.path}` })),
    setBranch,
    setHq,
    visibleHq,
    hqRole,
    branchRole,
  }
}

export default useMenu
