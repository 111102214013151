import { BranchProductSaleAction } from '@pages/Authorized/Branch/ProductSalePage'

import PageTitle, { PageTitleAction } from '@components/molecules/PageTitle'

type Props = {
  onAction: (type: BranchProductSaleAction, productSaleId?: number) => void
}

export default function BranchProductSaleTitle({ onAction }: Props): JSX.Element {
  const actions: PageTitleAction[] = [{ label: '내보내기', buttonType: 'dashed', onClick: () => onAction('export') }]

  return <PageTitle title="상품 판매내역" actions={actions} />
}
