import { Form, Modal, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'

import bufferToFileDownload from '@utils/download'

import TenantAPI from '@apis/branch/tenant'

import Loading from '@components/molecules/Loading'

type Props = {
  hqId: number
  branchId: number
  visible: boolean
  onDone?: () => void
  onClose: () => void
}

export default function TenantExportModal({ hqId, branchId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<{ includeExpired: boolean }>()
  const [loading, setLoading] = useState(false)

  const onOk = async () => {
    try {
      setLoading(true)
      const { includeExpired } = await form.validateFields()
      const { data } = await TenantAPI.exportTenants({ hqId, branchId }, { includeExpired })

      bufferToFileDownload('입주사.xlsx', Buffer.from(data))

      setLoading(false)
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="입주사 내보내기"
      visible={visible}
      onCancel={onClose}
      okText="내보내기"
      onOk={onOk}
      confirmLoading={loading}
      width="400px"
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical">
          <Form.Item label="계약 종료 된 입주사 포함" name="includeExpired">
            <Switch />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}
